import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO } from 'ngx-intl-tel-input';
import { first, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/login/services/auth.service';
import { RegistrationService } from 'src/app/login/services/registration.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  COUNTDOWN_IN_SECONDS,
  NUMBER_OF_ALLOWED_SMS_RESENDS,
} from 'src/app/shared/helpers/various-helpers.helper';
import { phoneValidator } from 'src/app/shared/phone.validator';
@Component({
  selector: 'app-connect-phone',
  templateUrl: './connect-phone.component.html',
  styleUrls: ['./connect-phone.component.scss'],
})
export class ConnectPhoneComponent implements OnInit {
  smsSent = false;
  smsSentAgain = false;

  isLoading = false;
  isSending = false;
  isVerifying = false;
  isSendingAgain = false;
  countryCode: CountryISO = CountryISO.Cyprus;
  phoneNumber: string = '';
  isSendAgainDisabled = false;
  countdown: number = COUNTDOWN_IN_SECONDS;
  numOfResendsAllowed: number = NUMBER_OF_ALLOWED_SMS_RESENDS;
  noMoreResendsAllowed: boolean = false;

  phoneForm = this.fb.group({
    phoneNumber: ['', [Validators.required, phoneValidator()]],
  });

  verificationForm = this.fb.group({
    otp: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService,
    private registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    // gets country code
    this.isLoading = true;
    this.authService
      .getAuthenticatedUserObs()
      .pipe(first())
      .subscribe(
        (user) => {
          // set country code only if exists in ngx-intl-tel-input
          if (
            Object.values(CountryISO).includes(
              user?.country.code.toLowerCase() as CountryISO
            )
          ) {
            this.countryCode = user?.country.code.toLowerCase() as CountryISO;
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  verify(): void {
    this.isVerifying = true;
    const otp = this.verificationForm.get('otp')?.value;
    this.registrationService
      .verifyPhoneNumber(otp)
      .pipe(mergeMap(() => this.authService.getAuthenticatedUser())) // refreshes user state in the app
      .subscribe(
        () => {
          this.isVerifying = false;
          this.router.navigate(['onboarding-new']);
        },
        (err) => {
          this.isVerifying = false;
          if (err.status === 400) {
            this.otpControl?.setErrors({ wrongCode: true });
          } else {
            this.errorService.showErrorDialog();
          }
        }
      );
  }

  sendSms(again?: boolean): void {
    if (this.numOfResendsAllowed > 0) {
      if (again) this.startCountdown();
      this.phoneNumber = this.phoneForm.get('phoneNumber')?.value.e164Number;
      again ? (this.isSendingAgain = true) : (this.isSending = true);
      this.registrationService.sendSms(this.phoneNumber).subscribe(
        () => {
          this.smsSent = true;
          if (again) {
            this.smsSentAgain = true;
            this.isSendingAgain = false;
            this.numOfResendsAllowed -= 1;
          } else {
            this.isSending = false;
          }
        },
        (error) => {
          again ? (this.isSendingAgain = false) : (this.isSending = false);
          if (again) this.numOfResendsAllowed -= 1;
          if (error.status === 422) {
            this.phoneNumberControl?.setErrors({ unacceptableData: true });
          } else {
            this.errorService.showErrorDialog();
          }
        }
      );
    } else {
      this.noMoreResendsAllowed = true;
    }
  }

  async startCountdown() {
    this.isSendAgainDisabled = true;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.isSendAgainDisabled = false;
        this.smsSentAgain = false;
        this.countdown = COUNTDOWN_IN_SECONDS;
      }
    }, 1000);
  }

  get otpControl(): AbstractControl | null {
    return this.verificationForm.get('otp');
  }

  get phoneNumberControl(): AbstractControl | null {
    return this.phoneForm.get('phoneNumber');
  }
}
