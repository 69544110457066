<div [formGroup]="corporateForm">
  <div class="desc">
    Please fill in the form here below so your verification will be complete
    (along with the necessary documents).
  </div>

  <h2>Business Details</h2>
  <!-- BUSINESS NAME -->
  <app-onboarding-form-item-new
    label="Company legal name"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.businessName"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_NAME)"
  >
    <div fxLayout="column" class="field">
      <label for="businessName">Company legal name</label>
      <mat-form-field appearance="outline">
        <input
          data-testid="business-name-input"
          matInput
          name="businessName"
          formControlName="businessName"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="businessNameControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="businessNameControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="businessNameControl?.hasError('maxlength')"
          >Company legal name can’t be longer than 64 characters</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- TRADING NAME -->
  <app-onboarding-form-item-new
    label="Trading name"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.businessTradingName || '-'"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_TRADING_NAME)"
  >
    <div fxLayout="column" class="field">
      <label for="businessTradingName">Trading name (if applicable)</label>
      <mat-form-field appearance="outline">
        <input
          data-testid="trading-name-input"
          matInput
          name="businessTradingName"
          formControlName="businessTradingName"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="businessTradingNameControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="businessTradingNameControl?.hasError('maxlength')"
          >Trading name can’t be longer than 64 characters</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- COMPANY LEGAL STATUS -->
  <app-onboarding-form-item-new
    label="Company legal status"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.legalStatus"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_LEGAL_STATUS)"
  >
    <div fxLayout="column" class="field">
      <label for="legalStatus">Company legal status</label>
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="legalStatus"
          formControlName="legalStatus"
        >
          <mat-option
            *ngFor="let legalStatus of legalStatuses"
            [value]="legalStatus[1]"
            [attr.data-testid]="
              'legalStatus-option-' +
              legalStatus[1].split(' ').join('-').toLowerCase()
            "
            >{{ legalStatus[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="legalStatusControl?.hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- Enable when Other option is selected -->
    <div
      fxLayout="column"
      class="field"
      *ngIf="legalStatusControl?.value === LegalStatus['Other type of Company']"
    >
      <label for="otherLegalStatus"
        >Please specify if Other type of Company</label
      >
      <mat-form-field appearance="outline">
        <input
          matInput
          name="otherLegalStatus"
          formControlName="otherLegalStatus"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="otherLegalStatusControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="otherLegalStatusControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="otherLegalStatusControl?.hasError('maxlength')"
          >Company legal status can’t be longer than 100 characters</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- REGISTRATION NUMBER -->
  <app-onboarding-form-item-new
    label="Registration number"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.registrationNumber"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_REGISTRATION_NUMBER)"
  >
    <div fxLayout="column" class="field">
      <label for="registrationNumber">Registration number</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          data-testid="registration-number-input"
          name="registrationNumber"
          formControlName="registrationNumber"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="registrationNumberControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="registrationNumberControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="registrationNumberControl?.hasError('maxlength')"
          >Registration number can’t be longer than 50 characters</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- DATE OF INCORPORATION -->
  <app-onboarding-form-item-new
    label="Date of Incorporation"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.incorporationDate"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_DATE_OF_INCORPORATION)"
  >
    <div fxLayout="column" class="field">
      <label for="incorporationDate">Date of Incorporation</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          data-testid="date-of-incorporation-input"
          [matDatepicker]="picker"
          name="incorporationDate"
          formControlName="incorporationDate"
          readonly
          [max]="today"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="incorporationDateControl?.hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- COUNTRY OF INCORPORATION -->
  <app-onboarding-form-item-new
    label="Country of Incorporation"
    [isReviewed]="isReviewed"
    [value]="
      getCountryById(
        countries,
        onboardingData?.corporateForm?.incorporationAddress?.countryId
      )
    "
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_COUNTRY_OF_INCORPORATION)"
  >
    <div formGroupName="incorporationAddress" fxLayout="column" class="field">
      <label>Country of Incorporation</label>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          data-testid="country-of-incorporation-select"
          name="countryId"
          formControlName="countryId"
        >
          <mat-option
            *ngFor="let country of countries"
            [value]="country.id"
            [attr.data-testid]="
              'country-of-incorporation-' + country.name.toLowerCase()
            "
            >{{ country.name | titlecase }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="incorporationCountryIdControl?.hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- TAX IDENTIFICATION NUMBER -->
  <app-onboarding-form-item-new
    label="Tax Identification Number"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.taxNumber"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_TAX_NUMBER)"
  >
    <div fxLayout="column" class="field">
      <label for="taxNumber">Tax Identification Number</label>
      <mat-form-field appearance="outline">
        <input
          data-testid="tax-number-input"
          matInput
          name="taxNumber"
          formControlName="taxNumber"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="taxNumberControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="taxNumberControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="taxNumberControl?.hasError('maxlength')"
          >Tax Identification Number can’t be longer than 50
          characters</mat-error
        >
      </mat-form-field>
    </div>
    <mat-checkbox
      formControlName="taxNumberNotAvailable"
      color="primary"
      class="checkbox"
      >Tax Identification Number not available</mat-checkbox
    >
  </app-onboarding-form-item-new>

  <!-- VAT NUMBER -->
  <app-onboarding-form-item-new
    label="VAT Number"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.vatNumber || '-'"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_VAT_NUMBER)"
  >
    <div fxLayout="column" class="field">
      <label for="vatNumber">VAT Number</label>
      <mat-form-field appearance="outline">
        <input
          data-testid="vat-number-input"
          matInput
          name="vatNumber"
          formControlName="vatNumber"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="vatNumberControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="vatNumberControl?.hasError('maxlength')"
          >VAT Number can’t be longer than 50 characters</mat-error
        >
      </mat-form-field>
    </div>
    <mat-checkbox
      formControlName="vatNumberNotAvailable"
      color="primary"
      class="checkbox"
      >VAT Number not available</mat-checkbox
    >
  </app-onboarding-form-item-new>

  <!-- TAX/VAT COUNTRY -->
  <app-onboarding-form-item-new
    label="Country of Tax Residency/VAT Number"
    [isReviewed]="isReviewed"
    [value]="
      getCountryById(countries, onboardingData?.corporateForm?.taxAndVatCountry)
    "
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_TAX_AND_VAT_COUNTRY)"
    *ngIf="taxNumberControl?.value !== NA || vatNumberControl?.value !== NA"
  >
    <div fxLayout="column" class="field short">
      <label for="taxAndVatCountry">Country of Tax Residency/VAT Number</label>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          name="taxAndVatCountry"
          formControlName="taxAndVatCountry"
        >
          <mat-option
            *ngFor="let country of countries"
            [value]="country.id"
            [attr.data-testid]="
              'country-of-incorporation-' + country.name.toLowerCase()
            "
            >{{ country.name | titlecase }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="taxAndVatCountryControl?.hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- COMPANY URLS -->
  <app-onboarding-form-item-new
    label="Company URLs (if applicable)"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.companyUrls || '-'"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_URLS)"
  >
    <div fxLayout="column" class="field">
      <label for="companyUrls">Company URLs (if applicable)</label>
      <mat-form-field appearance="outline">
        <textarea
          rows="3"
          #urlInput
          maxlength="200"
          data-testid="company-urls-input"
          matInput
          name="companyUrls"
          formControlName="companyUrls"
        ></textarea>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyUrlsControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyUrlsControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyUrlsControl?.hasError('maxlength')"
          >Company URLs can’t be longer than 200 characters</mat-error
        >
      </mat-form-field>
      <div class="counter">
        <label
          >{{ urlInput.value.length }} /
          {{ urlInput.getAttribute('maxlength') }}</label
        >
      </div>
    </div>
  </app-onboarding-form-item-new>

  <h2 class="operating-address-title">
    <span [matTooltip]="registeredAddressTooltip">Registered Address</span>
    (required)
  </h2>
  <ng-container formGroupName="incorporationAddress">
    <!-- BUSINESS ADDRESS -->
    <app-onboarding-form-item-new
      label="Registered address (Street, building number, place)"
      [isReviewed]="isReviewed"
      [value]="
        onboardingData?.corporateForm?.incorporationAddress?.streetAddress
      "
      [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ADDRESS)"
    >
      <div fxLayout="column" class="field">
        <label style="margin-top: -15px">No PO Boxes</label>
        <label for="streetAddress"
          >Registered address (Street, building number, place)</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            data-testid="street-address-input"
            name="streetAddress"
            formControlName="streetAddress"
          />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="incorporationStreetAddressControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="incorporationStreetAddressControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="incorporationStreetAddressControl?.hasError('maxlength')"
            >Address can’t be longer than 50 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- BUSINESS ADDRESS 2ND LINE -->
    <app-onboarding-form-item-new
      label="Registered address 2nd line (optional)"
      [isReviewed]="isReviewed"
      [value]="
        onboardingData?.corporateForm?.incorporationAddress
          ?.additionalStreetAddress
      "
      [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ADDRESS_2ND_LINE)"
    >
      <div fxLayout="column" class="field">
        <label for="additionalStreetAddress"
          >Registered address 2nd line (optional)</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            data-testid="street-address-second-part-input"
            name="additionalStreetAddress"
            formControlName="additionalStreetAddress"
          />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="
              incorporationAdditionalStreetAddressControl?.hasError('pattern')
            "
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="
              incorporationAdditionalStreetAddressControl?.hasError('maxlength')
            "
            >Address can’t be longer than 50 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <div
      [fxLayout]="isReviewed ? 'column' : 'row'"
      [class.field]="!isReviewed"
      fxLayoutAlign="space-between"
    >
      <!-- ZIP/POSTAL CODE -->
      <app-onboarding-form-item-new
        label="Zip/Postal code"
        [isReviewed]="isReviewed"
        [value]="onboardingData?.corporateForm?.incorporationAddress?.postCode"
        [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ZIP_POSTAL_CODE)"
      >
        <div fxLayout="column" class="zip" [class.field]="isReviewed">
          <label for="postCode">Zip/Postal code</label>
          <mat-form-field appearance="outline">
            <input
              matInput
              data-testid="post-code-input"
              name="postCode"
              formControlName="postCode"
            />
            <mat-error
              data-testid="validation-message-box"
              *ngIf="incorporationPostCodeControl?.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error
              data-testid="validation-message-box"
              *ngIf="incorporationPostCodeControl?.hasError('pattern')"
              >Forbidden char.</mat-error
            >
            <mat-error
              data-testid="validation-message-box"
              *ngIf="incorporationPostCodeControl?.hasError('maxlength')"
              >Too long</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>

      <!-- CITY -->
      <app-onboarding-form-item-new
        label="City"
        [isReviewed]="isReviewed"
        [value]="onboardingData?.corporateForm?.incorporationAddress?.city"
        [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_CITY)"
      >
        <div fxLayout="column" class="city" [class.field]="isReviewed">
          <label for="city">City</label>
          <mat-form-field appearance="outline">
            <input
              data-testid="city-input"
              matInput
              name="city"
              formControlName="city"
            />
            <mat-error
              data-testid="validation-message-box"
              *ngIf="incorporationCityControl?.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error
              data-testid="validation-message-box"
              *ngIf="incorporationCityControl?.hasError('pattern')"
              >Forbidden character used</mat-error
            >
            <mat-error
              data-testid="validation-message-box"
              *ngIf="incorporationCityControl?.hasError('maxlength')"
              >City can’t be longer than 25 characters</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>
    </div>
  </ng-container>

  <h2 class="operating-address-title">
    <span [matTooltip]="operatingAddressTooltip">Operating Address</span>
    (required)
  </h2>
  <mat-radio-group
    *ngIf="!isReviewed"
    fxLayout="column"
    fxLayoutGap="24px"
    name="isSameAddress"
    formControlName="isSameAddress"
  >
    <mat-radio-button [value]="false">
      <div data-testid="operating-address-different">
        Operating address is <strong>different</strong> from the registered
        address
      </div>
    </mat-radio-button>
    <mat-radio-button [value]="true">
      <div data-testid="operating-address-same">
        Operating address is the <strong>same</strong> as the registered address
      </div>
    </mat-radio-button>
  </mat-radio-group>

  <div
    formGroupName="operatingAddress"
    data-testid="operation-address-container"
    *ngIf="isSameAddressControl?.value === false || isReviewed"
    class="operating-address-container"
  >
    <!-- OPERATING ADDRESS -->
    <app-onboarding-form-item-new
      label="Operating address (Street, building number, place)"
      [isReviewed]="isReviewed"
      [value]="onboardingData?.corporateForm?.operatingAddress?.streetAddress"
      [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_OPERATING_ADDRESS)"
    >
      <div fxLayout="column" class="field">
        <label style="margin-top: -15px">No PO Boxes</label>
        <label for="streetAddress"
          >Operating address (Street, building number, place)</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            data-testid="operating-street-address-input"
            name="streetAddress"
            formControlName="streetAddress"
          />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="operatingStreetAddressControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="operatingStreetAddressControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="operatingStreetAddressControl?.hasError('maxlength')"
            >Address can’t be longer than 50 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- OPERATING ADDRESS 2ND LINE (OPTIONAL) -->
    <app-onboarding-form-item-new
      label="Operating address 2nd line (optional)"
      [isReviewed]="isReviewed"
      [value]="
        onboardingData?.corporateForm?.operatingAddress?.additionalStreetAddress
      "
      [field]="
        fieldsMap.get(OnboardingFieldId.BUSINESS_OPERATING_ADDRESS_2ND_LINE)
      "
    >
      <div fxLayout="column" class="field">
        <label for="additionalStreetAddress"
          >Operating address 2nd line (optional)</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            data-testid="operating-street-address-second-part-input"
            name="additionalStreetAddress"
            formControlName="additionalStreetAddress"
          />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="operatingAdditionalStreetAddressControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="
              operatingAdditionalStreetAddressControl?.hasError('maxlength')
            "
            >Address can’t be longer than 50 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <div
      [fxLayout]="isReviewed ? 'column' : 'row'"
      fxLayoutAlign="space-between"
      [class.field]="!isReviewed"
    >
      <!-- ZIP/POSTAL CODE -->
      <app-onboarding-form-item-new
        label="Zip/Postal code"
        [isReviewed]="isReviewed"
        [value]="onboardingData?.corporateForm?.operatingAddress?.postCode"
        [field]="
          fieldsMap.get(OnboardingFieldId.BUSINESS_OPERATING_ZIP_POSTAL_CODE)
        "
      >
        <div fxLayout="column" class="zip" [class.field]="isReviewed">
          <label for="postCode">Zip/Postal code</label>
          <mat-form-field appearance="outline">
            <input
              data-testid="operating-post-code-input"
              matInput
              name="postCode"
              formControlName="postCode"
            />
            <mat-error
              data-testid="validation-message-box"
              *ngIf="operatingPostCodeControl?.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error
              data-testid="validation-message-box"
              *ngIf="operatingPostCodeControl?.hasError('pattern')"
              >Forbidden char.</mat-error
            >
            <mat-error
              data-testid="validation-message-box"
              *ngIf="operatingPostCodeControl?.hasError('maxlength')"
              >Too long</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>

      <!-- CITY -->
      <app-onboarding-form-item-new
        label="City"
        [isReviewed]="isReviewed"
        [value]="onboardingData?.corporateForm?.operatingAddress?.city"
        [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_OPERATING_CITY)"
      >
        <div fxLayout="column" class="city" [class.field]="isReviewed">
          <label for="city">City</label>
          <mat-form-field appearance="outline">
            <input
              data-testid="operating-city-input"
              matInput
              name="city"
              formControlName="city"
            />
            <mat-error
              data-testid="validation-message-box"
              *ngIf="operatingCityControl?.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error
              data-testid="validation-message-box"
              *ngIf="operatingCityControl?.hasError('pattern')"
              >Forbidden character used</mat-error
            >
            <mat-error
              data-testid="validation-message-box"
              *ngIf="operatingCityControl?.hasError('maxlength')"
              >City can’t be longer than 25 characters</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>
    </div>
    <!-- OPERATING ADDRESS COUNTRY -->
    <app-onboarding-form-item-new
      label="Operating address country"
      [isReviewed]="isReviewed"
      [value]="
        getCountryById(
          countries,
          onboardingData?.corporateForm?.operatingAddress?.countryId
        )
      "
      [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_OPERATING_COUNTRY)"
    >
      <div fxLayout="column" class="field">
        <label for="countryId">Operating address country</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            data-testid="operating-country-select"
            name="countryId"
            formControlName="countryId"
          >
            <mat-option
              *ngFor="let country of acceptedCountries"
              [value]="country.id"
              [attr.data-testid]="
                'operating-country-' + country.name.toLowerCase()
              "
              >{{ country.name | titlecase }}</mat-option
            >
          </mat-select>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="operatingCountryIdControl?.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>
  </div>

  <h2>Business Activity (required)</h2>
  <!-- COMPANY BUSINESS ACTIVITY -->
  <app-onboarding-form-item-new
    label="Business Activity"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.companyActivity"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ACTIVITY)"
  >
    <div fxLayout="column" class="field">
      <label for="companyActivity">Business activity</label>
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="companyActivity"
          formControlName="companyActivity"
        >
          <mat-option
            style="
              height: auto;
              white-space: normal;
              line-height: initial;
              padding: 10px;
            "
            *ngFor="let occupation of occupations"
            [value]="occupation[1]"
            [attr.data-testid]="
              'occupation-option-' +
              occupation[1].split(' ').join('-').toLowerCase()
            "
            >{{ occupation[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyActivityControl?.hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- COMPANY BUSINESS ACTIVITY DESCRIPTION -->
  <app-onboarding-form-item-new
    label="Business Activity Description"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.companyActivityDescr"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ACTIVITY_DESCR)"
  >
    <div fxLayout="column" class="field">
      <label for="companyActivityDescr"
        >Please provide a brief description of the company's business
        activities</label
      >
      <mat-form-field appearance="outline">
        <textarea
          rows="3"
          #activityInput
          maxlength="1000"
          data-testid="company-activity-desc-input"
          matInput
          name="companyActivityDescr"
          formControlName="companyActivityDescr"
        ></textarea>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyActivityDescrControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyActivityDescrControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyActivityDescrControl?.hasError('maxlength')"
          >Company activity can’t be longer than 1000 characters</mat-error
        >
      </mat-form-field>
      <div class="counter">
        <label
          >{{ activityInput.value.length }} /
          {{ activityInput.getAttribute('maxlength') }}</label
        >
      </div>
    </div>
  </app-onboarding-form-item-new>

  <h2>Other Information (required)</h2>

  <!-- ANNUAL INCOME -->
  <app-onboarding-form-item-new
    label="Annual income of the last year's financial statement"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.annualIncome"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ANNUAL_INCOME)"
  >
    <div fxLayout="column" class="field">
      <label for="annualIncome"
        >Annual income of the last year's financial statement</label
      >
      <mat-form-field appearance="outline">
        <input
          placeholder="0"
          maxlength="19"
          data-testid="company-annual-income-input"
          matInput
          name="annualIncome"
          formControlName="annualIncome"
          [value]="annualIncomeControl?.value | commify"
        />
        <div matSuffix>EUR</div>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="annualIncomeControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="annualIncomeControl?.hasError('pattern')"
          >Only numbers are allowed</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="annualIncomeControl?.hasError('maxlength')"
          >Annual income can’t be longer than 19 characters</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- ANNUAL INCOME EXPECTED TO AT LEAST DOUBLE -->
  <app-onboarding-form-item-new
    label="Is your annual income expected to at least double within the next 12 months?"
    [isReviewed]="isReviewed"
    [value]="getStringValue(onboardingData?.corporateForm?.doubleAnnualIncome)"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_DOUBLE_ANNUAL_INCOME)"
  >
    <label
      >Is your annual income expected to at least double within the next 12
      months?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="doubleAnnualIncome"
      formControlName="doubleAnnualIncome"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>
  </app-onboarding-form-item-new>

  <!-- PERCENTAGE OF CASH TRANSACTIONS ON TOTAL ACTIVITIES  -->
  <app-onboarding-form-item-new
    label="Percentage of cash transactions on total activities"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.percentageOfCash"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_PERCENTAGE_OF_CASH)"
  >
    <div fxLayout="column" class="field">
      <label for="percentageOfCash" class="field-space"
        >Percentage of cash transactions on total activities</label
      >
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="percentageOfCash"
          formControlName="percentageOfCash"
        >
          <mat-option
            *ngFor="let cashPercentage of cashPercentages"
            [value]="cashPercentage[1]"
            >{{ cashPercentage[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="percentageOfCashControl?.hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- IS REGULATED -->
  <!-- admin comment appears on only one field (isRegulated) but it also includes regulatoryAuthority,
    because if comment exists only on isRegulated, the regulatoryAuthority field does not appear,
    so the two fields are interconnected -->
  <app-onboarding-form-item-new
    label="Are you regulated by any authority?"
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.isRegulated) +
      (onboardingData?.corporateForm?.regulatoryAuthority
        ? ', ' + onboardingData?.corporateForm?.regulatoryAuthority
        : '')
    "
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_IS_REGULATED)"
  >
    <label for="isRegulated" class="field-space"
      >Are you regulated by any authority?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="isRegulated"
      formControlName="isRegulated"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>

    <!-- REGULATORY AUTHORITY -->
    <div
      *ngIf="isRegulatedControl?.value"
      fxLayout="column"
      class="field reg-authority"
    >
      <label for="regulatoryAuthority">Please specify which authority</label>
      <mat-form-field appearance="outline">
        <textarea
          rows="3"
          #authInput
          maxlength="200"
          data-testid="company-regulatory-authority-input"
          matInput
          name="regulatoryAuthority"
          formControlName="regulatoryAuthority"
        ></textarea>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="regulatoryAuthorityControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="regulatoryAuthorityControl?.hasError('pattern')"
          >Forbidden character used</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="regulatoryAuthorityControl?.hasError('maxlength')"
          >Regulatory authority can’t be longer than 200 characters</mat-error
        >
      </mat-form-field>
      <div class="counter">
        <label
          >{{ authInput.value.length }} /
          {{ authInput.getAttribute('maxlength') }}</label
        >
      </div>
    </div>
  </app-onboarding-form-item-new>

  <!-- OPERATE IN HIGH RISK COUNTRIES  -->
  <app-onboarding-form-item-new
    label="Do you operate in any of the following countries: Afghanistan,  Albania, American Samoa,  Anguilla, Bahamas, Barbados, Belarus, British Virgin Islands, Burkina Faso, Cambodia, Cayman Islands, Costa Rica, 
    Democratic Republic of Congo (DRC), Democratic People’s Republic of Korea (DPRK), Fiji Islands, Gibraltar, Guam, Haiti, Iran, Jamaica, Jordan, Mali, Marshall Islands, Morocco, Mozambique, Myanmar, Nigeria, Palau, 
    Panama, Philippines, Russia, Samoa, Senegal, South Africa, South Sudan, Syria, Tanzania, Trinidad & Tobago, Turkey, Turks and Caicos islands, Uganda, United Arab Emirates, US Virgin Islands, Vanuatu, Yemen."
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.operateInHighRiskCountries)
    "
    [field]="
      fieldsMap.get(OnboardingFieldId.BUSINESS_OPERATE_IN_HIGH_RISK_COUNTRIES)
    "
  >
    <div fxLayout="column" class="field">
      <label>Do you operate in any of the following countries:</label>
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="List of countries"
          fxLayout="column"
          fxLayoutGap="14px"
        >
          <mat-option
            class="disable-selection"
            *ngFor="let country of highRiskCountries"
            [value]="country"
            >{{ country }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-radio-group
        class="radio-countries"
        fxLayout="row"
        fxLayoutGap="14px"
        name="operateInHighRiskCountries"
        formControlName="operateInHighRiskCountries"
      >
        <mat-radio-button [value]="true">
          <div>Yes</div>
        </mat-radio-button>
        <mat-radio-button [value]="false">
          <div>No</div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </app-onboarding-form-item-new>

  <!-- CAN FUND FROM OWN ACCOUNT  -->
  <app-onboarding-form-item-new
    label="Are you able to initially fund your eCREDO account from an existing account at a bank registered and licensed in an EEA country?"
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.canFundFromOwnAccount)
    "
    [field]="
      fieldsMap.get(OnboardingFieldId.BUSINESS_CAN_FUND_FROM_OWN_ACCOUNT)
    "
  >
    <label
      >Are you able to initially fund your eCREDO account from an existing
      account at a bank registered and licensed in an EEA country?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="canFundFromOwnAccount"
      formControlName="canFundFromOwnAccount"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>
  </app-onboarding-form-item-new>

  <!-- ARE FINANCIAL STATEMENTS AUDITED  -->
  <app-onboarding-form-item-new
    label="Do you have financial statements (no older than two years) audited by a registered certified public accountant/auditor?"
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.isFinancialStatementAudited)
    "
    [field]="
      fieldsMap.get(OnboardingFieldId.BUSINESS_IS_FINANCIAL_STATEMENT_AUDITED)
    "
  >
    <label
      >Do you have financial statements (no older than two years) audited by a
      registered certified public accountant/auditor?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="isFinancialStatementAudited"
      formControlName="isFinancialStatementAudited"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>
  </app-onboarding-form-item-new>

  <!-- ZERO BALANCE IN LAST YEAR'S FINANCIAL STATEMENTS -->
  <app-onboarding-form-item-new
    label="Do your last year's financial statements contain zero balance in “Total assets” or “Total equities and liabilities” in the balance sheet?"
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.zeroBalanceStatement)
    "
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ZERO_BALANCE_STATEMENT)"
  >
    <label
      >Do your last year's financial statements contain zero balance in "Total
      assets" or "Total equities and liabilities" in the balance sheet?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="zeroBalanceStatement"
      formControlName="zeroBalanceStatement"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>
  </app-onboarding-form-item-new>

  <h2>Account Information (required)</h2>
  <!-- ACCOUNT OPENING PURPOSE -->
  <app-onboarding-form-item-new
    label="Account opening purpose"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.accountPurpose"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ACCOUNT_PURPOSE)"
  >
    <div fxLayout="column" class="field">
      <label for="accountPurpose">Account opening purpose</label>
      <mat-form-field>
        <mat-select
          placeholder="Please select"
          name="accountPurpose"
          formControlName="accountPurpose"
          multiple
        >
          <mat-option
            style="height: 50px; white-space: normal; line-height: initial"
            *ngFor="let accountPurpose of accountPurposes"
            [value]="accountPurpose[0]"
            >{{ accountPurpose[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="accountPurposeControl?.hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- EXPECTED MONTHLY LOADING -->
  <app-onboarding-form-item-new
    label="Expected monthly loading"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.monthlyLoading"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_MONTHLY_LOADING)"
  >
    <div fxLayout="column" class="field">
      <label for="monthlyLoading">Expected monthly loading</label>
      <mat-form-field appearance="outline">
        <input
          placeholder="0"
          maxlength="19"
          data-testid="company-monthly-loading-input"
          matInput
          name="monthlyLoading"
          formControlName="monthlyLoading"
          [value]="monthlyLoadingControl?.value | commify"
        />
        <div matSuffix>EUR</div>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="monthlyLoadingControl?.hasError('required')"
          >Field is required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="monthlyLoadingControl?.hasError('pattern')"
          >Only numbers are allowed</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="monthlyLoadingControl?.hasError('maxlength')"
          >Expected monthly loading can’t be longer than 19
          characters</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <!-- EXPECTED NUMBER OF PLASTIC CARDS -->
  <app-onboarding-form-item-new
    label="Expected number of physical cards required"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.expectedNumOfPhysicalCards"
    [field]="
      fieldsMap.get(
        OnboardingFieldId.BUSINESS_EXPECTED_NUMBER_OF_PHYSICAL_CARDS
      )
    "
  >
    <div fxLayout="column" class="field">
      <label for="expectedNumOfPhysicalCards"
        >Expected number of physical cards required</label
      >
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="expectedNumOfPhysicalCards"
          formControlName="expectedNumOfPhysicalCards"
        >
          <mat-option
            *ngFor="let numOfPhysicalCards of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
            [value]="numOfPhysicalCards"
            >{{ numOfPhysicalCards }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="expectedNumOfPhysicalCardsControl?.hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </div>
  </app-onboarding-form-item-new>

  <h2>Additional phone number (optional)</h2>
  <!-- ADDITIONAL PHONE NUMBER (OPTIONAL) -->
  <app-onboarding-form-item-new
    label="Phone number"
    [isReviewed]="isReviewed"
    [value]="onboardingData?.corporateForm?.additionalPhoneNumber"
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ADDITIONAL_PHONE_NUMBER)"
  >
    <div fxLayout="column" class="field phone-number short">
      <label for="additionalPhoneNumber">Phone number</label>
      <ngx-intl-tel-input
        [enablePlaceholder]="false"
        [searchCountryFlag]="true"
        [separateDialCode]="true"
        data-testid="optional-phone-number-input"
        [selectedCountryISO]="countryCode"
        name="additionalPhoneNumber"
        formControlName="additionalPhoneNumber"
      >
      </ngx-intl-tel-input>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="
          additionalPhoneNumberControl?.invalid &&
          additionalPhoneNumberControl?.touched
        "
        >Number is invalid</mat-error
      >
    </div>
  </app-onboarding-form-item-new>

  <div fxLayoutAlign="space-between center">
    <button
      class="save-button"
      mat-flat-button
      color="accent"
      data-testid="save-form-button"
      [disabled]="
        corporateForm.invalid ||
        isSaving ||
        corporateForm.pristine ||
        isFirstTimeEDD
      "
      [appButtonLoader]="isSaving"
      (click)="saveCorporateForm()"
    >
      Save
    </button>
    <ng-container [ngSwitch]="isComplete | async">
      <div *ngSwitchCase="true" class="status complete">Complete</div>
      <div *ngSwitchCase="false" class="status">Incomplete</div>
    </ng-container>
  </div>
</div>

<!-- TEMPLATE -->
<ng-template #review let-label="label" let-value="value" let-field="field">
  <ng-container *ngIf="isReviewed">
    <div
      fxLayout="row"
      fxLayoutGap="20px grid"
      class="submitted"
      [class.comment]="field?.comment"
    >
      <div fxFlex="50" class="name">{{ label }}</div>
      <div fxFlex="50" class="value">{{ field?.commentedValue || value }}</div>
    </div>
    <div *ngIf="field?.comment" class="comment">
      {{ field?.comment }}
    </div>
  </ng-container>
</ng-template>
