<div class="main-container">
  <div fxLayout="row" fxLayoutAlign="start center">
    <h1>Favourites</h1>
    <mat-icon class="material-icons-outlined info" (click)="showInfo()"
      >info</mat-icon
    >
  </div>
  <br />
  <div fxLayout="column">
    <label for="search">Search</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="search"
        [formControl]="searchControl"
        placeholder="Search favourite"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="results-container">
    <app-big-loader *ngIf="isLoadingFavourites || isLoading"></app-big-loader>
    <ng-container *ngIf="!isLoadingFavourites && !isLoading">
      <mat-card *ngFor="let favourite of favourites">
        <div
          fxLayout
          fxLayout.lt-sm="column"
          class="data-container"
          fxLayoutGap="24px"
        >
          <!-- LEFT COLUMN -->
          <div fxFlex="45%" fxFlex.lt-md="100%">
            <h1>{{ favourite.name }}</h1>
            <!-- TRANSFER TYPE -->
            <div
              fxLayout
              fxLayoutAlign="start center"
              fxLayoutGap="4px"
              class="section big-text"
              [ngSwitch]="favourite.transferType"
            >
              <ng-container *ngSwitchCase="TransferType.SEPA">
                <span>SEPA Transfer</span>
                <mat-icon
                  class="material-icons-outlined"
                  color="accent"
                  [matTooltip]="sepaTooltipText"
                  >info</mat-icon
                >
              </ng-container>
              <ng-container *ngSwitchCase="TransferType.SWIFT">
                SWIFT Transfer
              </ng-container>
              <ng-container *ngSwitchCase="TransferType.E2E">
                e2e Now Transfer
              </ng-container>
            </div>

            <div fxLayout>
              <div *ngIf="favourite.rateCurrency" fxFlex="55%" class="section">
                <div>Currency</div>
                <div class="big-text">
                  {{ favourite.rateCurrency }}
                </div>
              </div>
            </div>
          </div>

          <!-- RIGHT COLUMN -->
          <div fxFlex="55%" fxFlex.lt-md="100%">
            <h1>From</h1>
            <div class="section">
              <div class="medium-text">
                {{ getFromAccountName(favourite) }}
              </div>
            </div>

            <h1>To</h1>
            <div class="section medium-text">
              <div>{{ favourite.benName }}</div>
              <div>{{ favourite.benAddress }}</div>
              <div>
                {{ favourite.benPostCode }}
                {{ favourite.benCity }}
              </div>
              <div>
                {{ getCountryByCode(countries, favourite.benCountryCode) }}
              </div>
            </div>

            <div *ngIf="favourite.benAccNo" class="section">
              <div>IBAN or Account Number</div>
              <div class="medium-text">{{ favourite.benAccNo }}</div>
            </div>
            <div *ngIf="favourite.benBic" class="section">
              <div>Bank SWIFT / BIC</div>
              <div class="medium-text">{{ favourite.benBic }}</div>
            </div>
            <div *ngIf="favourite.benBankName" class="section">
              <div>Bank Name</div>
              <div class="medium-text">
                {{ favourite.benBankName }}
              </div>
            </div>
            <div *ngIf="favourite.benBankAddress" class="section">
              <div>Bank Address</div>
              <div class="medium-text">
                {{ favourite.benBankAddress }}
              </div>
            </div>

            <div *ngIf="favourite.intermediaryBankBic" class="section">
              <div>Intermediary Bank SWIFT / BIC</div>
              <div class="medium-text">{{ favourite.intermediaryBankBic }}</div>
            </div>
            <div *ngIf="favourite.intermediaryBankName" class="section">
              <div>Intermediary Bank Name</div>
              <div class="medium-text">
                {{ favourite.intermediaryBankName }}
              </div>
            </div>
            <div *ngIf="favourite.intermediaryBankAddress" class="section">
              <div>Intermediary Bank Address</div>
              <div class="medium-text">
                {{ favourite.intermediaryBankAddress }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="buttons-container"
          fxLayout
          fxLayout.lt-sm="column"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-sm="start"
          fxLayoutGap="30px"
        >
          <div
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
            class="fake-button"
            (click)="makeTransfer(favourite)"
          >
            <mat-icon class="material-icons-outlined">send</mat-icon>
            <div>Make transfer</div>
          </div>
          <!-- REMOVE -->
          <button
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
            mat-icon-button
            disableRipple
            class="fake-button"
            [disabled]="isRemoving"
            [appButtonLoader]="isRemovingFavourite(favourite.guid)"
            (click)="removeFavourite(favourite.guid)"
          >
            <mat-icon svgIcon="cancel"></mat-icon>
            <span class="remove">Remove from Favourites</span>
          </button>
        </div>
      </mat-card>
    </ng-container>
  </div>
</div>
