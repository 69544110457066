import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WalletComingSoonGuard implements CanActivate {
  constructor() {}

  canActivate(): boolean {
    return !environment.DISABLE_WALLET; // return true to allow access
  }
}
