export const environment = {
  production: false,
  BACKEND_URL: 'https://localhost:3000',
  SP_URL: 'https://app.shuftipro.com',
  ALLOWED_DOMAINS: ['localhost:3000'], // list of domains to which app can send auth headers
  DISABLE_CARDS: true,
  DISABLE_WALLET: true,
  DISABLE_SWIFT: true,
  DISABLE_PROFILE: true,
  DISABLE_UPGRADES: true,
  DISABLE_REGISTRATION: false,
  TIMEOUT: 29.5,
};
