<div [formGroup]="groupMemberForm" class="main">
  <ng-container>
    <div fxLayoutAlign="start center" class="header">
      <h2>{{ index + 1 | ordinalNumber }} Group Member</h2>
      <ng-container
        *ngTemplateOutlet="removeButton; context: { $implicit: groupMember }"
      ></ng-container>
    </div>

    <!-- NAME -->
    <app-onboarding-form-item-new
      label="Company name"
      [isReviewed]="isReviewed"
      [value]="groupMember.name"
      [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_GROUP_MEMBER_NAME)"
    >
      <div fxLayout="column" class="field">
        <label for="name">Company name</label>
        <mat-form-field appearance="outline">
          <input matInput name="name" formControlName="name" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('maxlength')"
            >Company name can’t be longer than 64 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- REGISTRATION NUMBER -->
    <app-onboarding-form-item-new
      label="Registration number"
      [isReviewed]="isReviewed"
      [value]="groupMember.registrationNumber"
      [field]="
        fieldsMap.get(
          OnboardingFieldId.BUSINESS_GROUP_MEMBER_REGISTRATION_NUMBER
        )
      "
    >
      <div fxLayout="column" class="field">
        <label for="registrationNumber">Registration number</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            name="registrationNumber"
            formControlName="registrationNumber"
          />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="registrationNumberControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="registrationNumberControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="registrationNumberControl?.hasError('maxlength')"
            >Registration number can’t be longer than 50 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- DATE OF INCORPORATION -->
    <app-onboarding-form-item-new
      label="Date of Incorporation"
      [isReviewed]="isReviewed"
      [value]="groupMember.incorporationDate"
      [field]="
        fieldsMap.get(
          OnboardingFieldId.BUSINESS_GROUP_MEMBER_DATE_OF_INCORPORATION
        )
      "
    >
      <div fxLayout="column" class="field">
        <label for="incorporationDate">Date of Incorporation</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            data-testid="date-of-incorporation-input"
            [matDatepicker]="picker"
            name="incorporationDate"
            formControlName="incorporationDate"
            readonly
            [max]="today"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="incorporationDateControl?.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- ADDRESS -->
    <ng-container formGroupName="address">
      <h2>Registered Address</h2>
      <app-onboarding-form-item-new
        label="Address (Street, building number, place)"
        [isReviewed]="isReviewed"
        [value]="groupMember.address?.streetAddress"
        [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_GROUP_MEMBER_ADDRESS)"
      >
        <div fxLayout="column" class="field">
          <label>No PO Boxes</label>
          <label for="streetAddress"
            >Address (Street, building number, place)</label
          >
          <mat-form-field appearance="outline">
            <input
              matInput
              name="streetAddress"
              formControlName="streetAddress"
            />
            <mat-error *ngIf="streetAddressControl?.hasError('required')"
              >Field is required</mat-error
            >
            <mat-error *ngIf="streetAddressControl?.hasError('pattern')"
              >Forbidden character used</mat-error
            >
            <mat-error *ngIf="streetAddressControl?.hasError('maxlength')"
              >Address can’t be longer than 50 characters</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>
      <app-onboarding-form-item-new
        label="Address 2nd line (optional)"
        [isReviewed]="isReviewed"
        [value]="groupMember.address?.additionalStreetAddress"
        [field]="
          fieldsMap.get(
            OnboardingFieldId.BUSINESS_GROUP_MEMBER_ADDRESS_2ND_LINE
          )
        "
      >
        <div fxLayout="column" class="field">
          <label for="additionalStreetAddress"
            >Address 2nd line (optional)</label
          >
          <mat-form-field appearance="outline">
            <input
              matInput
              name="additionalStreetAddress"
              formControlName="additionalStreetAddress"
            />
            <mat-error
              *ngIf="additionalStreetAddressControl?.hasError('pattern')"
              >Forbidden character used</mat-error
            >
            <mat-error
              *ngIf="additionalStreetAddressControl?.hasError('maxlength')"
              >Address can’t be longer than 50 characters</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>

      <div
        [fxLayout]="isReviewed ? 'column' : 'row'"
        [class.field]="!isReviewed"
        [fxLayoutGap]="isReviewed ? '0px' : '35px'"
      >
        <app-onboarding-form-item-new
          label="Zip/Postal code"
          [isReviewed]="isReviewed"
          [value]="groupMember.address?.postCode"
          [field]="
            fieldsMap.get(
              OnboardingFieldId.BUSINESS_GROUP_MEMBER_ZIP_POSTAL_CODE
            )
          "
        >
          <div fxLayout="column" class="zip">
            <label for="postCode">Zip/Postal code</label>
            <mat-form-field appearance="outline">
              <input matInput name="postCode" formControlName="postCode" />
              <mat-error *ngIf="postCodeControl?.hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="postCodeControl?.hasError('pattern')"
                >Forbidden char.</mat-error
              >
              <mat-error *ngIf="postCodeControl?.hasError('maxlength')"
                >Too long</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <app-onboarding-form-item-new
          label="City"
          [isReviewed]="isReviewed"
          [value]="groupMember.address?.city"
          [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_GROUP_MEMBER_CITY)"
        >
          <div fxLayout="column" class="city">
            <label for="city">City</label>
            <mat-form-field appearance="outline">
              <input matInput name="city" formControlName="city" />
              <mat-error *ngIf="cityControl?.hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="cityControl?.hasError('pattern')"
                >Forbidden character used</mat-error
              >
              <mat-error *ngIf="cityControl?.hasError('maxlength')"
                >City can’t be longer than 25 characters</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>
      </div>

      <app-onboarding-form-item-new
        label="Country"
        [isReviewed]="isReviewed"
        [value]="getCountryById(countries, groupMember.address?.countryId)"
        [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_GROUP_MEMBER_COUNTRY)"
      >
        <div fxLayout="column" class="field short">
          <label for="countryId">Country</label>
          <mat-form-field appearance="outline">
            <mat-select
              placeholder="Please select"
              name="countryId"
              formControlName="countryId"
            >
              <mat-option
                *ngFor="let country of countries"
                [value]="country.id"
                >{{ country.name | titlecase }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="countryIdControl?.hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
        </div>
      </app-onboarding-form-item-new>
    </ng-container>
  </ng-container>
</div>

<ng-template #removeButton>
  <button
    *ngIf="!isReviewed"
    mat-icon-button
    (click)="removeGroupMember(groupMember)"
    [disabled]="isRemoving"
    [appButtonLoader]="isRemoving"
  >
    <mat-icon svgIcon="cancel"></mat-icon>
  </button>
</ng-template>
