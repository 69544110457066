import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { RegistrationService } from 'src/app/login/services/registration.service';
import { PasswordComponent } from 'src/app/login/shared/password/password.component';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { jointLengthValidator } from 'src/app/shared/joint-length.validator';
import { Country } from '../../../../shared/models/country.model';
import { Program } from '../../../models/program.enum';
import { Registration } from '../../../models/registration.model';
import { UserType } from '../../../models/user-type.enum';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from 'src/app/login/services/auth.service';
import { ClientIpAddress } from 'src/app/login/models/client-ip-address.model';
import {
  NA,
  firstOrLastNameValidator,
} from 'src/app/shared/helpers/various-helpers.helper';

@Component({
  selector: 'app-provide-data',
  templateUrl: './provide-data.component.html',
  styleUrls: ['./provide-data.component.scss'],
})
export class ProvideDataComponent implements OnInit {
  @ViewChild(PasswordComponent, { static: true })
  passwordComp!: PasswordComponent;

  @Input() set selectedProgram(val: Program | null) {
    this.selectedProgramName = val ? Program[val]?.toLowerCase() : '';
    // sets userType
    this.userType =
      val === Program.JADE || val === Program.EMERALD
        ? UserType.PERSONAL
        : UserType.CORPORATE;
    this._selectedProgram = val;
  }
  get selectedProgram(): Program | null {
    return this._selectedProgram;
  }
  @Output() changeProgram = new EventEmitter<void>();
  @Output() registered = new EventEmitter<void>();

  programs = Program;
  userTypes = UserType;
  registerForm: FormGroup = new FormGroup({});
  selectedProgramName: string = '';
  userType: UserType = UserType.PERSONAL;
  acceptedCountriesObs: Observable<Country[]> = of([]);
  isLoading = false;
  private _selectedProgram: Program | null = null;
  operatingAddressTooltip: string =
    'The operating address is where the company has existence of a place of business or activity (registered branch).';

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private registrationService: RegistrationService,
    private sharedService: SharedService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.acceptedCountriesObs = this.sharedService.getAllCountries();

    this.registerForm = this.fb.group(
      {
        firstName: ['', [Validators.required, ...firstOrLastNameValidator]],
        lastName: ['', [Validators.required, ...firstOrLastNameValidator]],
        email: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
          ],
        ],
        passwordGroup: this.passwordComp.getGroup(),
        country: [null, Validators.required],
        termsAccepted: [false, Validators.requiredTrue],
        consent: [null, Validators.required],
      },
      {
        validators: [jointLengthValidator('firstName', 'lastName', 64)],
      }
    );
  }

  async register(): Promise<void> {
    this.isLoading = true;
    const ipPromise: ClientIpAddress = await this.authService
      .getIpAddress()
      .toPromise();
    const clientIp = ipPromise ? ipPromise.ip : NA;
    const body: Registration = {
      userType: this.userType,
      program: this.selectedProgram as Program,
      firstName: this.firstName,
      lastName: this.lastName,
      countryId: this.country.id,
      email: this.email,
      password: this.password,
      acceptedTerms: this.termsAccepted,
      acceptedPrivacy: true,
      consent: this.consent,
      ipAddress: clientIp,
    };
    (await this.registrationService.register(body)).subscribe(
      () => {
        this.isLoading = false;
        this.registered.emit();
      },
      (error) => {
        this.isLoading = false;
        if (error.error.statusCode === 429) {
          this.errorService.showErrorDialog(
            'Too many requests from this IP. Please try again later.'
          );
        } else if (error.error.statusCode === 500) {
          this.errorService.showErrorDialog(error.error.message);
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  get firstNameControl(): FormControl {
    return this.registerForm.get('firstName') as FormControl;
  }
  get lastNameControl(): FormControl {
    return this.registerForm.get('lastName') as FormControl;
  }

  public get firstName(): string {
    return this.firstNameControl.value;
  }

  public get lastName(): string {
    return this.lastNameControl.value;
  }

  public get email(): string {
    return this.registerForm.get('email')?.value;
  }

  public get password(): string {
    return this.registerForm.get('passwordGroup')?.get('password')?.value;
  }

  public get country(): Country {
    return this.registerForm.get('country')?.value;
  }

  public get termsAccepted(): boolean {
    return this.registerForm.get('termsAccepted')?.value;
  }

  public get consent(): boolean {
    return this.registerForm.get('consent')?.value;
  }
}
