import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountType } from 'src/app/dashboard/shared/account-type.enum';
import { Transfer } from '../models/transfer.model';

@Component({
  selector: 'app-transfer-confirmation',
  templateUrl: './transfer-confirmation.component.html',
  styleUrls: ['./transfer-confirmation.component.scss'],
})
export class TransferConfirmationComponent {
  @Input() fromAccount!: AccountDetails;
  @Input() toAccount?: AccountDetails;
  // e2e Now
  @Input() toName?: string;
  @Input() toPhoneNumber?: string;
  // SEPA/SWIFT
  @Input() isExternal?: boolean;
  @Input() isBetween?: boolean;
  @Input() sepaTooltipText?: string;
  @Input() currency: string = 'EUR';
  @Input() toCurrency?: any;
  @Input() beneficiaryName?: string;
  @Input() ibanOrAccountNumber?: string;
  @Input() isSepa?: boolean;
  @Input() bic?: string;
  @Input() bankName?: string;
  @Input() intermediaryBic?: string;
  @Input() intermediaryBankName?: string;
  @Input() executionDate?: string;
  @Input() charges?: string;
  @Input() country?: string;
  @Input() address1?: string;
  @Input() address2?: string;
  @Input() zipCode?: string;
  @Input() city?: string;

  @Input() amount!: number;
  @Input() fee?: any;
  @Input() payerDescription!: string;
  @Input() beneficiaryDescription?: string;

  @Input() hasOtp?: boolean = true;
  @Input() canSaveToFavourites: boolean = true;

  @Input() isTransferring: boolean = false;
  @Input() set isInvalidOtp(val: boolean) {
    if (val) {
      this.otpControl.setErrors({ invalidOtp: true });
    }
  }
  @Input() transactionId?: number;
  @Input() transferBody?: Transfer | null;

  @Output() cancel = new EventEmitter<boolean>(); // emits true when should reload accounts
  @Output() transfer = new EventEmitter<string | undefined>(); // emits otpCode

  otpControl = this.fb.control(null, Validators.required);

  constructor(private fb: FormBuilder) {}

  confirmTransfer(): void {
    this.transfer.emit(this.otpControl.value);
  }

  isCardAccount(account: AccountDetails): boolean {
    return account.type === AccountType.CARDS;
  }

  isWalletAccount(account: AccountDetails): boolean {
    return account.type === AccountType.WALLETS;
  }

  isIbanAccount(account: AccountDetails): boolean {
    return account.type === AccountType.IBAN;
  }
}
