import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateTime } from 'luxon';
import { merge, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ErrorService } from '../shared/error-dialog/error.service';
import { AccountDetails } from '../dashboard/models/account-details.model';
import { PaymentItem } from '../statement/models/payment-item.model';
import { PaymentsDataSource } from './payments.datasource';
import { PaymentsService } from './payments.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { StatementSearch } from '../statement/models/statement-search.model';
import { StatementService } from '../statement/statement.service';
import { TransactionTypeName } from '../shared/models/transaction-type-name.enum';

@Component({
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  accounts: AccountDetails[] = [];
  accountOwnerGuid!: string;
  isLoading: boolean = true;
  isLoadingDetails: boolean = false;

  transactionDetails?: PaymentItem;

  filtersGroup!: FormGroup;

  dataSource!: PaymentsDataSource;
  displayedColumns: string[] = [
    'createdAt',
    'category',
    'accountOrderId',
    'customerBen',
    'amountOrder',
    'amountBen',
    'status',
    'transactionReference',
  ];

  expandedElement?: PaymentItem;
  TransactionTypeName = TransactionTypeName;

  private formSub: Subscription | undefined;
  private sortSub: Subscription | undefined;
  private mergeSub: Subscription | undefined;

  constructor(
    private fb: FormBuilder,
    private paymentsService: PaymentsService,
    private errorService: ErrorService,
    private dashboardService: DashboardService,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.dataSource = new PaymentsDataSource(
      this.paymentsService,
      this.errorService
    );
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    const endDate = new Date();
    this.filtersGroup = this.fb.group({
      startDate: startDate.toISOString().substring(0, 10),
      endDate: endDate.toISOString().substring(0, 10),
    });
  }

  ngAfterViewInit(): void {
    this.getInitialData();
    // listens for filters values
    this.formSub = merge(
      this.startDateControl.valueChanges,
      this.endDateControl.valueChanges
    ).subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadPage();
    });

    // reset the paginator after sorting
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.mergeSub = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadPage()))
      .subscribe();
  }

  private getInitialData(): void {
    this.isLoading = true;
    this.dashboardService.getAccounts().subscribe(
      (accounts) => {
        this.accounts = [accounts.iban, ...accounts.cards, ...accounts.wallets];
        this.accountOwnerGuid = accounts.accountOwnerGuid;
        this.paginator.firstPage();
        this.loadPage();
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  loadPage(): void {
    if (!this.accountOwnerGuid) {
      this.dataSource.clear();
      return;
    }

    if (this.startDateControl.value && this.endDateControl.value) {
      const params: StatementSearch = {
        ...this.generatePaymentsReqParams(),
        page: this.paginator.pageIndex,
        limit: this.paginator.pageSize,
      };
      this.dataSource.clear();
      this.dataSource.loadPayments(params);
    }
  }

  getAccountName(row: any) {
    const acc = this.accounts.find((a) => a.id === row?.accountOrderId);
    return `${acc?.name}`;
  }

  getLastMonths(months: number) {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - months);
    const endDate = new Date();
    this.startDateControl.setValue(startDate.toISOString().substring(0, 10));
    this.endDateControl.setValue(endDate.toISOString().substring(0, 10));
  }

  onClickExpand(row: PaymentItem) {
    if (row === this.expandedElement) {
      this.expandedElement = undefined;
    } else {
      this.transactionDetails = undefined;
      this.isLoadingDetails = true;
      this.statementService
        .getPaymentDetails({
          transactionReference: row?.transactionReference,
          accountOwnerGuid: this.accountOwnerGuid,
        })
        .pipe(finalize(() => (this.isLoadingDetails = false)))
        .subscribe(
          (t) => {
            this.transactionDetails = t;
          },
          () => this.errorService.showErrorDialog()
        );
      this.expandedElement = row;
    }
  }

  private generatePaymentsReqParams(): StatementSearch {
    const params: StatementSearch = {
      dtStartDate:
        typeof this.startDateControl.value === 'string'
          ? this.startDateControl.value
          : DateTime.fromISO(this.startDateControl.value).toFormat(
              'yyyy-MM-dd'
            ),
      dtEndDate:
        typeof this.endDateControl.value === 'string'
          ? this.endDateControl.value
          : DateTime.fromISO(this.endDateControl.value).toFormat('yyyy-MM-dd'),
      page: 0,
      limit: this.dataSource.getCount(),
      orderField: this.sort.active,
      orderAscending: this.sort.direction === 'asc',
      accountOwnerGuid: this.accountOwnerGuid,
    };
    return params;
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
    this.sortSub?.unsubscribe();
    this.mergeSub?.unsubscribe();
  }

  get startDateControl(): FormControl {
    return this.filtersGroup.get('startDate') as FormControl;
  }
  get endDateControl(): FormControl {
    return this.filtersGroup.get('endDate') as FormControl;
  }
}
