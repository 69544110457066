<div fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div>
  <div fxLayout="column">
    <h1>KYB Hit Details | Id: {{ kybHitId }}</h1>
  </div>
  <mat-divider></mat-divider>
  <div class="spinner-container" *ngIf="isLoadingKybHit">
    <app-big-loader></app-big-loader>
  </div>
  <mat-dialog-content *ngIf="kybHit">
    <div class="table-container">
      <table>
        <tr>
          <th class="factors"></th>
          <th></th>
        </tr>

        <!-- SP APIs say if a single item is found it will return an object (with null parameters), if multiple items are found it will return an array of objects -->
        <tr *ngFor="let kv of kybHit | keyvalue">
          <td class="field">
            {{ convertToString(kv.key) | replaceUnderscore | titlecase }}
          </td>
          <td>
            <div *ngIf="isNonNullObject(kv.value)">
              <div *ngFor="let item of getObjectEntries(kv.value)">
                <span>
                  {{
                    convertToString(item[0]) | replaceUnderscore | titlecase
                  }}:
                  <!-- if object (e.g. company_financial_summary, company_identifiers) return json, else as it is -->
                  <span *ngIf="isObject(item[1])">
                    {{ formatJson(item[1]) }}
                  </span>
                  <span *ngIf="!isObject(item[1])">
                    {{ item[1] }}
                  </span>
                </span>
              </div>
            </div>

            <div *ngIf="isString(kv.value)">
              <div>
                <p>
                  {{
                    convertToString(kv.value) | replaceUnderscore | titlecase
                  }}
                </p>
              </div>
            </div>

            <table *ngIf="isArray(kv.value)">
              <thead>
                <tr>
                  <th
                    *ngFor="let property of getFirstObjectProperties(kv.value)"
                  >
                    {{
                      convertToString(property) | replaceUnderscore | titlecase
                    }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let entry of $any(kv.value)">
                  <td *ngFor="let property of getObjectProperties(entry)">
                    <span *ngIf="isObject(entry[property])">
                      <div
                        *ngFor="let obj of getObjectEntries(entry[property])"
                      >
                        <span>
                          {{
                            convertToString(obj[0])
                              | replaceUnderscore
                              | titlecase
                          }}: {{ obj[1] }}
                        </span>
                      </div>
                    </span>
                    <span *ngIf="!isObject(entry[property])">
                      {{ entry[property] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>
        <tr>
          <td class="field">Full Raw Response</td>
          <td>
            <div>
              <app-json-viewer [jsonObject]="kybHit"></app-json-viewer>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
</div>
