<div *ngIf="isLoading">
  <app-big-loader></app-big-loader>
</div>
<div *ngIf="!isLoading && data" fxLayout="column" fxLayoutGap="20px">
  <mat-card>
    <!-- Header -->
    <div fxLayoutAlign="space-between start">
      <h1 data-testid="user-name-text" *ngIf="data?.personalForm">
        {{ data?.personalForm?.firstName }} {{ data?.personalForm?.lastName }}
      </h1>
      <h1 data-testid="user-name-text" *ngIf="data?.corporateForm">
        {{ data?.corporateForm?.businessName }} <br />
      </h1>
      <button
        data-testid="back-to-list-button"
        mat-stroked-button
        color="accent"
        routerLink="../"
      >
        Back to the list
      </button>
    </div>
    <!-- Details under header -->
    <div class="details">
      <div data-testid="chosen-programm-text">
        {{ programs[data.program] | titlecase }} Account
      </div>
      <div>Registration started {{ data.dateCreated | date: 'short' }}</div>
      <div data-testid="submitted-email-text">Email: {{ data.email }}</div>
      <div data-testid="submitted-phone-number-text">
        Phone: {{ data.cellPhoneNumber }}
      </div>
    </div>
    <!-- UserState and Notes -->
    <div class="state-container" fxLayoutAlign="space-between center">
      <div>
        {{ convertUserState(data.state) | titlecase
        }}<span *ngIf="data.state === UserState.EDD_SUBMITTED"
          >:
          {{
            data?.personalForm?.dtEddFormSubmitted ||
              data?.corporateForm?.dtEddFormSubmitted | date: 'dd/MM/yyyy HH:mm'
          }}</span
        >
        <span *ngIf="data.state === UserState.DECISION_POINT"
          >:
          {{
            data?.personalForm?.dtDecisionPointStarted ||
              data?.corporateForm?.dtDecisionPointStarted
              | date: 'dd/MM/yyyy HH:mm'
          }}</span
        >
        <span *ngIf="data.state === UserState.KEY_PERSONS_DECISION_POINT"
          >:
          {{
            data?.corporateForm?.dtKeyPersonDecisionPointStarted
              | date: 'dd/MM/yyyy HH:mm'
          }}</span
        >
        <span *ngIf="data.state === UserState.BUSINESS_CDD_REVIEW"
          >:
          {{
            data?.corporateForm?.dtBusinessDecisionPointStarted
              | date: 'dd/MM/yyyy HH:mm'
          }}</span
        >
        <!-- only if all legal/natural persons completed verification -->
        <span
          *ngIf="
            data.state === UserState.KEY_PERSONS_VERIFICATION &&
            allowRetriggerStakeholderScoring()
          "
        >
          <button
            mat-stroked-button
            color="accent"
            class="retrigger"
            [disabled]="isRetriggering"
            [appButtonLoader]="isRetriggering"
            (click)="retriggerStakeholderScoring()"
          >
            Retrigger stakeholder scoring
          </button>
        </span>
      </div>
      <div fxLayout="row" fxLayoutGap="40px">
        <div>
          <span class="notes">Download all documents</span>
          <button
            data-testid="download-all-documents-button"
            mat-icon-button
            (click)="downloadAllDocuments()"
          >
            <mat-icon fontSet="material-icons-outlined" color="accent"
              >download</mat-icon
            >
          </button>
        </div>
        <div>
          <span class="notes">Notes ({{ data.noteCount }})</span>
          <button
            data-testid="add-notes-button"
            mat-icon-button
            (click)="addNote()"
          >
            <mat-icon svgIcon="add"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="state !== ViewState.INITIAL">
      <mat-divider></mat-divider>
      <div class="documents-container">
        <h2>Uploaded documents</h2>
        <div fxLayout="row wrap" fxLayoutGap="30px grid">
          <app-admin-document
            *ngFor="let control of documentsControls"
            [formControl]="control"
            [fileDialogHeader]="headerText"
            [userId]="userId"
            [showProofOfOperatingAddress]="data.corporateForm !== undefined"
          ></app-admin-document>
        </div>
      </div>

      <div class="form-container">
        <h2>Form</h2>
        <!-- Disable the option of admin to Comment on form fields when in EDD_SUBMITTED (KYC) because it will go back to the user to request new data and risk score can change.
          showExtraQuestions: extra questions should be shown in admin for every state and individual program except when program is JADE and in DECISION_POINT - CDD) -->
        <app-admin-form-review-new
          [isSameAddress]="isSameAddress"
          [corporateGroup]="corporateGroup"
          [personalGroup]="personalGroup"
          [accountGroup]="accountGroup"
          [counterpartyGroup]="counterpartyGroup"
          [userId]="userId"
          [isEDD]="data.state === UserState.EDD_SUBMITTED"
          [showExtraQuestions]="
            !(
              userProgram === Program.JADE &&
              data.state === UserState.DECISION_POINT
            )
          "
        ></app-admin-form-review-new>
      </div>

      <div *ngIf="directors.length" class="directors-container">
        <h2>Company’s Stakeholders and Authorized Contact Person</h2>
        <div [ngSwitch]="directors.length" class="directors-count">
          <ng-container *ngSwitchCase="1"
            >{{ directors.length }} Company’s Stakeholder and Authorized Contact
            Person</ng-container
          >
          <ng-container *ngSwitchDefault
            >{{ directors.length }} Company’s Stakeholders and Authorized
            Contact Person</ng-container
          >
        </div>
        <mat-divider></mat-divider>
        <!-- allow to resend id verification link if key person is active, didn't do the verification, not legal person and not only authorized contact person -->
        <app-admin-director-shareholder-new
          *ngFor="let director of directors; let i = index"
          [index]="i"
          [director]="director"
          [userId]="userId"
          [control]="$any(directorsArray.at(i))"
          [countries]="countries"
          [directorGroup]="directorGroup"
          [directorCloseLinkGroup]="directorCloseLinkGroup"
          [allowResendIdVerificationUrl]="
            data.state === UserState.KEY_PERSONS_VERIFICATION &&
            director.isActive &&
            !director.onboardingVerificationDone &&
            !director.isLegalPerson &&
            !(
              director.isAuthorizedPerson &&
              !director.isDirector &&
              !director.isShareholder &&
              !director.isUBO
            )
          "
        ></app-admin-director-shareholder-new>
      </div>

      <div class="form-container" *ngIf="corporateGroup">
        <app-admin-form-review-new
          [closeLinkGroup]="closeLinkGroup"
          [groupMemberGroup]="groupMemberGroup"
          [userId]="userId"
        ></app-admin-form-review-new>
      </div>
    </ng-container>
  </mat-card>

  <app-risk-assessment
    *ngIf="!(state === ViewState.INITIAL)"
    [userId]="userId"
    [personalData]="data.personalForm"
    [corporateData]="data.corporateForm"
    [companyDirectors]="data.companyDirectors"
    [companyGroupMembers]="data.companyGroupMembers"
    [companyCloseLinks]="data.companyCloseLinks"
    [state]="data.state"
    [isInOnboarding]="true"
    [viewOnlyState]="state === ViewState.VIEW_ONLY"
    [eddSuggestionMade]="data.eddSuggestionMade"
    (reloadUser)="loadData()"
  ></app-risk-assessment>

  <app-action-logs
    *ngIf="!(state === ViewState.INITIAL)"
    [userId]="userId"
    [actionLogs]="data.actionLogs"
    (reloadActionLogs)="loadActionLogs()"
  ></app-action-logs>

  <mat-card *ngIf="!(state === ViewState.INITIAL)" class="summary">
    <h2 class="header">Date of Birth Check</h2>
    <mat-divider></mat-divider>
    <div class="dob-match">
      <div *ngIf="!isEmptyDobMatches()">
        <div *ngFor="let recipient of data?.dobMatches | keyvalue">
          <b> {{ recipient.key | ordinalNumber }} Run: </b><br />
          <div *ngFor="let usr of recipient.value | keyvalue">
            <u>
              <br />{{ usr.key }} -
              {{ usr.value.dateOfRun | date: 'dd/MM/yyyy HH:mm' }}: </u
            ><br />
            <div
              *ngIf="
                usr.value.emailsOfAccountHolders &&
                usr.value.emailsOfAccountHolders.length > 0
              "
            >
              Account holders: <br /> 
              {{ usr.value.emailsOfAccountHolders.join(', ') }}
            </div>
            <div
              *ngIf="
                usr.value.emailsOfKeyPersons &&
                usr.value.emailsOfKeyPersons.length > 0
              "
            >
              Key persons: <br />
              {{ usr.value.emailsOfKeyPersons.join(', ') }}
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div *ngIf="isEmptyDobMatches()">None</div>
    </div>
  </mat-card>

  <!-- General comment should be shown always because if it goes back to client it needs to show the comment -->
  <mat-card *ngIf="!(state === ViewState.INITIAL)" class="summary">
    <h2 class="header">Summary</h2>
    <div fxLayout="row" fxLayoutGap="60px">
      <div fxLayout="column" fxLayoutGap="20px">
        <div>
          <h2>Uploaded documents</h2>
          <div *ngIf="getAcceptedCount(documentsControls)">
            {{ getAcceptedCount(documentsControls) }} accepted
          </div>
          <div *ngIf="getRejectedCount(documentsControls)">
            {{ getRejectedCount(documentsControls) }} rejected
          </div>
          <div *ngIf="getIndeterminateCount(documentsControls)">
            {{ getIndeterminateCount(documentsControls) }} indeterminate
          </div>
          <div *ngIf="getMissingCommentCount(documentsControls)">
            {{ getMissingCommentCount(documentsControls) }} missing comment(s)
          </div>
        </div>
        <div *ngIf="directors.length">
          <h2>Stakeholders' documents</h2>
          <div *ngIf="getAcceptedCount(directorsDocControls)">
            {{ getAcceptedCount(directorsDocControls) }} accepted
          </div>
          <div *ngIf="getRejectedCount(directorsDocControls)">
            {{ getRejectedCount(directorsDocControls) }} rejected
          </div>
          <div *ngIf="getIndeterminateCount(directorsDocControls)">
            {{ getIndeterminateCount(directorsDocControls) }} indeterminate
          </div>
          <div *ngIf="getMissingCommentCount(directorsDocControls)">
            {{ getMissingCommentCount(directorsDocControls) }} missing
            comment(s)
          </div>
        </div>
        <div>
          <h2>Form</h2>
          <div [ngSwitch]="getMissingCommentCount(formControls)">
            <ng-container *ngSwitchCase="0">No issues</ng-container>
            <ng-container *ngSwitchDefault>
              {{ getMissingCommentCount(formControls) }} missing comment(s)
            </ng-container>
          </div>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="general-comment" fxLayout="column" fxLayoutGap="15px">
        <h2>General comment (optional)</h2>
        <div>
          Anything you want to add that is not related to a specific data in the
          applicant's application.
        </div>
        <div fxLayout="column">
          <label
            >General comment (will appear on top of the applicant's page)</label
          >
          <mat-form-field>
            <textarea
              matInput
              data-testid="general-comment-input"
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="3"
              [cdkAutosizeMaxRows]="3"
              [ngModel]="generalComment"
              (ngModelChange)="saveGeneralComment($event)"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [disabled]="
                generalCommentControl.pending || generalCommentControl.disabled
              "
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div
      *ngIf="!(state === ViewState.VIEW_ONLY)"
      fxLayoutAlign="end center"
      fxLayoutGap="40px"
    >
      <button
        *ngIf="!isApplicationValid && !isApplicationPending"
        mat-stroked-button
        data-testid="accept-all-button"
        color="accent"
        (click)="acceptIndeterminates()"
      >
        {{
          isNothingRejected
            ? 'Accept all documents'
            : 'Accept all unrejected documents'
        }}
      </button>
      <!-- disable button if application was sent for approval -->
      <button
        mat-flat-button
        color="accent"
        [disabled]="
          !isApplicationValid ||
          isReviewing ||
          (data.state === UserState.EDD_SUBMITTED && data.eddSuggestionMade) ||
          (data.state === UserState.BUSINESS_CDD_REVIEW &&
            data.cddBusinessSuggestionMade)
        "
        data-testid="update-the-client-button"
        [appButtonLoader]="isReviewing"
        (click)="returnApplication()"
      >
        Return the application
      </button>

      <button
        *ngIf="data.state === UserState.BUSINESS_CDD_REVIEW"
        mat-flat-button
        color="accent"
        [disabled]="!isApplicationValid"
        (click)="changeCddBusinessSuggestionStatus()"
      >
        {{
          data.cddBusinessSuggestionMade
            ? 'Return for adjustments'
            : 'Send for approval'
        }}
      </button>

      <button
        *ngIf="data.state === UserState.EDD_SUBMITTED"
        mat-flat-button
        data-testid="edd-suggest-button"
        color="accent"
        [disabled]="!isApplicationValid"
        (click)="changeEddSuggestionStatus()"
      >
        {{
          data.eddSuggestionMade
            ? 'Return for adjustments'
            : 'Send for approval'
        }}
      </button>
      <!-- Alternative condition for verify/reject applicant buttons 
        (data.state === UserState.EDD_SUBMITTED && !data.eddSuggestionMade)
        || (data.state === UserState.BUSINESS_CDD_REVIEW && !data.cddBusinessSuggestionMade) -->
      <button
        mat-flat-button
        data-testid="verify-the-client-button"
        color="accent"
        [disabled]="
          !isApplicationValid ||
          (!data.eddSuggestionMade && !data.cddBusinessSuggestionMade)
        "
        (click)="verifyClient()"
      >
        Verify the applicant
      </button>
      <!-- reject or verify the applicant only if application is valid (documents being either accepted or rejected) -->
      <button
        mat-flat-button
        data-testid="reject-the-client-button"
        color="accent"
        [disabled]="
          !isApplicationValid ||
          (!data.eddSuggestionMade && !data.cddBusinessSuggestionMade)
        "
        (click)="makeClientDecision(false)"
      >
        Reject the applicant
      </button>
    </div>

    <div
      *ngIf="data.state === UserState.DECISION_POINT"
      fxLayoutAlign="end center"
      fxLayoutGap="40px"
    >
      <button
        mat-flat-button
        data-testid="cdd-suggest-button"
        color="accent"
        (click)="changeCddSuggestionStatus()"
      >
        {{
          data.cddSuggestionMade
            ? 'Return for adjustments'
            : 'Send for approval'
        }}
      </button>

      <button
        mat-flat-button
        data-testid="proceed-client-button"
        color="accent"
        [disabled]="!data.cddSuggestionMade"
        (click)="makeClientDecision(true)"
      >
        Proceed
      </button>

      <button
        mat-flat-button
        data-testid="reject-the-client-button"
        color="accent"
        [disabled]="!data.cddSuggestionMade"
        (click)="makeClientDecision(false)"
      >
        Reject
      </button>
    </div>

    <div
      *ngIf="data.state === UserState.KEY_PERSONS_DECISION_POINT"
      fxLayoutAlign="end center"
      fxLayoutGap="40px"
    >
      <button
        mat-flat-button
        data-testid="cdd-suggest-button"
        color="accent"
        (click)="changeCddKeyPersonSuggestionStatus()"
      >
        {{
          data.cddKeyPersonSuggestionMade
            ? 'Return for adjustments'
            : 'Send for approval'
        }}
      </button>

      <button
        mat-flat-button
        data-testid="proceed-client-button"
        color="accent"
        [disabled]="!data.cddKeyPersonSuggestionMade"
        (click)="makeClientDecision(true)"
      >
        Proceed
      </button>

      <button
        mat-flat-button
        data-testid="reject-the-client-button"
        color="accent"
        [disabled]="!data.cddKeyPersonSuggestionMade"
        (click)="makeClientDecision(false)"
      >
        Reject
      </button>
    </div>
  </mat-card>
</div>
