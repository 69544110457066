import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Country } from 'src/app/shared/models/country.model';
import { RecurringCycle } from '../models/recurring-cycle.enum';
import { descriptionMaxLength } from 'src/app/shared/helpers/various-helpers.helper';
import { Charges } from '../enum/charges.enum';

@Component({
  selector: 'app-transfer-details-form',
  templateUrl: './transfer-details-form.component.html',
  styleUrls: ['./transfer-details-form.component.scss'],
})
export class TransferDetailsFormComponent implements OnInit {
  // E2ENOW
  @Input() beneficiaryName?: string;
  @Input() isWrongBeneficiary?: boolean;
  @Input() isSameBeneficiary?: boolean;
  // in-between
  @Input() isBetween?: boolean;
  // External transfer
  @Input() isExternal?: boolean;
  @Input() currency: string = 'EUR';
  @Input() countries: Country[] = [];
  @Input() sepaTooltipText?: string;
  @Input() lastUpdated?: Date;
  @Input() ibanDetails?: any;
  @Input() bicDetails?: any;
  @Input() intermediaryBicDetails?: any;
  @Input() currencyCodeList?: any;
  @Input() hideTransferSection: boolean = false;

  @Output() shouldTransfer = new EventEmitter<void>();

  otherFieldsGroup?: FormGroup;
  transactionForm?: FormGroup;

  RecurringCycle = RecurringCycle;
  descriptionMaxLength = descriptionMaxLength;
  Charges = Charges;
  today: Date = new Date();

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.otherFieldsGroup = this.controlContainer.control as FormGroup;
    this.transactionForm = this.otherFieldsGroup.parent as FormGroup;
  }

  imagePath(name: string): string {
    return name ? `assets/img/country-flags/${name}.svg` : '';
  }

  get amountControl(): FormControl {
    return this.otherFieldsGroup?.get('amount') as FormControl;
  }
  get toCurrencyControl(): FormControl {
    return this.otherFieldsGroup?.get('toCurrency') as FormControl;
  }
  get toAmountControl(): FormControl {
    return this.otherFieldsGroup?.get('toAmount') as FormControl;
  }
  get executionDateControl(): FormControl {
    return this.otherFieldsGroup?.get('executionDate') as FormControl;
  }
  get payerDescriptionControl(): FormControl {
    return this.otherFieldsGroup?.get('payerDescription') as FormControl;
  }
  get beneficiaryDescriptionControl(): FormControl {
    return this.otherFieldsGroup?.get('beneficiaryDescription') as FormControl;
  }

  // SEPA/SWIFT
  get accountsGroup(): FormGroup {
    return this.transactionForm?.get('accounts') as FormGroup;
  }
  get isSepaControl(): FormControl {
    return this.accountsGroup?.get('isSepa') as FormControl;
  }
  get isRecurringControl(): FormControl {
    return this.otherFieldsGroup?.get('isRecurring') as FormControl;
  }
  get recurringCycleControl(): FormControl {
    return this.otherFieldsGroup?.get('recurringCycle') as FormControl;
  }
  get bicControl(): FormControl {
    return this.otherFieldsGroup?.get('bic') as FormControl;
  }
  get intermediaryBicControl(): FormControl {
    return this.otherFieldsGroup?.get('intermediaryBic') as FormControl;
  }
  get beneficiaryNameControl(): FormControl {
    return this.otherFieldsGroup?.get('beneficiaryName') as FormControl;
  }
  get creditorAddress1Control(): FormControl {
    return this.otherFieldsGroup?.get('creditorAddress1') as FormControl;
  }
  get creditorAddress2Control(): FormControl {
    return this.otherFieldsGroup?.get('creditorAddress2') as FormControl;
  }
  get creditorZipControl(): FormControl {
    return this.otherFieldsGroup?.get('creditorZip') as FormControl;
  }
  get creditorCityControl(): FormControl {
    return this.otherFieldsGroup?.get('creditorCity') as FormControl;
  }
  get feeControl(): FormControl {
    return this.otherFieldsGroup?.get('fee') as FormControl;
  }
}
