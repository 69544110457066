<div fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close [disabled]="isSavingRiskForm">
    <mat-icon>close</mat-icon>
  </button>
</div>

<ng-template #checkTrue>
  <button disableRipple mat-icon-button class="check">
    <mat-icon>check</mat-icon>
  </button>
</ng-template>

<ng-template #checkFalse>
  <button disableRipple mat-icon-button class="check">
    <mat-icon class="cancel-icon">close</mat-icon>
  </button>
</ng-template>

<mat-dialog-content>
  <h2 class="header">
    Detailed risk assessment │
    {{ isCorporate ? entityReport.id : personalReport.id }}
    {{ isProposalReport ? '(Proposal)' : '' }}
  </h2>
  <mat-divider></mat-divider>

  <div class="table-container" *ngIf="!isCorporate">
    <table>
      <tr>
        <th class="factors">Factors</th>
        <th class="details">Details</th>
        <th class="other">From API</th>
        <th class="other">From User</th>
        <th class="other">Score</th>
        <th class="other">Average</th>
      </tr>

      <div class="space"></div>
      <tr>
        <td class="category-title" colspan="6">Customer Risk Factors</td>
      </tr>
      <div class="space"></div>

      <tr>
        <td>Politically exposed person</td>
        <td>{{ getIndividualPEP() }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle" [class.found]="isHigh(personalReport.pepScore)">
          {{ getScoreValue(personalReport.pepScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Adverse media</td>
        <td>{{ getIndividualAdverse() }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.adverseMediaScore)"
        >
          {{ getScoreValue(personalReport.adverseMediaScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Criminal record</td>
        <td>
          {{
            personalReport.foundInWarningList
              ? name + ' was found in Warning lists.'
              : '-'
          }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.criminalRecordScore)"
        >
          {{ getScoreValue(personalReport.criminalRecordScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Occupation status</td>
        <td>{{ personalReport.dataOccupationProfession || '-' }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.dataOccupationProfessionScore)"
        >
          {{ getScoreValue(personalReport.dataOccupationProfessionScore) }}
        </td>
        <td
          class="middle"
          [class.found]="isHigh(getCategory1IndividualAverage())"
        >
          {{ getCategory1IndividualAverage() }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Industry involved</td>
        <td>{{ personalReport.dataOccupation || '-' }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.dataOccupationScore)"
        >
          {{ getScoreValue(personalReport.dataOccupationScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Monthly income</td>
        <td>{{ personalReport.dataMonthlyIncome || '-' }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.dataMonthlyIncomeScore)"
        >
          {{ getScoreValue(personalReport.dataMonthlyIncomeScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Monthly spend</td>
        <td>{{ personalReport.dataMonthlySpend || '-' }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.dataMonthlySpendScore)"
        >
          {{ getScoreValue(personalReport.dataMonthlySpendScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Tax Identification Number and VAT Number</td>
        <td>
          {{
            personalReport.taxAndVatScore
              ? isHigh(personalReport.taxAndVatScore)
                ? 'Tax Identification Number and VAT Number are empty'
                : 'Tax Identification Number and/or VAT Number are not empty'
              : '-'
          }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.taxAndVatScore)"
        >
          {{ getScoreValue(personalReport.taxAndVatScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Temporary Residence Permit in EEA</td>
        <td>
          {{
            personalReport.isTempAddressScore
              ? isHigh(personalReport.isTempAddressScore)
                ? 'The provided address is temporary'
                : 'The provided address is not temporary'
              : '-'
          }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.isTempAddressScore)"
        >
          {{ getScoreValue(personalReport.isTempAddressScore) }}
        </td>
      </tr>

      <div class="space"></div>
      <tr>
        <td class="category-title" colspan="6">
          Service/Transaction Risk Factors
        </td>
      </tr>
      <div class="space"></div>

      <tr>
        <td>Account Purpose</td>
        <td>{{ personalReport.accountPurpose || '-' }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.accountPurposeScore)"
        >
          {{ getScoreValue(personalReport.accountPurposeScore) }}
        </td>
        <td
          class="middle"
          [class.found]="isHigh(personalReport.accountPurposeScore)"
        >
          {{ getScoreValue(personalReport.accountPurposeScore) }}
        </td>
      </tr>

      <div class="space"></div>
      <tr>
        <td class="category-title" colspan="6">
          Country (Geographical) Risk Factors
        </td>
      </tr>
      <div class="space"></div>

      <tr>
        <td>Residency</td>
        <td>
          {{
            personalReport.country
              ? getCountryById(countries, personalReport.country)
              : '-'
          }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle" [class.found]="isHigh(personalReport.countryScore)">
          {{ getScoreValue(personalReport.countryScore) }}
        </td>
        <td class="middle" [class.found]="isHigh(personalReport.countryScore)">
          {{ getScoreValue(personalReport.countryScore) }}
        </td>
      </tr>

      <div class="space"></div>
      <tr>
        <td class="category-title" colspan="6">Total Risk Scoring</td>
      </tr>
      <div class="space"></div>

      <tr>
        <td>Risk level</td>
        <td>
          {{ personalReport.riskLevel || '-' }}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td class="middle" [class.found]="atLeastOneHigh()">
          {{ getScoreValue(personalReport.totalRiskScore) }}
        </td>
      </tr>
    </table>
  </div>

  <div class="table-container" *ngIf="isCorporate">
    <table>
      <tr>
        <th class="factors">Factors</th>
        <th class="details">Details</th>
        <th class="other">From API</th>
        <th class="other">From User</th>
        <th class="other">Score</th>
        <th class="other">Average</th>
      </tr>

      <div class="space"></div>
      <tr>
        <td class="category-title" colspan="6">Customer Risk Factors</td>
      </tr>
      <div class="space"></div>

      <tr>
        <td>Politically exposed stakeholders</td>
        <td class="linebreak">{{ getDirectorPEP() }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.directorsPEPScore)"
        >
          {{ getScoreValue(entityReport.directorsPEPScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>
          Adverse Media / Criminal Record check of the entity and stakeholders
        </td>
        <td class="linebreak">
          <u>Entity:</u><br />
          {{ getEntityAdverseCriminal() }}
          <br /><u>Stakeholders:</u><br />
          {{ getDirectorAdverseCriminal() }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.adverseMediaCriminalRecordScore)"
        >
          {{ getScoreValue(entityReport.adverseMediaCriminalRecordScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>
          Sanction / Adverse Media / Criminal Record check of the group of
          companies
        </td>
        <td class="linebreak">{{ getGroupMemberAML() }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.groupMemberAmlScore)"
        >
          {{ getScoreValue(entityReport.groupMemberAmlScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Financial statement</td>
        <td>
          Zero balance:
          {{ getStringValue(entityReport.zeroBalanceStatement) }}
          <br />
          Annual income:
          {{ getAnnualIncome() }}
          <br />
          Annual income expected to at least double:
          {{ getStringValue(entityReport.doubleAnnualIncome) }}
          <br />
          Audited financial statement:
          {{ getStringValue(entityReport.isFinancialStatementAudited) }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.financialStatementScore)"
        >
          {{ getScoreValue(entityReport.financialStatementScore) }}
        </td>
        <td class="middle" [class.found]="isHigh(getCategory1Average())">
          {{ getCategory1Average() }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Business activity</td>
        <td>{{ entityReport.companyActivity || '-' }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.companyActivityScore)"
        >
          {{ getScoreValue(entityReport.companyActivityScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Percentage of cash transactions on total activities</td>
        <td>{{ entityReport.percentageOfCash || '-' }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.percentageOfCashScore)"
        >
          {{ getScoreValue(entityReport.percentageOfCashScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>
          The entity is able to initially fund their eCREDO account from an
          existing account at a bank registered and licensed in an EEA country
        </td>
        <td>{{ getStringValue(entityReport.canFundFromOwnAccount) }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.canFundFromOwnAccountScore)"
        >
          {{ getScoreValue(entityReport.canFundFromOwnAccountScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Tax Identification Number and VAT Number</td>
        <td>
          {{
            entityReport.taxAndVatScore
              ? isHigh(entityReport.taxAndVatScore)
                ? 'Tax Identification Number and VAT Number are empty'
                : 'Tax Identification Number and/or VAT Number are not empty'
              : '-'
          }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td class="middle" [class.found]="isHigh(entityReport.taxAndVatScore)">
          {{ getScoreValue(entityReport.taxAndVatScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Temporary Residence Permit in EEA of stakeholders</td>
        <td>
          {{
            entityReport.isTempAddressScore
              ? isHigh(entityReport.isTempAddressScore)
                ? 'At least one stakeholder has provided a temporary address'
                : 'None of the stakeholders has provided a temporary address'
              : '-'
          }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.isTempAddressScore)"
        >
          {{ getScoreValue(entityReport.isTempAddressScore) }}
        </td>
      </tr>

      <div class="space"></div>
      <tr>
        <td class="category-title" colspan="6">
          Service/Transaction Risk Factors
        </td>
      </tr>
      <div class="space"></div>

      <tr>
        <td>Account Purpose</td>
        <td>{{ entityReport.accountPurpose || '-' }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.accountPurposeScore)"
        >
          {{ getScoreValue(entityReport.accountPurposeScore) }}
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.accountPurposeScore)"
        >
          {{ getScoreValue(entityReport.accountPurposeScore) }}
        </td>
      </tr>

      <div class="space"></div>
      <tr>
        <td class="category-title" colspan="6">
          Country (Geographical) Risk Factors
        </td>
      </tr>
      <div class="space"></div>

      <tr>
        <td>Country Jurisdictions</td>
        <td>
          <u>Entity:</u><br />
          {{ getOperateInHighRiskCountries() }}
          <br /><u>Group Members:</u><br />
          {{ getGroupMemberCountries() }}
          <br /><u>Counterparties:</u><br />
          {{ getCounterpartyCountries() }}
          <br /><u>Bank/PI Accounts:</u><br />
          {{ getBankAccountCountries() }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.jurisdictionsScore)"
        >
          {{ getScoreValue(entityReport.jurisdictionsScore) }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>
          Country risk of the entity's country of incorporation and operating
          country
        </td>
        <td>
          Country of Incorporation:
          {{
            getCountryById(countries, entityReport.incorporationCountry) || '-'
          }}
          <br />Operating country:
          {{ getCountryById(countries, entityReport.operatingCountry) || '-' }}
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.incOpeCountryScore)"
        >
          {{ getScoreValue(entityReport.incOpeCountryScore) }}
        </td>
        <td class="middle" [class.found]="isHigh(getCategory3Average())">
          {{ getCategory3Average() }}
        </td>
      </tr>

      <tr>
        <td colspan="5"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td>Residency of stakeholders</td>
        <td>{{ getDirectorResidency() }}</td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkFalse"></ng-container>
        </td>
        <td class="middle">
          <ng-container *ngTemplateOutlet="checkTrue"></ng-container>
        </td>
        <td
          class="middle"
          [class.found]="isHigh(entityReport.directorCountryScore)"
        >
          {{ getScoreValue(entityReport.directorCountryScore) }}
        </td>
      </tr>

      <div class="space"></div>
      <tr>
        <td class="category-title" colspan="6">Total Risk Scoring</td>
      </tr>
      <div class="space"></div>

      <tr>
        <td>Risk Level</td>
        <td>
          {{ entityReport.riskLevel || '-' }}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td class="middle" [class.found]="atLeastOneHigh()">
          {{ getScoreValue(entityReport.totalRiskScore) }}
        </td>
      </tr>
    </table>
  </div>

  <mat-divider></mat-divider>

  <div class="aml-table">
    <h2 class="header">AML screening</h2>
    <mat-divider></mat-divider>
  </div>

  <mat-table [dataSource]="dataSourcePersonal" *ngIf="!isCorporate">
    <ng-container matColumnDef="riskReportId">
      <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.id }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="itemName">
      <mat-header-cell *matHeaderCellDef><b>Individual</b></mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ personalReport.amlPayload?.background_checks?.name?.full_name }}
        <br />
        DOB:
        {{ convertDate(personalReport.amlPayload?.background_checks?.dob) }}
        <br />
        {{ personalReport.amlPayload?.reference }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="foundInSanctionList">
      <mat-header-cell *matHeaderCellDef>Sanction List</mat-header-cell>
      <mat-cell *matCellDef="let row" [class.found]="row.foundInSanctionList">{{
        getFoundValue(row.foundInSanctionList)
      }}</mat-cell>
    </ng-container>

    <div [formGroup]="form">
      <div formArrayName="personalReportsForm">
        <ng-container matColumnDef="foundInWarningList">
          <mat-header-cell *matHeaderCellDef>Warning List</mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div class="actions-column">
              <mat-form-field
                class="mat-select-field"
                *ngIf="allowAmlListAndAdjustmentReasonChange()"
              >
                <mat-select
                  [ngClass]="{
                    redtext: amlListMainReportControl('foundInWarningList')
                  }"
                  name="foundInWarningList"
                  formControlName="foundInWarningList"
                >
                  <mat-option
                    *ngFor="let entry of amlResultOptions"
                    [value]="entry.id"
                    >{{ entry.value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInWarningList"
            >
              {{ getFoundValue(row.foundInWarningList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInFitnessProbityList">
          <mat-header-cell *matHeaderCellDef
            >Fitness Probity List</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListMainReportControl('foundInFitnessProbityList')
                }"
                name="foundInFitnessProbityList"
                formControlName="foundInFitnessProbityList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInFitnessProbityList"
            >
              {{ getFoundValue(row.foundInFitnessProbityList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInPEPList">
          <mat-header-cell *matHeaderCellDef>PEP List</mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListMainReportControl('foundInPEPList')
                }"
                name="foundInPEPList"
                formControlName="foundInPEPList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInPEPList"
            >
              {{ getFoundValue(row.foundInPEPList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInAdverseMediaList">
          <mat-header-cell *matHeaderCellDef
            >Adverse Media List</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListMainReportControl('foundInAdverseMediaList')
                }"
                name="foundInAdverseMediaList"
                formControlName="foundInAdverseMediaList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInAdverseMediaList"
            >
              {{ getFoundValue(row.foundInAdverseMediaList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="adjustedRiskLevel">
          <mat-header-cell *matHeaderCellDef
            >Adjusted Risk Level</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-adjustment-field"
              *ngIf="allowUpdateAdjustedRiskLevel()"
            >
              <mat-select
                name="adjustedRiskLevel"
                formControlName="adjustedRiskLevel"
              >
                <mat-option
                  *ngFor="let entry of adjustmentOptions"
                  [value]="entry"
                  >{{ entry }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div *ngIf="!allowUpdateAdjustedRiskLevel()">
              {{ getScoreValue(row.adjustedRiskLevel) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dueDiligenceLevel">
          <mat-header-cell *matHeaderCellDef
            >Due Diligence Level</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            {{ personalReport.dueDiligenceLevel || '-' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amlHits">
          <mat-header-cell *matHeaderCellDef>AML Results</mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="20px"
            >
              <div>
                <button
                  class="aml"
                  mat-button
                  (click)="showAmlHits(row.id, RiskReportType.MAIN)"
                >
                  See more
                </button>
              </div>
              <div>
                <a
                  *ngIf="personalReport.amlPayload?.reference"
                  [href]="getAmlLink(personalReport.amlPayload?.reference)"
                  target="_blank"
                >
                  <mat-icon class="link" [matTooltip]="matTip"
                    >open_in_new</mat-icon
                  >
                </a>
              </div>
            </div>
          </mat-cell>
        </ng-container>
      </div>
    </div>
    <mat-header-row *matHeaderRowDef="displayedColumnsAML"></mat-header-row>
    <mat-row
      *matRowDef="let col; columns: displayedColumnsAML; let entry"
    ></mat-row>
  </mat-table>

  <mat-table [dataSource]="dataSourceEntity" *ngIf="isCorporate">
    <ng-container matColumnDef="riskReportId">
      <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.id }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="itemName">
      <mat-header-cell *matHeaderCellDef><b>Entity</b></mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.amlPayload?.aml_for_businesses?.business_name }} <br />
        DOI:
        {{
          convertDate(
            row.amlPayload?.aml_for_businesses?.business_incorporation_date
          )
        }}
        <br />
        {{ row.amlPayload?.reference }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="foundInSanctionList">
      <mat-header-cell *matHeaderCellDef>Sanction List</mat-header-cell>
      <mat-cell *matCellDef="let row" [class.found]="row.foundInSanctionList">{{
        getFoundValue(row.foundInSanctionList)
      }}</mat-cell>
    </ng-container>

    <div [formGroup]="form">
      <div formArrayName="entityReportsForm">
        <ng-container matColumnDef="foundInWarningList">
          <mat-header-cell *matHeaderCellDef>Warning List</mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div class="actions-column">
              <mat-form-field
                class="mat-select-field"
                *ngIf="allowAmlListAndAdjustmentReasonChange()"
              >
                <mat-select
                  [ngClass]="{
                    redtext: amlListMainReportControl('foundInWarningList')
                  }"
                  name="foundInWarningList"
                  formControlName="foundInWarningList"
                >
                  <mat-option
                    *ngFor="let entry of amlResultOptions"
                    [value]="entry.id"
                    >{{ entry.value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInWarningList"
            >
              {{ getFoundValue(row.foundInWarningList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInFitnessProbityList">
          <mat-header-cell *matHeaderCellDef
            >Fitness Probity List</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListMainReportControl('foundInFitnessProbityList')
                }"
                name="foundInFitnessProbityList"
                formControlName="foundInFitnessProbityList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInFitnessProbityList"
            >
              {{ getFoundValue(row.foundInFitnessProbityList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInPEPList">
          <mat-header-cell *matHeaderCellDef>PEP List</mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListMainReportControl('foundInPEPList')
                }"
                name="foundInPEPList"
                formControlName="foundInPEPList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInPEPList"
            >
              {{ getFoundValue(row.foundInPEPList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInAdverseMediaList">
          <mat-header-cell *matHeaderCellDef
            >Adverse Media List</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListMainReportControl('foundInAdverseMediaList')
                }"
                name="foundInAdverseMediaList"
                formControlName="foundInAdverseMediaList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInAdverseMediaList"
            >
              {{ getFoundValue(row.foundInAdverseMediaList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="adjustedRiskLevel">
          <mat-header-cell *matHeaderCellDef
            >Adjusted Risk Level</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-adjustment-field"
              *ngIf="allowUpdateAdjustedRiskLevel()"
            >
              <mat-select
                name="adjustedRiskLevel"
                formControlName="adjustedRiskLevel"
              >
                <mat-option
                  *ngFor="let entry of adjustmentOptions"
                  [value]="entry"
                  >{{ entry }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div *ngIf="!allowUpdateAdjustedRiskLevel()">
              {{ getScoreValue(row.adjustedRiskLevel) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dueDiligenceLevel">
          <mat-header-cell *matHeaderCellDef
            >Due Diligence Level</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            {{ entityReport.dueDiligenceLevel || '-' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amlHits">
          <mat-header-cell *matHeaderCellDef>AML Results</mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="20px"
            >
              <div>
                <button
                  class="aml"
                  mat-button
                  (click)="showAmlHits(row.id, RiskReportType.MAIN)"
                >
                  See more
                </button>
              </div>
              <div>
                <a
                  *ngIf="row.amlPayload?.reference"
                  [href]="getAmlLink(row.amlPayload?.reference)"
                  target="_blank"
                >
                  <mat-icon class="link" [matTooltip]="matTip"
                    >open_in_new</mat-icon
                  >
                </a>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="kybHits">
          <mat-header-cell *matHeaderCellDef>KYB Results</mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="20px"
            >
              <div>
                <button class="aml" mat-button (click)="showKybHits(row.id)">
                  See more
                </button>
              </div>
              <div>
                <a
                  *ngIf="entityReport?.kybPayload?.reference"
                  [href]="getAmlLink(entityReport?.kybPayload?.reference)"
                  target="_blank"
                >
                  <mat-icon class="link" [matTooltip]="matTip"
                    >open_in_new</mat-icon
                  >
                </a>
              </div>
            </div>
          </mat-cell>
        </ng-container>
      </div>
    </div>
    <mat-header-row *matHeaderRowDef="displayedColumnsAML"></mat-header-row>
    <mat-row
      *matRowDef="let col; columns: displayedColumnsAML; let entry"
    ></mat-row>
  </mat-table>

  <mat-table [dataSource]="dataSourceDirector" *ngIf="isCorporate">
    <ng-container matColumnDef="riskReportId">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.id }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="itemName">
      <mat-header-cell *matHeaderCellDef
        ><b>Key Persons ({{ directorReports.length }})</b></mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="isLegalPerson(row.companyDirectorId)">
          {{ row.amlPayload?.aml_for_businesses?.business_name }}<br />
          DOI:
          {{
            convertDate(
              row.amlPayload?.aml_for_businesses?.business_incorporation_date
            )
          }}
          <br />
        </ng-container>
        <ng-container *ngIf="!isLegalPerson(row.companyDirectorId)">
          {{ row.amlPayload?.background_checks?.name?.full_name }}<br />
          DOB: {{ convertDate(row.amlPayload?.background_checks?.dob) }} <br />
        </ng-container>
        {{ row.amlPayload?.reference }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="foundInSanctionList">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" [class.found]="row.foundInSanctionList">{{
        getFoundValue(row.foundInSanctionList)
      }}</mat-cell>
    </ng-container>

    <div [formGroup]="form">
      <div formArrayName="directorReportsForm">
        <ng-container matColumnDef="foundInWarningList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div class="actions-column">
              <mat-form-field
                class="mat-select-field"
                *ngIf="allowAmlListAndAdjustmentReasonChange()"
              >
                <mat-select
                  [ngClass]="{
                    redtext: amlListDirectorReportsControl(
                      index,
                      'foundInWarningList'
                    )
                  }"
                  name="foundInWarningList"
                  formControlName="foundInWarningList"
                >
                  <mat-option
                    *ngFor="let entry of amlResultOptions"
                    [value]="entry.id"
                    >{{ entry.value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInWarningList"
            >
              {{ getFoundValue(row.foundInWarningList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInFitnessProbityList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListDirectorReportsControl(
                    index,
                    'foundInFitnessProbityList'
                  )
                }"
                name="foundInFitnessProbityList"
                formControlName="foundInFitnessProbityList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInFitnessProbityList"
            >
              {{ getFoundValue(row.foundInFitnessProbityList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInPEPList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListDirectorReportsControl(
                    index,
                    'foundInPEPList'
                  )
                }"
                name="foundInPEPList"
                formControlName="foundInPEPList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInPEPList"
            >
              {{ getFoundValue(row.foundInPEPList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInAdverseMediaList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListDirectorReportsControl(
                    index,
                    'foundInAdverseMediaList'
                  )
                }"
                name="foundInAdverseMediaList"
                formControlName="foundInAdverseMediaList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInAdverseMediaList"
            >
              {{ getFoundValue(row.foundInAdverseMediaList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="adjustedRiskLevel">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dueDiligenceLevel">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amlHits">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="20px"
            >
              <div>
                <button
                  class="aml"
                  mat-button
                  (click)="showAmlHits(row.id, RiskReportType.DIRECTOR)"
                >
                  See more
                </button>
              </div>
              <div>
                <a
                  *ngIf="row.amlPayload?.reference"
                  [href]="getAmlLink(row.amlPayload?.reference)"
                  target="_blank"
                >
                  <mat-icon class="link" [matTooltip]="matTip"
                    >open_in_new</mat-icon
                  >
                </a>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="kybHits">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="kybHitsUrl">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"></mat-cell>
        </ng-container>
      </div>
    </div>
    <mat-header-row *matHeaderRowDef="displayedColumnsAML"></mat-header-row>
    <mat-row
      *matRowDef="let col; columns: displayedColumnsAML; let entry"
    ></mat-row>
  </mat-table>

  <mat-table [dataSource]="dataSourceGroupMember" *ngIf="isCorporate">
    <ng-container matColumnDef="riskReportId">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.id }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="itemName">
      <mat-header-cell *matHeaderCellDef
        ><b>Group Members ({{ groupMemberReports.length }})</b></mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.amlPayload?.aml_for_businesses?.business_name }}<br />
        DOI:
        {{
          convertDate(
            row.amlPayload?.aml_for_businesses?.business_incorporation_date
          )
        }}
        <br />
        {{ row.amlPayload?.reference }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="foundInSanctionList">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" [class.found]="row.foundInSanctionList">{{
        getFoundValue(row.foundInSanctionList)
      }}</mat-cell>
    </ng-container>

    <div [formGroup]="form">
      <div formArrayName="groupMemberReportsForm">
        <ng-container matColumnDef="foundInWarningList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div class="actions-column">
              <mat-form-field
                class="mat-select-field"
                *ngIf="allowAmlListAndAdjustmentReasonChange()"
              >
                <mat-select
                  [ngClass]="{
                    redtext: amlListGroupMemberReportsControl(
                      index,
                      'foundInWarningList'
                    )
                  }"
                  name="foundInWarningList"
                  formControlName="foundInWarningList"
                >
                  <mat-option
                    *ngFor="let entry of amlResultOptions"
                    [value]="entry.id"
                    >{{ entry.value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInWarningList"
            >
              {{ getFoundValue(row.foundInWarningList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInFitnessProbityList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListGroupMemberReportsControl(
                    index,
                    'foundInFitnessProbityList'
                  )
                }"
                name="foundInFitnessProbityList"
                formControlName="foundInFitnessProbityList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInFitnessProbityList"
            >
              {{ getFoundValue(row.foundInFitnessProbityList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInPEPList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListGroupMemberReportsControl(
                    index,
                    'foundInPEPList'
                  )
                }"
                name="foundInPEPList"
                formControlName="foundInPEPList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInPEPList"
            >
              {{ getFoundValue(row.foundInPEPList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInAdverseMediaList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListGroupMemberReportsControl(
                    index,
                    'foundInAdverseMediaList'
                  )
                }"
                name="foundInAdverseMediaList"
                formControlName="foundInAdverseMediaList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInAdverseMediaList"
            >
              {{ getFoundValue(row.foundInAdverseMediaList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="adjustedRiskLevel">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dueDiligenceLevel">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amlHits">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="20px"
            >
              <div>
                <button
                  class="aml"
                  mat-button
                  (click)="showAmlHits(row.id, RiskReportType.GROUP_MEMBER)"
                >
                  See more
                </button>
              </div>
              <div>
                <a
                  *ngIf="row.amlPayload?.reference"
                  [href]="getAmlLink(row.amlPayload?.reference)"
                  target="_blank"
                >
                  <mat-icon class="link" [matTooltip]="matTip"
                    >open_in_new</mat-icon
                  >
                </a>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="kybHits">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="kybHitsUrl">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"></mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="displayedColumnsAML"></mat-header-row>
      <mat-row
        *matRowDef="let col; columns: displayedColumnsAML; let entry"
      ></mat-row>
    </div>
  </mat-table>

  <mat-table [dataSource]="dataSourceCloseLink" *ngIf="isCorporate">
    <ng-container matColumnDef="riskReportId">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.id }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="itemName">
      <mat-header-cell *matHeaderCellDef
        ><b>Close Links ({{ closeLinkReports.length }})</b></mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        {{ row.amlPayload?.aml_for_businesses?.business_name }}<br />
        DOI:
        {{
          convertDate(
            row.amlPayload?.aml_for_businesses?.business_incorporation_date
          )
        }}
        <br />
        {{ row.amlPayload?.reference }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="foundInSanctionList">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" [class.found]="row.foundInSanctionList">{{
        getFoundValue(row.foundInSanctionList)
      }}</mat-cell>
    </ng-container>

    <div [formGroup]="form">
      <div formArrayName="closeLinkReportsForm">
        <ng-container matColumnDef="foundInWarningList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div class="actions-column">
              <mat-form-field
                class="mat-select-field"
                *ngIf="allowAmlListAndAdjustmentReasonChange()"
              >
                <mat-select
                  [ngClass]="{
                    redtext: amlListCloseLinkReportsControl(
                      index,
                      'foundInWarningList'
                    )
                  }"
                  name="foundInWarningList"
                  formControlName="foundInWarningList"
                >
                  <mat-option
                    *ngFor="let entry of amlResultOptions"
                    [value]="entry.id"
                    >{{ entry.value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInWarningList"
            >
              {{ getFoundValue(row.foundInWarningList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInFitnessProbityList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListCloseLinkReportsControl(
                    index,
                    'foundInFitnessProbityList'
                  )
                }"
                name="foundInFitnessProbityList"
                formControlName="foundInFitnessProbityList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInFitnessProbityList"
            >
              {{ getFoundValue(row.foundInFitnessProbityList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInPEPList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListCloseLinkReportsControl(
                    index,
                    'foundInPEPList'
                  )
                }"
                name="foundInPEPList"
                formControlName="foundInPEPList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInPEPList"
            >
              {{ getFoundValue(row.foundInPEPList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="foundInAdverseMediaList">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field
              class="mat-select-field"
              *ngIf="allowAmlListAndAdjustmentReasonChange()"
            >
              <mat-select
                [ngClass]="{
                  redtext: amlListCloseLinkReportsControl(
                    index,
                    'foundInAdverseMediaList'
                  )
                }"
                name="foundInAdverseMediaList"
                formControlName="foundInAdverseMediaList"
              >
                <mat-option
                  *ngFor="let entry of amlResultOptions"
                  [value]="entry.id"
                  >{{ entry.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="!allowAmlListAndAdjustmentReasonChange()"
              [class.found]="row.foundInAdverseMediaList"
            >
              {{ getFoundValue(row.foundInAdverseMediaList) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="adjustedRiskLevel">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dueDiligenceLevel">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amlHits">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="20px"
            >
              <div>
                <button
                  class="aml"
                  mat-button
                  (click)="showAmlHits(row.id, RiskReportType.CLOSE_LINK)"
                >
                  See more
                </button>
              </div>
              <div>
                <a
                  *ngIf="row.amlPayload?.reference"
                  [href]="getAmlLink(row.amlPayload?.reference)"
                  target="_blank"
                >
                  <mat-icon class="link" [matTooltip]="matTip"
                    >open_in_new</mat-icon
                  >
                </a>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="kybHits">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="kybHitsUrl">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"></mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="displayedColumnsAML"></mat-header-row>
      <mat-row
        *matRowDef="let col; columns: displayedColumnsAML; let entry"
      ></mat-row>
    </div>
  </mat-table>

  <div [formGroup]="form" class="reason-container">
    <div
      [formArrayName]="
        isCorporate ? 'entityReportsForm' : 'personalReportsForm'
      "
    >
      <ng-container
        *ngFor="
          let element of isCorporate
            ? entityReportsControl.controls
            : personalReportsControl.controls;
          let index = index
        "
        [formGroupName]="index"
      >
        <h2 class="header">Adjustment Reason</h2>
        <div fxLayout="column">
          <mat-divider></mat-divider>
          <textarea
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="3"
            matInput
            name="adjustmentReason"
            formControlName="adjustmentReason"
            placeholder="Enter your comments here..."
          ></textarea>
          <mat-divider></mat-divider>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="adjustmentReasonControl?.hasError('required')"
            >Field is required</mat-error
          >
        </div>
      </ng-container>
    </div>
  </div>

  <div fxLayoutAlign="end" class="button-container">
    <button
      *ngIf="allowAmlListAndAdjustmentReasonChange()"
      mat-flat-button
      color="accent"
      [disabled]="form.invalid || isSavingRiskForm || form.pristine"
      [appButtonLoader]="isSavingRiskForm"
      (click)="saveProposal()"
    >
      Save
    </button>
  </div>
</mat-dialog-content>
