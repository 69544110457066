import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { Country } from 'src/app/shared/models/country.model';
import { CompanyDirectorWithDocuments } from 'src/app/onboarding-new/models/company-director.model';
import { CorporateForm } from 'src/app/onboarding-new/models/corporate-form.model';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { RegisteredUsersService } from '../../../registered-users.service';
import { DateTime } from 'luxon';
import { AccountPurposeBusiness } from 'src/app/onboarding-new/models/account-purpose-business.enum';
import { taxOrVatExists } from 'src/app/onboarding-new/components/onboarding-individual-form/onboarding-individual-form.component';
import { CashPercentage } from 'src/app/onboarding-new/models/cash-percentage.enum';
import { LegalStatus } from 'src/app/onboarding-new/models/legal-status.enum';
import { Occupation } from 'src/app/onboarding-new/models/occupation.enum';
import { RegistrationService } from 'src/app/login/services/registration.service';
import {
  addressLineValidator,
  atLeastOneCheckboxIsChecked,
  atLeastOneCheckboxIsCheckedCounterparty,
  fullNameValidator,
  getCountryById,
  percentageValidators,
  firstOrLastNameValidator,
  postCodeCityValidator,
  NA,
} from 'src/app/shared/helpers/various-helpers.helper';
import { v4 as uuidv4 } from 'uuid';
import { SaveRegisteredUser } from '../../../models/save-registered-user.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes-guard';
import { CompanyAccount } from 'src/app/onboarding-new/models/company-account.model';
import { CompanyCounterparty } from 'src/app/onboarding-new/models/company-counterparty.model';
import { CompanyGroupMember } from 'src/app/onboarding-new/models/company-group-member.model';
import { CompanyCloseLink } from 'src/app/onboarding-new/models/company-close-link.model';
import { MatDialog } from '@angular/material/dialog';
import { CompanyCloseLinkSelectDialogComponent } from 'src/app/onboarding-new/shared/company-close-link-select-dialog/company-close-link-select-dialog.component';
import { CompanyDirectorCloseLink } from 'src/app/onboarding-new/models/company-director-close-link.model';
import { RiskScoreLevel } from 'src/app/onboarding-new/models/risk-score-level.enum';

@Component({
  selector: 'app-registered-user-profile-corporate-new',
  templateUrl: './registered-user-profile-corporate-new.component.html',
  styleUrls: ['./registered-user-profile-corporate-new.component.scss'],
})
export class RegisteredUserProfileCorporateNewComponent
  implements OnInit, ComponentCanDeactivate
{
  @Input() userId!: number;
  @Input() corporateData!: CorporateForm;
  @Input() countries: Country[] = [];
  @Input() acceptedCountries: Country[] = [];
  @Input() email!: string;
  @Input() cellPhoneNumber!: string;
  @Input() companyDirectors!: CompanyDirectorWithDocuments[];
  @Input() companyAccounts?: CompanyAccount[];
  @Input() companyCounterparties?: CompanyCounterparty[];
  @Input() companyGroupMembers?: CompanyGroupMember[];
  @Input() companyCloseLinks?: CompanyCloseLink[];
  @Input() companyDirectorCloseLinks?: CompanyDirectorCloseLink[];
  @Output() reloadUser = new EventEmitter<void>();

  form!: FormGroup;
  isSaving: boolean = false;
  statusCheckboxes = [
    'isDirector',
    'isShareholder',
    'isAuthorizedPerson',
    'isUBO',
    'percentageOwnedByShareholder',
    'percentageOwnedByUBO',
  ];

  /**
   * TODO: Decide what to do with documents later (here and user-profile-business)
   * id document should not be uploaded by admin
   * decide if proof of address should be uploaded
   * decide how new stakeholders will be adding id/proof of address
   * can use document functions from ../company-directors-shareholders.component.ts
   * after changes are saved, do not allow to change date of incorporation, but decide later which fields to disable
   * */
  // clearFile: Subject<void> = new Subject<void>();
  // displayedDocumentOptions = new Map<AppDocumentType, string>([
  //   [AppDocumentType.IDENTITY_CARD, 'Identity card'],
  //   [AppDocumentType.PASSPORT, 'Passport'],
  // ]);
  // areFilesUploaded = true;

  registeredAddressTooltip: string =
    "The registered address is the official address of the company, registered with the Company's house.";
  operatingAddressTooltip: string =
    'The operating address is where the company has existence of a place of business or activity (registered branch).';
  closeLinksTooltip: string =
    'A situation in which two or more natural or legal persons are linked by: (a) participation in the form of ownership, direct or by way of control, of 20 % or more of the voting rights or capital of an undertaking; (b) ‘control’ which means the relationship between a parent undertaking and a subsidiary, in all the cases referred to in Article 22(1) and (2) of Directive 2013/34/EU, or a similar relationship between any natural or legal person and an undertaking, any subsidiary undertaking of a subsidiary undertaking also being considered to be a subsidiary of the parent undertaking which is at the head of those undertakings;  (c) a permanent link of both or all of them to the same person by a control relationship.';

  getCountryById = getCountryById;
  occupations = Object.entries(Occupation);
  cashPercentages = Object.entries(CashPercentage);
  legalStatuses = Object.entries(LegalStatus);
  accountPurposes = Object.entries(AccountPurposeBusiness);
  LegalStatus = LegalStatus;
  today: Date = new Date();
  highRiskCountries: string[] = [];
  isHighRisk: boolean | undefined;
  NA = NA;

  readonly minAge = 18;
  minDob: Date = new Date(
    this.today.getFullYear() - this.minAge,
    this.today.getMonth(),
    this.today.getDate()
  );
  percentageValidators = percentageValidators;

  constructor(
    private fb: FormBuilder,
    private registeredUsersService: RegisteredUsersService,
    private errorService: ErrorService,
    private registrationService: RegistrationService,
    private dialog: MatDialog
  ) {}

  canDeactivate(): boolean {
    return this.form.pristine;
  }

  ngOnInit(): void {
    this.isHighRisk =
      this.corporateData.riskReports?.find(
        (r) => r.id === this.corporateData.currentRiskReportId
      )?.adjustedRiskLevel === RiskScoreLevel.HIGH;

    this.form = this.fb.group({
      entityForm: this.fb.group({
        businessName: [
          this.corporateData?.businessName,
          [Validators.required, ...fullNameValidator],
        ],
        email: [this.email],
        cellPhoneNumber: [this.cellPhoneNumber, phoneValidator()],
        businessTradingName: [
          this.corporateData?.businessTradingName,
          [...fullNameValidator],
        ],
        taxNumber: [
          this.corporateData?.taxNumber,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
            Validators.maxLength(50),
          ],
        ],
        taxNumberNotAvailable: [''],
        vatNumber: [
          this.corporateData?.vatNumber,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
            Validators.maxLength(50),
          ],
        ],
        vatNumberNotAvailable: [''],
        taxAndVatCountry: [
          this.corporateData?.taxAndVatCountry,
          taxOrVatExists(true),
        ],
        companyUrls: [
          this.corporateData?.companyUrls,
          [
            Validators.pattern(
              "^[a-zA-Z0-9 ~!@#$%&*\\(\\)\\-\\+=[\\]\\/:;',.?_]*$"
            ),
            Validators.maxLength(200),
          ],
        ],
        companyActivity: [
          this.corporateData?.companyActivity,
          Validators.required,
        ],
        companyActivityDescr: [
          this.corporateData?.companyActivityDescr,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(1000),
          ],
        ],
        isRegulated: [this.corporateData?.isRegulated, Validators.required],
        regulatoryAuthority: [
          this.corporateData?.regulatoryAuthority,
          [
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(200),
          ],
        ],
        canFundFromOwnAccount: [
          this.corporateData?.canFundFromOwnAccount,
          Validators.required,
        ],
        annualIncome: [
          this.corporateData?.annualIncome
            ? parseInt(this.corporateData?.annualIncome).toLocaleString('en-US')
            : null,
          [
            Validators.required,
            Validators.maxLength(19),
            Validators.pattern('^(?!.*,,)[0-9]+(?:,[0-9]+)*$'), // prohibits comma at the start/end and consecutive commas
          ],
        ],
        accountPurpose: [
          this.corporateData?.accountPurpose
            ? this.corporateData?.accountPurpose.split(', ')
            : '',
          [Validators.required],
        ],
        monthlyLoading: [
          this.corporateData?.monthlyLoading
            ? parseInt(this.corporateData?.monthlyLoading).toLocaleString(
                'en-US'
              )
            : null,
          [
            Validators.required,
            Validators.maxLength(19),
            Validators.pattern('^(?!.*,,)[0-9]+(?:,[0-9]+)*$'), // prohibits comma at the start/end and consecutive commas
          ],
        ],
        percentageOfCash: [
          this.corporateData?.percentageOfCash,
          Validators.required,
        ],
        doubleAnnualIncome: [
          this.corporateData?.doubleAnnualIncome,
          Validators.required,
        ],
        zeroBalanceStatement: [
          this.corporateData?.zeroBalanceStatement,
          Validators.required,
        ],
        legalStatus: [
          this.isOtherLegalStatus()
            ? LegalStatus['Other type of Company']
            : this?.corporateData?.legalStatus,
          Validators.required,
        ],
        otherLegalStatus: [
          this.isOtherLegalStatus() ? this?.corporateData?.legalStatus : null,
          [
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(100),
          ],
        ],
        operateInHighRiskCountries: [
          this.corporateData?.operateInHighRiskCountries,
          Validators.required,
        ],
        isFinancialStatementAudited: [
          this.corporateData?.isFinancialStatementAudited,
          Validators.required,
        ],
        expectedNumOfPhysicalCards: [
          this.corporateData?.expectedNumOfPhysicalCards,
          Validators.required,
        ],
        incorporationDate: [
          this.corporateData?.incorporationDate
            ? DateTime.fromISO(this.corporateData?.incorporationDate)
            : null,
          Validators.required,
        ],
        registrationNumber: [
          this.corporateData?.registrationNumber,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(50),
          ],
        ],
        additionalPhoneNumber: [
          this.corporateData?.additionalPhoneNumber,
          phoneValidator(),
        ],
        incorporationAddress: this.fb.group({
          streetAddress: [
            this.corporateData?.incorporationAddress?.streetAddress,
            [Validators.required, ...addressLineValidator],
          ],
          additionalStreetAddress: [
            this.corporateData?.incorporationAddress?.additionalStreetAddress,
            [...addressLineValidator],
          ],
          postCode: [
            this.corporateData?.incorporationAddress?.postCode,
            [Validators.required, ...postCodeCityValidator],
          ],
          city: [
            this.corporateData?.incorporationAddress?.city,
            [Validators.required, ...postCodeCityValidator],
          ],
          countryId: [
            this.corporateData?.incorporationAddress?.countryId,
            Validators.required,
          ],
        }),
        operatingAddress: this.fb.group({
          streetAddress: [
            this.corporateData?.operatingAddress?.streetAddress,
            [Validators.required, ...addressLineValidator],
          ],
          additionalStreetAddress: [
            this.corporateData?.operatingAddress?.additionalStreetAddress,
            [...addressLineValidator],
          ],
          postCode: [
            this.corporateData?.operatingAddress?.postCode,
            [Validators.required, ...postCodeCityValidator],
          ],
          city: [
            this.corporateData?.operatingAddress?.city,
            [Validators.required, ...postCodeCityValidator],
          ],
          countryId: [
            this.corporateData?.operatingAddress?.countryId,
            Validators.required,
          ],
        }),
        isSameAddress: [
          JSON.stringify(this.corporateData?.incorporationAddress) ===
            JSON.stringify(this.corporateData?.operatingAddress),
          Validators.required,
        ],
      }),
      directorForm: this.fb.array([], {
        validators: [
          this.sharesValidator.bind(this), // shares don't exceed 100%
          this.positionValidator.bind(this), // only one authorized person and at least one director/shareholder/UBO
        ],
      }),
      groupMemberForm: this.fb.array([]),
      closeLinkForm: this.fb.array([]),
      accountForm: this.fb.array([]),
      counterpartyForm: this.fb.array([], {
        validators: [
          this.atLeastOneIncomingOutgoingTransaction.bind(this), // at least one incoming and one outgoing transaction
        ],
      }),
    });

    this.companyDirectors.forEach((director) => {
      this.addDirector(director);
    });

    if (this.companyAccounts) {
      this.companyAccounts.forEach((account) => {
        this.addAccount(account);
      });
    }

    if (this.companyCounterparties) {
      this.companyCounterparties.forEach((account) => {
        this.addCounterparty(account);
      });
    }

    if (this.companyGroupMembers) {
      this.companyGroupMembers.forEach((groupMember) => {
        this.addGroupMember(groupMember);
      });
    }

    this.entityEmailControl?.disable();
    this.cellPhoneNumberControl?.disable();
    this.incorporationDateControl?.disable();

    this.cellPhoneNumberControl?.valueChanges.pipe(first()).subscribe((val) => {
      // removes redundant prefix from phone number when set from backend
      this.cellPhoneNumberControl?.setValue(val, {
        emitEvent: false,
      });
      setTimeout(() => this.cellPhoneNumberControl?.markAsPristine());
    });

    this.additionalPhoneNumberControl?.valueChanges
      .pipe(first())
      .subscribe((val) => {
        // removes redundant prefix from phone number when set from backend
        this.additionalPhoneNumberControl?.setValue(val, {
          emitEvent: false,
        });
        setTimeout(() => this.additionalPhoneNumberControl?.markAsPristine());
      });

    this.registrationService.getHighRiskCountries().subscribe((countries) => {
      this.highRiskCountries = countries.map((c) =>
        getCountryById(countries, c.id)
      );
    });

    // conditional validators
    this.isRegulatedControl?.valueChanges.subscribe((val) => {
      if (val) {
        this.entityFormControl
          .get('regulatoryAuthority')
          ?.setValidators([
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(200),
          ]);
      } else {
        this.entityFormControl.get('regulatoryAuthority')?.clearValidators();
        this.entityFormControl.get('regulatoryAuthority')?.setValue(null);
      }
      this.entityFormControl
        .get('regulatoryAuthority')
        ?.updateValueAndValidity();
    });

    this.legalStatusControl?.valueChanges.subscribe((val) => {
      if (val === LegalStatus['Other type of Company']) {
        this.entityFormControl
          .get('otherLegalStatus')
          ?.setValidators([
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(100),
          ]);
      } else {
        this.entityFormControl.get('otherLegalStatus')?.clearValidators();
      }
      this.entityFormControl.get('otherLegalStatus')?.updateValueAndValidity();
    });

    // if tax number is N/A set the checkbox to true
    if (this.corporateData?.taxNumber === NA) {
      this.taxNumberNotAvailableControl?.setValue(true);
      this.taxNumberControl?.disable();
    } else {
      this.taxNumberNotAvailableControl?.setValue(false);
      this.taxNumberControl?.enable();
    }

    // if vat number is N/A set the checkbox to true
    if (this.corporateData?.vatNumber === NA) {
      this.vatNumberNotAvailableControl?.setValue(true);
      this.vatNumberControl?.disable();
    } else {
      this.vatNumberNotAvailableControl?.setValue(false);
      this.vatNumberControl?.enable();
    }

    // if tax number is NA, disable the tax field and set the tax number to NA
    this.taxNumberNotAvailableControl?.valueChanges.subscribe(
      (notAvailable) => {
        if (notAvailable) {
          this.taxNumberControl?.disable();
          this.taxNumberControl?.setValue(NA);
        } else {
          this.taxNumberControl?.enable();
          this.taxNumberControl?.setValue(null);
        }
        // run custom validator of country
        this.taxAndVatCountryControl?.updateValueAndValidity();
      }
    );
    this.taxNumberControl?.valueChanges.subscribe(() => {
      this.taxAndVatCountryControl?.updateValueAndValidity();
    });

    // if vat number is NA, disable the vat field and set the vat number to NA
    this.vatNumberNotAvailableControl?.valueChanges.subscribe(
      (notAvailable) => {
        if (notAvailable) {
          this.vatNumberControl?.disable();
          this.vatNumberControl?.setValue(NA);
        } else {
          this.vatNumberControl?.enable();
          this.vatNumberControl?.setValue(null);
        }
        // run custom validator of country
        this.taxAndVatCountryControl?.updateValueAndValidity();
      }
    );
    this.vatNumberControl?.valueChanges.subscribe(() => {
      this.taxAndVatCountryControl?.updateValueAndValidity();
    });

    if (!this.form.valid) {
      this.form.markAllAsTouched();
    }
  }

  isOtherLegalStatus() {
    return (
      this?.corporateData?.legalStatus &&
      this?.corporateData?.legalStatus !==
        LegalStatus['Private Limited Company'] &&
      this?.corporateData?.legalStatus !== LegalStatus['Public Limited Company']
    );
  }

  addDirector(director: CompanyDirectorWithDocuments) {
    if (director.isLegalPerson) {
      this.directorFormControl.push(
        this.fb.group(
          {
            id: [director.id],
            firstName: null,
            lastName: null,
            isDirector: [director.isDirector, { updateOn: 'change' }],
            isShareholder: [director.isShareholder, { updateOn: 'change' }],
            isUBO: [director.isUBO, { updateOn: 'change' }],
            isAuthorizedPerson: [
              director.isAuthorizedPerson,
              { updateOn: 'change' },
            ],
            email: [
              director.email,
              [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
              ],
            ],
            dateOfBirth: null,
            nationality: null,
            address: this.fb.group({
              streetAddress: [
                director.address?.streetAddress,
                [Validators.required, ...addressLineValidator],
              ],
              additionalStreetAddress: [
                director.address?.additionalStreetAddress,
                [...addressLineValidator],
              ],
              postCode: [
                director.address?.postCode,
                [Validators.required, ...postCodeCityValidator],
              ],
              city: [
                director.address?.city,
                [Validators.required, ...postCodeCityValidator],
              ],
              countryId: [director.address?.countryId, Validators.required],
            }),
            isTempAddress: null,
            isPEP: null,
            phoneNumber: null,
            taxNumber: [director?.taxNumber],
            taxResidencyCountry: director?.taxResidencyCountry,
            percentageOwnedByShareholder: [
              director?.percentageOwnedByShareholder,
              this.percentageValidators,
            ],
            percentageOwnedByUBO: [
              director?.percentageOwnedByUBO,
              this.percentageValidators,
            ],
            isLegalPerson: [true, Validators.requiredTrue],
            legalPersonName: [
              director?.legalPersonName,
              [Validators.required, ...fullNameValidator],
            ],
            legalPersonRegistrationNumber: [
              director?.legalPersonRegistrationNumber,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
                Validators.maxLength(50),
              ],
            ],
            legalPersonIncorporationDate: [
              director?.legalPersonIncorporationDate
                ? DateTime.fromISO(director?.legalPersonIncorporationDate)
                : null,
              Validators.required,
            ],
            isActive: [director?.isActive, Validators.required],
            hasCloseLinks: [director?.hasCloseLinks, { updateOn: 'change' }],
            // documents: [director?.documents],
          },
          {
            updateOn: 'blur',
            validators: [
              atLeastOneCheckboxIsChecked(),
              this.closeLinksFilled(director.id),
            ],
          }
        )
      );
    } else {
      this.directorFormControl.push(
        this.fb.group(
          {
            id: [director.id],
            firstName: [
              director.firstName,
              [Validators.required, ...firstOrLastNameValidator],
            ],
            lastName: [
              director.lastName,
              [Validators.required, ...firstOrLastNameValidator],
            ],
            isDirector: [director.isDirector, { updateOn: 'change' }],
            isShareholder: [director.isShareholder, { updateOn: 'change' }],
            isUBO: [director.isUBO, { updateOn: 'change' }],
            isAuthorizedPerson: [
              director.isAuthorizedPerson,
              { updateOn: 'change' },
            ],
            email: [
              director.email,
              [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
              ],
            ],
            dateOfBirth: [
              director.dateOfBirth
                ? DateTime.fromISO(director.dateOfBirth)
                : null,
              Validators.required,
            ],
            nationality: [director.nationality, Validators.required],
            address: this.fb.group({
              streetAddress: [
                director.address?.streetAddress,
                [Validators.required, ...addressLineValidator],
              ],
              additionalStreetAddress: [
                director.address?.additionalStreetAddress,
                [...addressLineValidator],
              ],
              postCode: [
                director.address?.postCode,
                [Validators.required, ...postCodeCityValidator],
              ],
              city: [
                director.address?.city,
                [Validators.required, ...postCodeCityValidator],
              ],
              countryId: [director.address?.countryId, Validators.required],
            }),
            isTempAddress: director.isTempAddress,
            isPEP: [director.isPEP, Validators.required],
            phoneNumber: [
              director.phoneNumber,
              { updateOn: 'change' }, // because enabling a disabled director the phoneNumber became invalid
              [Validators.required, phoneValidator()],
            ],
            taxNumber: [
              director.taxNumber,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
                Validators.maxLength(50),
              ],
            ],
            taxResidencyCountry: director.taxResidencyCountry, // optional
            percentageOwnedByShareholder: [
              director.percentageOwnedByShareholder,
              this.percentageValidators,
            ],
            percentageOwnedByUBO: [
              director.percentageOwnedByUBO,
              this.percentageValidators,
            ],
            isLegalPerson: false,
            legalPersonName: null,
            legalPersonRegistrationNumber: null,
            legalPersonIncorporationDate: null,
            isActive: [director?.isActive, Validators.required],
            hasCloseLinks: [director?.hasCloseLinks, { updateOn: 'change' }],
            // documents: [director?.documents],
          },
          {
            updateOn: 'blur',
            validators: [
              atLeastOneCheckboxIsChecked(),
              this.closeLinksFilled(director.id),
            ],
          }
        )
      );
    }

    // conditional validators
    this.subscribeDirectorToValueChanges(director.id);
  }

  addNewDirector(isNaturalPerson: boolean): void {
    const id = uuidv4();
    if (isNaturalPerson) {
      this.directorFormControl.push(
        this.fb.group(
          {
            id: [id],
            firstName: [
              null,
              [Validators.required, ...firstOrLastNameValidator],
            ],
            lastName: [
              null,
              [Validators.required, ...firstOrLastNameValidator],
            ],
            isDirector: [false, { updateOn: 'change' }],
            isShareholder: [false, { updateOn: 'change' }],
            isUBO: [false, { updateOn: 'change' }],
            isAuthorizedPerson: [false, { updateOn: 'change' }],
            email: [
              null,
              [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
              ],
            ],
            dateOfBirth: [null, Validators.required],
            nationality: [null, Validators.required],
            address: this.fb.group({
              streetAddress: [
                null,
                [Validators.required, ...addressLineValidator],
              ],
              additionalStreetAddress: [null, [...addressLineValidator]],
              postCode: [null, [Validators.required, ...postCodeCityValidator]],
              city: [null, [Validators.required, ...postCodeCityValidator]],
              countryId: [null, Validators.required],
            }),
            isTempAddress: null,
            isPEP: [null, Validators.required],
            phoneNumber: [null, [Validators.required, phoneValidator()]],
            taxNumber: [
              null,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
                Validators.maxLength(50),
              ],
            ],
            taxResidencyCountry: null, // optional
            percentageOwnedByShareholder: [null, this.percentageValidators],
            percentageOwnedByUBO: [null, this.percentageValidators],
            isLegalPerson: false,
            legalPersonName: null,
            legalPersonRegistrationNumber: null,
            legalPersonIncorporationDate: null,
            isActive: [true, Validators.required],
            hasCloseLinks: [null, { updateOn: 'change' }],
            // documents: [],
          },
          {
            updateOn: 'blur',
            validators: [
              atLeastOneCheckboxIsChecked(),
              this.closeLinksFilled(id),
            ],
          }
        )
      );
    } else {
      this.directorFormControl.push(
        this.fb.group(
          {
            id: [id],
            firstName: null,
            lastName: null,
            isDirector: [false, { updateOn: 'change' }],
            isShareholder: [false, { updateOn: 'change' }],
            isUBO: [false, { updateOn: 'change' }],
            isAuthorizedPerson: [false, { updateOn: 'change' }],
            email: [
              null,
              [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
              ],
            ],
            dateOfBirth: null,
            nationality: null,
            address: this.fb.group({
              streetAddress: [
                null,
                [Validators.required, ...addressLineValidator],
              ],
              additionalStreetAddress: [null, [...addressLineValidator]],
              postCode: [null, [Validators.required, ...postCodeCityValidator]],
              city: [null, [Validators.required, ...postCodeCityValidator]],
              countryId: [null, Validators.required],
            }),
            isTempAddress: null,
            isPEP: null,
            phoneNumber: null,
            taxNumber: [null],
            taxResidencyCountry: null,
            percentageOwnedByShareholder: [null, this.percentageValidators],
            percentageOwnedByUBO: [null, this.percentageValidators],
            isLegalPerson: [true, Validators.requiredTrue],
            legalPersonName: [
              null,
              [Validators.required, ...fullNameValidator],
            ],
            legalPersonRegistrationNumber: [
              null,
              [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
                Validators.maxLength(50),
              ],
            ],
            legalPersonIncorporationDate: [null, Validators.required],
            isActive: [true, Validators.required],
            hasCloseLinks: [null, { updateOn: 'change' }],
            // documents: [],
          },
          {
            updateOn: 'blur',
            validators: [
              atLeastOneCheckboxIsChecked(),
              this.closeLinksFilled(id),
            ],
          }
        )
      );
    }

    // conditional validators
    this.subscribeDirectorToValueChanges(id);
    // this.areFilesUploaded = false;
  }

  subscribeDirectorToValueChanges(id: number | string) {
    // when searching for index get all directors in the form regardless of the disabled status
    const allDirectors = this.directorFormControl?.getRawValue();
    const directorIndex = allDirectors.findIndex(
      (d: CompanyDirectorWithDocuments) => d.id === id
    );
    if (directorIndex !== -1) {
      const directorControl = this.getDirectorControl(directorIndex);
      // disable non-active directors
      if (directorControl.get('isActive')?.value === false) {
        directorControl.disable();
      } else {
        // set if close links are required or not
        this.setValidatorsForCloseLinks(directorControl, directorIndex);

        // if status/position of key person changes, set if close links are required or not
        this.statusCheckboxes.forEach((checkbox) => {
          directorControl.get(checkbox)?.valueChanges.subscribe(() => {
            this.setValidatorsForCloseLinks(directorControl, directorIndex);
          });
        });

        this.isShareholderControl(directorIndex)?.valueChanges.subscribe(
          (val) => {
            if (val) {
              directorControl
                .get('percentageOwnedByShareholder')
                ?.setValidators(
                  this.percentageValidators.concat(Validators.required)
                );
            } else {
              directorControl
                .get('percentageOwnedByShareholder')
                ?.clearValidators();
            }
            directorControl
              .get('percentageOwnedByShareholder')
              ?.updateValueAndValidity();
          }
        );

        this.isUBOControl(directorIndex)?.valueChanges.subscribe((val) => {
          if (val) {
            directorControl
              .get('percentageOwnedByUBO')
              ?.setValidators(
                this.percentageValidators.concat(Validators.required)
              );
          } else {
            directorControl.get('percentageOwnedByUBO')?.clearValidators();
          }
          directorControl.get('percentageOwnedByUBO')?.updateValueAndValidity();
        });

        // if director says that has no close links - delete its close link connections
        this.hasCloseLinksControl(directorIndex)?.valueChanges.subscribe(
          (val) => {
            if (!val) {
              this.companyDirectorCloseLinks =
                this.companyDirectorCloseLinks?.filter(
                  (dcl) =>
                    !(
                      dcl.directorId ===
                      this.getDirectorControl(directorIndex).value.id
                    )
                );
            }
          }
        );
      }
    }
  }

  setValidatorsForCloseLinks(
    directorControl: FormGroup,
    directorIndex: number
  ) {
    /** set close links to required if:
     * 1. key-person has already completed his close links (hasCloseLinks) regardless of client risk and key-person position, even if he's authorized person
     * OR
     * 2. key-person hasn't completed his close links (hasCloseLinks) and is not the only authorized contact person and client is high risk
     */
    if (
      this.hasCloseLinksControl(directorIndex)?.value !== null ||
      (this.hasCloseLinksControl(directorIndex)?.value === null &&
        !this.isOnlyAuthorizedPerson(directorIndex) &&
        this.isHighRisk)
    ) {
      directorControl
        .get('hasCloseLinks')
        ?.setValidators([Validators.required]);
    } else {
      directorControl.get('hasCloseLinks')?.clearValidators();
    }
    directorControl.get('hasCloseLinks')?.updateValueAndValidity();
  }

  // returns if key person is only authorized contact person
  isOnlyAuthorizedPerson(index: number) {
    return !(
      this.isLegalPersonControl(index)?.value ||
      (!this.isLegalPersonControl(index)?.value &&
        (!this.isAuthorizedPersonControl(index)?.value ||
          this.isDirectorControl(index)?.value ||
          this.isShareholderControl(index)?.value ||
          this.isUBOControl(index)?.value))
    );
  }

  addAccount(account: CompanyAccount) {
    this.accountFormControl.push(
      this.fb.group({
        id: [account.id],
        name: [account.name, [Validators.required, ...fullNameValidator]],
        country: [account.country, Validators.required],
        isActive: [account.isActive, Validators.required],
      })
    );

    // when searching for index get all accounts in the form regardless of the disabled status
    const allAccounts = this.accountFormControl?.getRawValue();
    const accountIndex = allAccounts.findIndex(
      (d: CompanyAccount) => d.id === account.id
    );
    if (accountIndex !== -1) {
      const accountControl = this.getAccountControl(accountIndex);
      // disable non-active accounts
      if (accountControl.get('isActive')?.value === false) {
        accountControl.disable();
      }
    }
  }

  addNewAccount() {
    this.accountFormControl.push(
      this.fb.group({
        id: [uuidv4()],
        name: [null, [Validators.required, ...fullNameValidator]],
        country: [null, Validators.required],
        isActive: [true, Validators.required],
      })
    );
  }

  addCounterparty(counterparty: CompanyCounterparty) {
    this.counterpartyFormControl.push(
      this.fb.group(
        {
          id: [counterparty.id],
          name: [
            counterparty.name,
            [Validators.required, ...fullNameValidator],
          ],
          country: [counterparty.country, Validators.required],
          businessActivity: [
            counterparty.businessActivity,
            Validators.required,
          ],
          isIncomingTransaction: [counterparty.isIncomingTransaction],
          isOutgoingTransaction: [counterparty.isOutgoingTransaction],
          isActive: [counterparty.isActive, Validators.required],
        },
        { validators: atLeastOneCheckboxIsCheckedCounterparty() }
      )
    );

    // when searching for index get all counterparties in the form regardless of the disabled status
    const allCounterparties = this.counterpartyFormControl?.getRawValue();
    const counterpartyIndex = allCounterparties.findIndex(
      (d: CompanyAccount) => d.id === counterparty.id
    );
    if (counterpartyIndex !== -1) {
      const counterpartyControl =
        this.getCounterpartyControl(counterpartyIndex);
      // disable non-active counterparties
      if (counterpartyControl.get('isActive')?.value === false) {
        counterpartyControl.disable();
      }
    }
  }

  addNewCounterparty() {
    this.counterpartyFormControl.push(
      this.fb.group(
        {
          id: [uuidv4()],
          name: [null, [Validators.required, ...fullNameValidator]],
          country: [null, Validators.required],
          businessActivity: [null, Validators.required],
          isIncomingTransaction: [null],
          isOutgoingTransaction: [null],
          isActive: [true, Validators.required],
        },
        { validators: atLeastOneCheckboxIsCheckedCounterparty() }
      )
    );
  }

  addGroupMember(groupMember: CompanyGroupMember) {
    this.groupMemberFormControl.push(
      this.fb.group({
        id: [groupMember.id],
        name: [groupMember.name, [Validators.required, ...fullNameValidator]],
        registrationNumber: [
          groupMember.registrationNumber,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(50),
          ],
        ],
        incorporationDate: [
          groupMember.incorporationDate
            ? DateTime.fromISO(groupMember.incorporationDate)
            : null,
          [Validators.required],
        ],
        address: this.fb.group({
          streetAddress: [
            groupMember.address?.streetAddress,
            [Validators.required, ...addressLineValidator],
          ],
          additionalStreetAddress: [
            groupMember.address?.additionalStreetAddress,
            [...addressLineValidator],
          ],
          postCode: [
            groupMember.address?.postCode,
            [Validators.required, ...postCodeCityValidator],
          ],
          city: [
            groupMember.address?.city,
            [Validators.required, ...postCodeCityValidator],
          ],
          countryId: [groupMember.address?.countryId, Validators.required],
        }),
        isActive: [groupMember.isActive, Validators.required],
      })
    );

    // when searching for index get all group members in the form regardless of the disabled status
    const allGroupMembers = this.groupMemberFormControl?.getRawValue();
    const groupMemberIndex = allGroupMembers.findIndex(
      (d: CompanyGroupMember) => d.id === groupMember.id
    );
    if (groupMemberIndex !== -1) {
      const groupMemberControl = this.getGroupMemberControl(groupMemberIndex);
      // disable non-active group members
      if (groupMemberControl.get('isActive')?.value === false) {
        groupMemberControl.disable();
      }
    }
  }

  addNewGroupMember() {
    this.groupMemberFormControl.push(
      this.fb.group({
        id: [uuidv4()],
        name: ['', [Validators.required, ...fullNameValidator]],
        registrationNumber: [
          '',
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
            Validators.maxLength(50),
          ],
        ],
        incorporationDate: ['', [Validators.required]],
        address: this.fb.group({
          streetAddress: ['', [Validators.required, ...addressLineValidator]],
          additionalStreetAddress: ['', [...addressLineValidator]],
          postCode: ['', [Validators.required, ...postCodeCityValidator]],
          city: ['', [Validators.required, ...postCodeCityValidator]],
          countryId: ['', Validators.required],
        }),
        isActive: [true, Validators.required],
      })
    );
  }

  // check if user says that they have close links and close links were entered
  closeLinksFilled(id: number | string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (this.companyDirectorCloseLinks && value.hasCloseLinks === true) {
        var dcl = this.companyDirectorCloseLinks.find(
          (directorCloseLink) => directorCloseLink.directorId === id
        );
        // if said that has close links but director-close links don't exist, form is Incomplete
        if (!dcl) {
          return { missingCloseLinks: true };
        }
      }
      return null;
    };
  }

  selectCompanyCloseLink(directorId: number, index: number) {
    let dialogRef = this.dialog.open<CompanyCloseLinkSelectDialogComponent>(
      CompanyCloseLinkSelectDialogComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '770px',
        disableClose: true,
        data: {
          countries: this.countries,
          closeLinks: this.companyCloseLinks,
          directorCloseLinks: this.companyDirectorCloseLinks,
          directorId: directorId,
          isLegalPerson: this.isLegalPersonControl(index)?.value,
          isReviewed: false,
          isFirstTimeEDD: true,
          hasCloselinks: this.hasCloseLinksControl(index)?.value,
          isNotOnboarding: true,
        },
      }
    );
    const closeLinkChangeSub =
      dialogRef.componentInstance.companyCloseLinksChange.subscribe(
        (updatedCloseLinks) => {
          // receives all close links (new and updated ones with uuidv4 id)
          this.companyCloseLinks = updatedCloseLinks;
        }
      );
    const directorCloseLinkChangeSub =
      dialogRef.componentInstance.companyDirectorCloseLinksChange.subscribe(
        (updatedDirectorCloseLinks) => {
          // receives only the close links of the given director (new and updated ones with close link uuidv4 id)

          // make a copy of the original array of close links combinations
          const directorCloseLinksCopy = this.companyDirectorCloseLinks
            ? [...this.companyDirectorCloseLinks]
            : [];

          updatedDirectorCloseLinks.forEach((newDcl) => {
            const index = directorCloseLinksCopy.findIndex(
              (dcl) =>
                dcl.closeLinkId === newDcl.closeLinkId &&
                dcl.directorId === directorId
            );

            if (index !== -1) {
              // if the combination with the same closeLinkId and directorId exists, update its properties
              directorCloseLinksCopy[index] = {
                ...directorCloseLinksCopy[index],
                ...newDcl,
              };
            } else {
              // if the combination doesn't exist, add it to the array
              directorCloseLinksCopy.push(newDcl);
            }
          });

          // find close link combinations that don't exist in the array for the given director
          const directorCloseLinksToRemove = directorCloseLinksCopy.filter(
            (dcl) =>
              dcl.directorId === directorId &&
              !updatedDirectorCloseLinks.some(
                (newDcl) =>
                  dcl.closeLinkId === newDcl.closeLinkId &&
                  dcl.directorId === directorId
              )
          );

          // remove close link combinations that don't exist in the array for the given director
          const newDirectorCloseLinks = directorCloseLinksCopy.filter((dcl) => {
            return !directorCloseLinksToRemove.some(
              (closeLinkToRemove) =>
                dcl.closeLinkId === closeLinkToRemove.closeLinkId &&
                dcl.directorId === closeLinkToRemove.directorId
            );
          });

          this.companyDirectorCloseLinks = newDirectorCloseLinks;
        }
      );
    dialogRef.afterClosed().subscribe(() => {
      closeLinkChangeSub.unsubscribe();
      directorCloseLinkChangeSub.unsubscribe();
      this.getDirectorControl(index).updateValueAndValidity(); // rerun validators to check closeLinksFilled
    });
  }

  async saveRegisteredUserForm(): Promise<void> {
    this.isSaving = true;
    const incorporationAddress = {
      countryId: this.incorporationCountryIdControl?.value,
      streetAddress: this.incorporationStreetAddressControl?.value,
      additionalStreetAddress:
        this.incorporationAdditionalStreetAddressControl?.value || '',
      postCode: this.incorporationPostCodeControl?.value,
      city: this.incorporationCityControl?.value,
    };
    const operatingAddress = this.isSameAddressControl?.value
      ? incorporationAddress
      : {
          countryId: this.operatingCountryIdControl?.value,
          streetAddress: this.operatingStreetAddressControl?.value,
          additionalStreetAddress:
            this.operatingAdditionalStreetAddressControl?.value || '',
          postCode: this.operatingPostCodeControl?.value,
          city: this.operatingCityControl?.value,
        };
    const corporateForm: CorporateForm = {
      businessName: this.businessNameControl?.value,
      businessTradingName: this.businessTradingNameControl?.value,
      taxNumber: this.taxNumberControl?.value,
      vatNumber: this.vatNumberControl?.value,
      taxAndVatCountry: this.taxAndVatCountryControl?.value,
      companyUrls: this.companyUrlsControl?.value,
      companyActivity: this.companyActivityControl?.value,
      companyActivityDescr: this.companyActivityDescrControl?.value,
      isRegulated: this.isRegulatedControl?.value,
      regulatoryAuthority: this.regulatoryAuthorityControl?.value,
      canFundFromOwnAccount: this.canFundFromOwnAccountControl?.value,
      annualIncome: this.annualIncomeControl?.value
        ? this.annualIncomeControl?.value.replace(/\D/g, '')
        : '',
      accountPurpose: this.accountPurposeControl?.value
        ? this.accountPurposeControl?.value.join(', ')
        : '',
      monthlyLoading: this.monthlyLoadingControl?.value
        ? this.monthlyLoadingControl?.value.replace(/\D/g, '')
        : '',
      percentageOfCash: this.percentageOfCashControl?.value,
      doubleAnnualIncome: this.doubleAnnualIncomeControl?.value,
      zeroBalanceStatement: this.zeroBalanceStatementControl?.value,
      legalStatus:
        this.legalStatusControl?.value === LegalStatus['Other type of Company']
          ? this.otherLegalStatusControl?.value
          : this.legalStatusControl?.value,
      operateInHighRiskCountries: this.operateInHighRiskCountriesControl?.value,
      isFinancialStatementAudited:
        this.isFinancialStatementAuditedControl?.value,
      expectedNumOfPhysicalCards: this.expectedNumOfPhysicalCardsControl?.value,
      incorporationDate: (
        this.incorporationDateControl?.value as DateTime
      ).toISODate(),
      registrationNumber: this.registrationNumberControl?.value,
      additionalPhoneNumber:
        this.additionalPhoneNumberControl?.value?.e164Number || null,
      incorporationAddress,
      operatingAddress,
    };

    const directorRawValues = this.directorFormControl?.getRawValue(); // get all control values from the form, regardless of the disabled status
    const companyDirectors = directorRawValues.map((d) => {
      const body = {
        ...d,
        dateOfBirth: d.dateOfBirth
          ? (d.dateOfBirth as DateTime).toISODate()
          : null,
        phoneNumber: d.phoneNumber
          ? d.phoneNumber.hasOwnProperty('e164Number')
            ? d.phoneNumber.e164Number
            : d.phoneNumber
          : null,
        legalPersonIncorporationDate: d.legalPersonIncorporationDate
          ? (d.legalPersonIncorporationDate as DateTime).toISODate()
          : null,
      };
      return body;
    });

    const groupMemberRawValues = this.groupMemberFormControl?.getRawValue(); // get all control values from the form, regardless of the disabled status
    const companyGroupMembers = groupMemberRawValues.map((gm) => {
      const body = {
        ...gm,
        incorporationDate: gm.incorporationDate
          ? (gm.incorporationDate as DateTime).toISODate()
          : null,
      };
      return body;
    });

    const body: SaveRegisteredUser = {
      userId: this.userId,
      corporateData: corporateForm,
      companyDirectors: companyDirectors,
      companyCloseLinks: this.companyCloseLinks,
      companyDirectorCloseLinks: this.companyDirectorCloseLinks,
      companyCounterparties: this.counterpartyFormControl?.getRawValue(),
      companyAccounts: this.accountFormControl?.getRawValue(),
      companyGroupMembers: companyGroupMembers,
    };

    // dont save ids of directors/close links/accounts/... in backend because they are uuidv4 strings
    (
      await this.registeredUsersService.saveRegisteredUserCorporateData(body)
    ).subscribe(
      () => {
        this.form.markAsPristine();
        this.isSaving = false;
        this.reloadUser.emit();
      },
      (error) => {
        this.isSaving = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  onDirectorToggleChange(
    event: MatSlideToggleChange,
    index: number,
    id: number
  ): void {
    const isChecked = event.checked;

    if (isChecked) {
      this.getDirectorControl(index).get('isActive')?.setValue(true);
      const control = this.directorFormControl.at(index) as AbstractControl;
      control.enable();
      this.subscribeDirectorToValueChanges(control.value.id);
    } else {
      // if id is string (uuidv4) then delete it, otherwise disable it
      if (typeof id === 'string') {
        this.directorFormControl.removeAt(index);
      } else {
        this.getDirectorControl(index).get('isActive')?.setValue(false);
        this.getDirectorControl(index).disable();
      }
    }
    this.form.markAsDirty();
  }

  onAccountToggleChange(
    event: MatSlideToggleChange,
    index: number,
    id: number
  ): void {
    const isChecked = event.checked;

    if (isChecked) {
      this.getAccountControl(index).get('isActive')?.setValue(true);
      const control = this.accountFormControl.at(index) as AbstractControl;
      control.enable();
    } else {
      // if id is string (uuidv4) then delete it, otherwise disable it
      if (typeof id === 'string') {
        this.accountFormControl.removeAt(index);
      } else {
        this.getAccountControl(index).get('isActive')?.setValue(false);
        this.getAccountControl(index).disable();
      }
    }
    this.form.markAsDirty();
  }

  onCounterpartyToggleChange(
    event: MatSlideToggleChange,
    index: number,
    id: number
  ): void {
    const isChecked = event.checked;

    if (isChecked) {
      this.getCounterpartyControl(index).get('isActive')?.setValue(true);
      const control = this.counterpartyFormControl.at(index) as AbstractControl;
      control.enable();
    } else {
      // if id is string (uuidv4) then delete it, otherwise disable it
      if (typeof id === 'string') {
        this.counterpartyFormControl.removeAt(index);
      } else {
        this.getCounterpartyControl(index).get('isActive')?.setValue(false);
        this.getCounterpartyControl(index).disable();
      }
    }
    this.form.markAsDirty();
  }

  onGroupMemberToggleChange(
    event: MatSlideToggleChange,
    index: number,
    id: number
  ): void {
    const isChecked = event.checked;

    if (isChecked) {
      this.getGroupMemberControl(index).get('isActive')?.setValue(true);
      const control = this.groupMemberFormControl.at(index) as AbstractControl;
      control.enable();
    } else {
      // if id is string (uuidv4) then delete it, otherwise disable it
      if (typeof id === 'string') {
        this.groupMemberFormControl.removeAt(index);
      } else {
        this.getGroupMemberControl(index).get('isActive')?.setValue(false);
        this.getGroupMemberControl(index).disable();
      }
    }
    this.form.markAsDirty();
  }

  // Stakeholders
  get directorFormControl(): FormArray {
    return this.form.get('directorForm') as FormArray;
  }
  getDirectorControl(index: number): FormGroup {
    return this.directorFormControl.at(index) as FormGroup;
  }
  firstNameControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('firstName');
  }
  lastNameControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('lastName');
  }
  isShareholderControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isShareholder');
  }
  isUBOControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isUBO');
  }
  isAuthorizedPersonControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isAuthorizedPerson');
  }
  isDirectorControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isDirector');
  }
  public emailControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('email');
  }
  dateOfBirthControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('dateOfBirth');
  }
  nationalityControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('nationality');
  }
  // address
  addressGroup(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('address');
  }
  streetAddressControl(index: number): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('streetAddress');
  }
  additionalStreetAddressControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('additionalStreetAddress');
  }
  postCodeControl(index: number): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('postCode');
  }
  cityControl(index: number): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('city');
  }
  countryIdControl(index: number): AbstractControl | null | undefined {
    return this.addressGroup(index)?.get('countryId');
  }
  // natural person
  isTempAddressControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isTempAddress');
  }
  isPEPControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isPEP');
  }
  phoneNumberControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('phoneNumber');
  }
  taxNumberDirectorControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('taxNumber');
  }
  percentageOwnedByShareholderControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('percentageOwnedByShareholder');
  }
  percentageOwnedByUBOControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('percentageOwnedByUBO');
  }
  // legal person
  isLegalPersonControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('isLegalPerson');
  }
  legalPersonNameControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('legalPersonName');
  }
  legalPersonRegistrationNumberControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('legalPersonRegistrationNumber');
  }
  legalPersonIncorporationDateControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('legalPersonIncorporationDate');
  }
  // close links
  hasCloseLinksControl(index: number): AbstractControl | null {
    return this.getDirectorControl(index).get('hasCloseLinks');
  }

  // Bank/PI accounts
  get accountFormControl(): FormArray {
    return this.form.get('accountForm') as FormArray;
  }
  getAccountControl(index: number): FormGroup {
    return this.accountFormControl.at(index) as FormGroup;
  }
  accountNameControl(index: number): AbstractControl | null {
    return this.getAccountControl(index).get('name');
  }
  accountCountryControl(index: number): AbstractControl | null {
    return this.getAccountControl(index).get('country');
  }

  // Counterparties
  get counterpartyFormControl(): FormArray {
    return this.form.get('counterpartyForm') as FormArray;
  }
  getCounterpartyControl(index: number): FormGroup {
    return this.counterpartyFormControl.at(index) as FormGroup;
  }
  counterpartyNameControl(index: number): AbstractControl | null {
    return this.getCounterpartyControl(index).get('name');
  }
  counterpartyCountryControl(index: number): AbstractControl | null {
    return this.getCounterpartyControl(index).get('country');
  }
  counterpartyBusinessActivityControl(index: number): AbstractControl | null {
    return this.getCounterpartyControl(index).get('businessActivity');
  }

  // Group members
  get groupMemberFormControl(): FormArray {
    return this.form.get('groupMemberForm') as FormArray;
  }
  getGroupMemberControl(index: number): FormGroup {
    return this.groupMemberFormControl.at(index) as FormGroup;
  }
  groupMemberNameControl(index: number): AbstractControl | null {
    return this.getGroupMemberControl(index).get('name');
  }
  groupMemberRegistrationNumberControl(index: number): AbstractControl | null {
    return this.getGroupMemberControl(index).get('registrationNumber');
  }
  groupMemberIncorporationDateControl(index: number): AbstractControl | null {
    return this.getGroupMemberControl(index).get('incorporationDate');
  }
  // address
  groupMemberAddressGroup(index: number): AbstractControl | null {
    return this.getGroupMemberControl(index).get('address');
  }
  groupMemberStreetAddressControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('streetAddress');
  }
  groupMemberAdditionalStreetAddressControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('additionalStreetAddress');
  }
  groupMemberPostCodeControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('postCode');
  }
  groupMemberCityControl(index: number): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('city');
  }
  groupMemberCountryIdControl(
    index: number
  ): AbstractControl | null | undefined {
    return this.groupMemberAddressGroup(index)?.get('countryId');
  }

  get closeLinkFormControl(): FormArray {
    return this.form.get('closeLinkForm') as FormArray;
  }
  get entityFormControl(): FormGroup {
    return this.form.get('entityForm') as FormGroup;
  }
  get businessNameControl(): AbstractControl | null {
    return this.entityFormControl.get('businessName');
  }
  get entityEmailControl(): AbstractControl | null {
    return this.entityFormControl.get('email');
  }
  get cellPhoneNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('cellPhoneNumber');
  }
  get businessTradingNameControl(): AbstractControl | null {
    return this.entityFormControl.get('businessTradingName');
  }
  get taxNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('taxNumber');
  }
  get taxNumberNotAvailableControl(): AbstractControl | null {
    return this.entityFormControl.get('taxNumberNotAvailable');
  }
  get vatNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('vatNumber');
  }
  get vatNumberNotAvailableControl(): AbstractControl | null {
    return this.entityFormControl.get('vatNumberNotAvailable');
  }
  get taxAndVatCountryControl(): AbstractControl | null {
    return this.entityFormControl.get('taxAndVatCountry');
  }
  get companyUrlsControl(): AbstractControl | null {
    return this.entityFormControl.get('companyUrls');
  }
  get companyActivityControl(): AbstractControl | null {
    return this.entityFormControl.get('companyActivity');
  }
  get companyActivityDescrControl(): AbstractControl | null {
    return this.entityFormControl.get('companyActivityDescr');
  }
  get isRegulatedControl(): AbstractControl | null {
    return this.entityFormControl.get('isRegulated');
  }
  get regulatoryAuthorityControl(): AbstractControl | null {
    return this.entityFormControl.get('regulatoryAuthority');
  }
  get canFundFromOwnAccountControl(): AbstractControl | null {
    return this.entityFormControl.get('canFundFromOwnAccount');
  }
  get annualIncomeControl(): AbstractControl | null {
    return this.entityFormControl.get('annualIncome');
  }
  get accountPurposeControl(): AbstractControl | null {
    return this.entityFormControl.get('accountPurpose');
  }
  get monthlyLoadingControl(): AbstractControl | null {
    return this.entityFormControl.get('monthlyLoading');
  }
  get percentageOfCashControl(): AbstractControl | null {
    return this.entityFormControl.get('percentageOfCash');
  }
  get doubleAnnualIncomeControl(): AbstractControl | null {
    return this.entityFormControl.get('doubleAnnualIncome');
  }
  get zeroBalanceStatementControl(): AbstractControl | null {
    return this.entityFormControl.get('zeroBalanceStatement');
  }
  get legalStatusControl(): AbstractControl | null {
    return this.entityFormControl.get('legalStatus');
  }
  get otherLegalStatusControl(): AbstractControl | null {
    return this.entityFormControl.get('otherLegalStatus');
  }
  get operateInHighRiskCountriesControl(): AbstractControl | null {
    return this.entityFormControl.get('operateInHighRiskCountries');
  }
  get isFinancialStatementAuditedControl(): AbstractControl | null {
    return this.entityFormControl.get('isFinancialStatementAudited');
  }
  get expectedNumOfPhysicalCardsControl(): AbstractControl | null {
    return this.entityFormControl.get('expectedNumOfPhysicalCards');
  }
  get incorporationDateControl(): AbstractControl | null {
    return this.entityFormControl.get('incorporationDate');
  }
  get registrationNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('registrationNumber');
  }
  get additionalPhoneNumberControl(): AbstractControl | null {
    return this.entityFormControl.get('additionalPhoneNumber');
  }
  get isSameAddressControl(): AbstractControl | null {
    return this.entityFormControl.get('isSameAddress');
  }

  // incorporation address
  get incorporationAddressGroup(): AbstractControl | null {
    return this.entityFormControl.get('incorporationAddress');
  }
  get incorporationStreetAddressControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('streetAddress');
  }
  get incorporationAdditionalStreetAddressControl():
    | AbstractControl
    | null
    | undefined {
    return this.incorporationAddressGroup?.get('additionalStreetAddress');
  }
  get incorporationPostCodeControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('postCode');
  }
  get incorporationCityControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('city');
  }
  get incorporationCountryIdControl(): AbstractControl | null | undefined {
    return this.incorporationAddressGroup?.get('countryId');
  }

  // operating address
  get operatingAddressGroup(): AbstractControl | null {
    return this.entityFormControl.get('operatingAddress');
  }
  get operatingStreetAddressControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('streetAddress');
  }
  get operatingAdditionalStreetAddressControl():
    | AbstractControl
    | null
    | undefined {
    return this.operatingAddressGroup?.get('additionalStreetAddress');
  }
  get operatingPostCodeControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('postCode');
  }
  get operatingCityControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('city');
  }
  get operatingCountryIdControl(): AbstractControl | null | undefined {
    return this.operatingAddressGroup?.get('countryId');
  }

  private sharesValidator() {
    if (this.form && this.directorFormControl.value.length > 0) {
      const shares = this.directorFormControl?.value
        .filter((d: { isShareholder: any }) => d.isShareholder)
        .map(
          (s: { percentageOwnedByShareholder: any }) =>
            s.percentageOwnedByShareholder
        );
      if (shares.length > 0) {
        const sum = shares.reduce((sum: any, p: any) => sum + p, 0);
        if (sum > 100) {
          return { sharesMoreThan100: true };
        }
      }
    }
    return null;
  }

  private positionValidator() {
    if (this.form && this.directorFormControl.value.length > 0) {
      if (
        this.directorFormControl?.value.filter(
          (d: { isAuthorizedPerson: any }) => d.isAuthorizedPerson
        ).length === 1 &&
        this.directorFormControl?.value.filter(
          (d: { isDirector: any }) => d.isDirector
        ).length > 0 &&
        this.directorFormControl?.value.filter(
          (d: { isShareholder: any }) => d.isShareholder
        ).length > 0 &&
        this.directorFormControl?.value.filter((d: { isUBO: any }) => d.isUBO)
          .length > 0
      ) {
        return null;
      } else {
        return { atLeastOnePosition: true };
      }
    }
    return null;
  }

  private atLeastOneIncomingOutgoingTransaction() {
    if (this.form && this.counterpartyFormControl.length > 0) {
      if (
        this.counterpartyFormControl?.value.filter(
          (c: { isIncomingTransaction: boolean; isActive: boolean }) =>
            c.isIncomingTransaction && c.isActive
        ).length > 0 &&
        this.counterpartyFormControl?.value.filter(
          (c: { isOutgoingTransaction: boolean; isActive: boolean }) =>
            c.isOutgoingTransaction && c.isActive
        ).length > 0
      ) {
        this.counterpartyFormControl.setErrors(null);
        return null;
      } else {
        if (
          this.counterpartyFormControl?.value.filter(
            (c: { isIncomingTransaction: boolean; isActive: boolean }) =>
              c.isIncomingTransaction && c.isActive
          ).length === 0 &&
          this.counterpartyFormControl?.value.filter(
            (c: { isOutgoingTransaction: boolean; isActive: boolean }) =>
              c.isOutgoingTransaction && c.isActive
          ).length > 0
        ) {
          return { incomingTransactionRequired: true };
        }
        if (
          this.counterpartyFormControl?.value.filter(
            (c: { isOutgoingTransaction: boolean; isActive: boolean }) =>
              c.isOutgoingTransaction && c.isActive
          ).length === 0 &&
          this.counterpartyFormControl?.value.filter(
            (c: { isIncomingTransaction: boolean; isActive: boolean }) =>
              c.isIncomingTransaction && c.isActive
          ).length > 0
        ) {
          return { outgoingTransactionRequired: true };
        }
        if (
          this.counterpartyFormControl?.value.filter(
            (c: { isOutgoingTransaction: boolean; isActive: boolean }) =>
              c.isOutgoingTransaction && c.isActive
          ).length === 0 &&
          this.counterpartyFormControl?.value.filter(
            (c: { isIncomingTransaction: boolean; isActive: boolean }) =>
              c.isIncomingTransaction && c.isActive
          ).length === 0
        ) {
          return { atLeastOneCounterpartyIsRequired: true };
        }
      }
    }
    return null;
  }

  // TODO: adding documents
  // save() {
  //   this.isSaving = true;
  //   const combinedDirectors = [...this.directorFormControl.value];
  //Removes duplicates and id's from new directors
  // const uniqueDirectors: CompanyDirectorForm[] = combinedDirectors
  //   .filter(
  //     (value, index, a) => a.findIndex((t) => t.id === value.id) === index
  //   )
  //   .map((director) =>
  //     director.documents ? { ...director, id: null } : director
  //   );

  // const body: SaveRegisteredCompanyDirector[] = uniqueDirectors.map(
  //   (director) => ({
  //     id: director.id,
  //     firstName: director.firstName,
  //     lastName: director.lastName,
  //     isShareholder: director.isShareholder,
  //     isDirector: director.isDirector,
  //     documents: director.documents
  //       ? director.documents
  //           .filter((doc) => doc !== null)
  //           .map((doc) => {
  //             return {
  //               ...doc,
  //               expiryDate: director.expiryDate?.toISODate(),
  //             };
  //           })
  //       : [],
  //   })
  // );

  // if (this.updateByUser) {
  //   // company directors are updated by user/client
  //   this.userProfileService
  //     .updateCompanyDirectors(this.userId, body)
  //     .subscribe(
  //       () => {
  //         this.isSaving = false;
  //         this.clearFile.next();
  //         this.directorsControl.setValue(
  //           this.directorsControl.value.map((d: CompanyDirectorForm) =>
  //             d.expiryDate && d.documentType
  //               ? {
  //                   ...d,
  //                   expiryDate: '',
  //                   documentType: '',
  //                   documents: [],
  //                 }
  //               : d
  //           )
  //         );
  //         this.shareholdersControl.setValue(
  //           this.shareholdersControl.value.map((s: CompanyDirectorForm) =>
  //             s.expiryDate && s.documentType
  //               ? {
  //                   ...s,
  //                   expiryDate: '',
  //                   documentType: '',
  //                   documents: [],
  //                 }
  //               : s
  //           )
  //         );
  //         this.form.markAsPristine();
  //       },
  //       (error) => {
  //         this.isSaving = false;
  //         this.errorService.showErrorDialog(error.error.message);
  //         this.clearFile.next();
  //       }
  //     );
  // } else {
  //   this.registeredUsersService
  //   .saveCompanyDirectors(this.userId, body) // company directors are updated by admin
  //   .subscribe(
  //     () => {
  //       this.isSaving = false;
  //       this.clearFile.next();
  //       this.directorsControl.setValue(
  //         this.directorsControl.value.map((d: CompanyDirectorForm) =>
  //           d.expiryDate && d.documentType
  //             ? {
  //                 ...d,
  //                 expiryDate: '',
  //                 documentType: '',
  //                 documents: [],
  //               }
  //             : d
  //         )
  //       );
  //       this.shareholdersControl.setValue(
  //         this.shareholdersControl.value.map((s: CompanyDirectorForm) =>
  //           s.expiryDate && s.documentType
  //             ? {
  //                 ...s,
  //                 expiryDate: '',
  //                 documentType: '',
  //                 documents: [],
  //               }
  //             : s
  //         )
  //       );
  //       this.form.markAsPristine();
  //     },
  //     (error) => {
  //       this.isSaving = false;
  //       this.errorService.showErrorDialog(error.error.message);
  //       this.clearFile.next();
  //     }
  //   );
  // }
  // }

  // changeDocumentType(
  //   event: MatSelectChange,
  //   index: number,
  //   control: FormArray
  // ): void {
  //   control.value[index].documentType = event.value;
  //   this.checkFiles();
  // }

  // isPassport(documentType?: AppDocumentType): boolean {
  //   return documentType === AppDocumentType.PASSPORT;
  // }

  // fileLabel(
  //   documentType?: AppDocumentType,
  //   isSecondPage: boolean = false
  // ): string {
  //   switch (documentType) {
  //     case AppDocumentType.PASSPORT:
  //       return 'Passport page';
  //     case AppDocumentType.IDENTITY_CARD:
  //       return isSecondPage
  //         ? `2nd page of the ${this.displayedDocumentOptions.get(documentType)}`
  //         : `1st page of the ${this.displayedDocumentOptions.get(
  //             documentType
  //           )}`;
  //     default:
  //       return '';
  //   }
  // }

  // checkFiles() {
  //   const combined = [
  //     ...this.directorsControl.value,
  //     ...this.shareholdersControl.value,
  //   ];
  //   combined.map((director: CompanyDirectorForm) => {
  //     if (director.documents) {
  //       if (director.documentType !== AppDocumentType.PASSPORT) {
  //         this.areFilesUploaded =
  //           director.documents.filter((doc) => doc !== null).length === 3;
  //       } else {
  //         this.areFilesUploaded =
  //           director.documents[0] !== null && director.documents[2] !== null;
  //       }
  //     }
  //   });
  // }

  // onUploadFirstFile(file: File | null, formGroup: FormGroup) {
  //   const documents = formGroup.value.documents;
  //   if (file !== null) {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     this.registeredUsersService.userFiles(formData).subscribe((resp) => {
  //       documents[0] = {
  //         file: resp,
  //         expiryDate: '',
  //         type: formGroup.value.documentType,
  //       };
  //       formGroup.patchValue({ documents: documents });
  //       this.checkFiles();
  //     });
  //   } else {
  //     documents[0] = file;
  //     formGroup.patchValue({ documents: documents });
  //     this.checkFiles();
  //   }
  // }

  // onUploadSecondFile(file: File | null, formGroup: FormGroup) {
  //   const documents = formGroup.value.documents;

  //   if (file !== null) {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     this.registeredUsersService.userFiles(formData).subscribe((resp) => {
  //       var type; // set the correct type of document for second page
  //       if (documents[0].type === AppDocumentType.IDENTITY_CARD) {
  //         type = AppDocumentType.IDENTITY_CARD_SECOND_PAGE;
  //       } else {
  //         type = formGroup.value.documentType;
  //       }
  //       documents[1] = {
  //         file: resp,
  //         expiryDate: '',
  //         type: type,
  //       };
  //       formGroup.patchValue({ documents: documents });
  //       this.checkFiles();
  //     });
  //   } else {
  //     documents[1] = file;
  //     formGroup.patchValue({ documents: documents });
  //     this.checkFiles();
  //   }
  //   this.checkFiles();
  // }

  // onUploadThirdFile(file: File | null, formGroup: FormGroup) {
  //   const documents = formGroup.value.documents;

  //   if (file !== null) {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     this.registeredUsersService.userFiles(formData).subscribe((resp) => {
  //       documents[2] = {
  //         file: resp,
  //         expiryDate: '',
  //         type: AppDocumentType.PROOF_OF_ADDRESS,
  //       };
  //       formGroup.patchValue({ documents: documents });
  //       this.checkFiles();
  //     });
  //   } else {
  //     documents[2] = file;
  //     formGroup.patchValue({ documents: documents });
  //     this.checkFiles();
  //   }
  //   this.checkFiles();
  // }
}
