<app-onboarding-layout-new [steps]="steps">
  <h1 onboardingLayoutTitle data-testid="page-title-text">
    Submitted application
  </h1>

  <mat-card class="title">
    Your application will be reviewed shortly.<br />
    We will notify you here and by email when the verification will be
    completed.
  </mat-card>

  <!-- STEP 0 - Fill in the declaration form -->
  <app-expansion-panel-new [expanded]="false" name="Declaration Form">
    <div
      fxLayout="column"
      fxLayoutGap="0px"
      fxLayoutGap.lt-sm="8px"
      class="submitted-list"
    >
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          I am authorized and appointed by a Board of Directors Resolution to
          provide to eCREDO Limited all the necessary documentation for the
          opening of the account(s), to complete this application form and to
          carry out all communication with eCREDO Limited in relation to the
          opening of the account(s).
        </div>
        <div data-testid="business-authorized-by-bod-input" fxFlex="50">
          {{
            getStringValue(onboardingData.corporateForm?.applyOnBehalfOfCompany)
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          I am able to disclose company's Ultimate Beneficial Owners (UBOs) and
          demonstrate adequate transparency of ownership information.
        </div>
        <div data-testid="business-disclose-information-input" fxFlex="50">
          {{
            getStringValue(
              onboardingData.corporateForm?.ableDiscloseInformation
            )
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          I acknowledge that business activities of the entity do NOT include:
          Arms & Weapons, Binary Options Trading/Cryptocurrency, Greencard via
          Lotteries, Illegal downloads, Illegal Gambling, Illegal
          Pharmaceuticals, Illegal Tobacco sales, Pyramid and Ponzi Schemes,
          Timeshares, Unregistered Charities, Unregulated Financial Services,
          Shell banks and companies.
        </div>
        <div data-testid="business-activity-not-include-input" fxFlex="50">
          {{
            getStringValue(onboardingData.corporateForm?.activitiesNotInclude)
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          I declare that the information that will be provided in this
          application form, is to the best of my knowledge, true, accurate and
          complete. I understand that I need to immediately inform eCREDO
          Limited about any changes.
        </div>
        <div data-testid="business-correct-information-input" fxFlex="50">
          {{
            getStringValue(onboardingData.corporateForm?.isCorrectInformation)
          }}
        </div>
      </div>
    </div>
  </app-expansion-panel-new>

  <!-- STEP 4 - Fill in the form -->
  <app-expansion-panel-new [expanded]="false" name="Form">
    <div
      fxLayout="column"
      fxLayoutGap="0px"
      fxLayoutGap.lt-sm="8px"
      class="submitted-list"
    >
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Company legal name</div>
        <div data-testid="business-name-input" fxFlex="50">
          {{ onboardingData.corporateForm?.businessName }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Trading name (if applicable)</div>
        <div data-testid="business-trading-name-input" fxFlex="50">
          {{ onboardingData.corporateForm?.businessTradingName || '-' }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Company legal status</div>
        <div data-testid="business-legal-status-input" fxFlex="50">
          {{ onboardingData.corporateForm?.legalStatus }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Registration number</div>
        <div data-testid="registration-number-input" fxFlex="50">
          {{ onboardingData.corporateForm?.registrationNumber }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Date of Incorporation</div>
        <div data-testid="date-of-incorporation-input" fxFlex="50">
          {{ convertDate(onboardingData.corporateForm?.incorporationDate) }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Country of Incorporation</div>
        <div data-testid="country-of-incorporation-select" fxFlex="50">
          {{
            getCountryById(
              countries,
              onboardingData.corporateForm?.incorporationAddress?.countryId
            )
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Tax Identification Number</div>
        <div data-testid="business-tax-number-input" fxFlex="50">
          {{ onboardingData.corporateForm?.taxNumber }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">VAT Number</div>
        <div data-testid="business-vat-number-input" fxFlex="50">
          {{ onboardingData.corporateForm?.vatNumber }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Country of Tax Residency/VAT Number</div>
        <div data-testid="tax-vat-residency-country-select" fxFlex="50">
          {{
            getCountryById(
              countries,
              onboardingData.corporateForm?.taxAndVatCountry
            ) || '-'
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Company URLs (if applicable)</div>
        <div data-testid="business-urls-input" fxFlex="50">
          {{ onboardingData.corporateForm?.companyUrls || '-' }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Registered address</div>
        <div data-testid="street-address-input" fxFlex="50">
          {{
            onboardingData.corporateForm?.incorporationAddress?.streetAddress
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Registered address 2nd line</div>
        <div data-testid="street-address-second-part-input" fxFlex="50">
          {{
            onboardingData.corporateForm?.incorporationAddress
              ?.additionalStreetAddress || '-'
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Zip/Postal code</div>
        <div data-testid="post-code-input" fxFlex="50">
          {{ onboardingData.corporateForm?.incorporationAddress?.postCode }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">City</div>
        <div data-testid="city-input" fxFlex="50">
          {{ onboardingData.corporateForm?.incorporationAddress?.city }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Operating address</div>
        <div data-testid="operating-street-address-input" fxFlex="50">
          {{
            isSameAddress
              ? 'Same as Registered address'
              : onboardingData.corporateForm?.operatingAddress?.streetAddress
          }}
        </div>
      </div>
      <ng-container *ngIf="!isSameAddress">
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Operating address 2nd line</div>
          <div
            data-testid="operating-street-address-second-part-input"
            fxFlex="50"
          >
            {{
              onboardingData.corporateForm?.operatingAddress
                ?.additionalStreetAddress || '-'
            }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Zip/Postal code</div>
          <div data-testid="operating-post-code-input" fxFlex="50">
            {{ onboardingData.corporateForm?.operatingAddress?.postCode }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">City</div>
          <div data-testid="operating-city-input" fxFlex="50">
            {{ onboardingData.corporateForm?.operatingAddress?.city }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Operating address country</div>
          <div data-testid="operating-country-select" fxFlex="50">
            {{
              getCountryById(
                countries,
                onboardingData.corporateForm?.operatingAddress?.countryId
              )
            }}
          </div>
        </div>
      </ng-container>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Business activity</div>
        <div data-testid="business-activity-input" fxFlex="50">
          {{ onboardingData.corporateForm?.companyActivity }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          Please provide a brief description of the company's business
          activities
        </div>
        <div data-testid="business-activity-descr-input" fxFlex="50">
          {{ onboardingData.corporateForm?.companyActivityDescr }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          Annual income of the last year's financial statement
        </div>
        <div data-testid="business-annual-income-input" fxFlex="50">
          {{ onboardingData.corporateForm?.annualIncome | commify }} EUR
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          Is your annual income expected to at least double within the next 12
          months?
        </div>
        <div data-testid="business-double-annual-income-input" fxFlex="50">
          {{ getStringValue(onboardingData.corporateForm?.doubleAnnualIncome) }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          Percentage of cash transactions on total activities
        </div>
        <div data-testid="business-percentage-of-cash-input" fxFlex="50">
          {{ onboardingData.corporateForm?.percentageOfCash }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Are you regulated by any authority?</div>
        <div data-testid="business-is-regulated-input" fxFlex="50">
          {{ getStringValue(onboardingData.corporateForm?.isRegulated) }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
        *ngIf="onboardingData.corporateForm?.isRegulated"
      >
        <div fxFlex="50">Please specify which authority</div>
        <div data-testid="business-regulatory-authority-input" fxFlex="50">
          {{ onboardingData.corporateForm?.regulatoryAuthority || '-' }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Do you operate in any of the following countries:</div>
        <div
          data-testid="business-operate-in-high-risk-countries-input"
          fxFlex="50"
        >
          {{
            getStringValue(
              onboardingData.corporateForm?.operateInHighRiskCountries
            )
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          Are you able to initially fund your eCREDO account from an existing
          account at a bank registered and licensed in an EEA country?
        </div>
        <div data-testid="business-fund-from-own-account-input" fxFlex="50">
          {{
            getStringValue(onboardingData.corporateForm?.canFundFromOwnAccount)
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          Do you have financial statements (no older than two years) audited by
          a registered certified public accountant/auditor?
        </div>
        <div
          data-testid="business-audited-financial-statement-input"
          fxFlex="50"
        >
          {{
            getStringValue(
              onboardingData.corporateForm?.isFinancialStatementAudited
            )
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          Do your last year's financial statements contain zero balance in
          "Total assets" or "Total equities and liabilities" in the balance
          sheet?
        </div>
        <div data-testid="business-zero-balance-input" fxFlex="50">
          {{
            getStringValue(onboardingData.corporateForm?.zeroBalanceStatement)
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Account opening purpose</div>
        <div data-testid="business-opening-purpose-input" fxFlex="50">
          {{ onboardingData.corporateForm?.accountPurpose }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Expected monthly loading</div>
        <div data-testid="business-monthly-loading-input" fxFlex="50">
          {{ onboardingData.corporateForm?.monthlyLoading | commify }} EUR
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Expected number of physical cards required</div>
        <div data-testid="business-number-physical-cards-input" fxFlex="50">
          {{ onboardingData.corporateForm?.expectedNumOfPhysicalCards }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Additional phone number</div>
        <div data-testid="optional-phone-number-input" fxFlex="50">
          {{ onboardingData.corporateForm?.additionalPhoneNumber || '-' }}
        </div>
      </div>
    </div>
  </app-expansion-panel-new>

  <!-- STEP 6 - Company's Accounts -->
  <app-expansion-panel-new
    [expanded]="false"
    name="Company’s Accounts"
    class="step-6"
  >
    <div class="directors-number">
      {{ accounts.length }} Company’s
      <ng-container [ngSwitch]="accounts.length">
        <ng-container *ngSwitchCase="1">Account</ng-container>
        <ng-container *ngSwitchDefault>Accounts</ng-container>
      </ng-container>
    </div>
    <div *ngFor="let account of accounts; let i = index">
      <mat-divider></mat-divider>
      <h2 data-testid="account-summary-text">
        {{ i + 1 | ordinalNumber }} Bank/Payment Institution
      </h2>
      <div
        fxLayout="column"
        fxLayoutGap="0px"
        fxLayoutGap.lt-sm="8px"
        class="submitted-list"
      >
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Bank/Payment Institution Name</div>
          <div data-testid="account-name-input" fxFlex="50">
            {{ account.name }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Bank/Payment Institution Country</div>
          <div data-testid="account-country-select" fxFlex="50">
            {{ getCountryById(countries, account.country) }}
          </div>
        </div>
      </div>
    </div>
  </app-expansion-panel-new>

  <!--  STEP 5 - Company's Counterparties -->
  <app-expansion-panel-new
    [expanded]="false"
    name="Company’s Counterparties"
    class="step-5"
  >
    <div class="directors-number">
      {{ counterparties.length }} Company’s
      <ng-container [ngSwitch]="counterparties.length">
        <ng-container *ngSwitchCase="1">Counterparty</ng-container>
        <ng-container *ngSwitchDefault>Counterparties</ng-container>
      </ng-container>
    </div>
    <div *ngFor="let counterparty of counterparties; let i = index">
      <mat-divider></mat-divider>
      <h2 data-testid="counterparty-summary-text">
        {{ i + 1 | ordinalNumber }} Counterparty
      </h2>
      <div fxLayout="column" fxLayoutGap="0px" class="submitted-list">
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Name</div>
          <div data-testid="counterparty-name-input" fxFlex="50">
            {{ counterparty.name }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Country</div>
          <div data-testid="counterparty-country-select" fxFlex="50">
            {{ getCountryById(countries, counterparty.country) }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Business Activity</div>
          <div data-testid="counterparty-activity-input" fxFlex="50">
            {{ counterparty.businessActivity }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Incoming Transaction</div>
          <div data-testid="counterparty-incoming-tx-input" fxFlex="50">
            {{ getStringValue(counterparty.isIncomingTransaction) }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Outgoing Transaction</div>
          <div data-testid="counterparty-outgoing-tx-input" fxFlex="50">
            {{ getStringValue(counterparty.isOutgoingTransaction) }}
          </div>
        </div>
      </div>
    </div>
  </app-expansion-panel-new>

  <!-- STEP 1 - Upload the documents -->
  <app-expansion-panel-new
    [expanded]="false"
    name="Uploaded documents"
    class="step-1"
  >
    <div
      fxLayout="row wrap"
      fxLayoutGap="25px grid"
      fxLayoutAlign="space-between"
    >
      <app-file-input
        *ngFor="let control of documentsControls"
        [formControl]="control"
        [viewOnly]="true"
        fxFlex="50"
      ></app-file-input>
    </div>
  </app-expansion-panel-new>

  <!-- STEP 2 - Company's Stakeholders and Authorized Contact Person -->
  <app-expansion-panel-new
    [expanded]="false"
    name="Company’s Stakeholders and Authorized Contact Person"
    class="step-2"
  >
    <div class="directors-number">
      {{ directors.length }} Company’s
      <ng-container [ngSwitch]="directors.length">
        <ng-container *ngSwitchCase="1"
          >Stakeholder and Authorized Contact Person</ng-container
        >
        <ng-container *ngSwitchDefault
          >Stakeholders and Authorized Contact Person</ng-container
        >
      </ng-container>
    </div>
    <mat-divider></mat-divider>
    <div *ngFor="let director of directors; let i = index">
      <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header class="disable_ripple">
            <mat-panel-title fxLayoutAlign="space-between center">
              <h2
                data-testid="director-summary-text"
                *ngIf="!director.isLegalPerson"
              >
                {{ i + 1 | ordinalNumber }} (Natural) Person
              </h2>
              <h2
                data-testid="director-summary-text"
                *ngIf="director.isLegalPerson"
              >
                {{ i + 1 | ordinalNumber }} (Legal) Person
              </h2>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div
            fxLayout="column"
            fxLayoutGap="0px"
            fxLayoutGap.lt-sm="8px"
            class="submitted-list"
          >
            <ng-container *ngIf="!director.isLegalPerson">
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">First Name</div>
                <div data-testid="director-first-name-input" fxFlex="50">
                  {{ director.firstName }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Last Name</div>
                <div data-testid="director-last-name-input" fxFlex="50">
                  {{ director.lastName }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Date of Birth</div>
                <div data-testid="director-dob-input" fxFlex="50">
                  {{ convertDate(director.dateOfBirth) }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Nationality</div>
                <div data-testid="director-nationality-input" fxFlex="50">
                  {{ getNationalityById(countries, director.nationality) }}
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="director.isLegalPerson">
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Legal person name</div>
                <div data-testid="director-legal-person-name-input" fxFlex="50">
                  {{ director.legalPersonName }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Registration number</div>
                <div
                  data-testid="director-legal-person-registration-number-input"
                  fxFlex="50"
                >
                  {{ director.legalPersonRegistrationNumber }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Date of Incorporation</div>
                <div
                  data-testid="director-legal-person-incorporation-date-input"
                  fxFlex="50"
                >
                  {{ convertDate(director.legalPersonIncorporationDate) }}
                </div>
              </div>
            </ng-container>

            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">Email</div>
              <div data-testid="director-email-input" fxFlex="50">
                {{ director.email }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div *ngIf="director.isLegalPerson" fxFlex="50">
                Operating address
              </div>
              <div *ngIf="!director.isLegalPerson" fxFlex="50">
                Residential address
              </div>

              <div data-testid="director-address-input" fxFlex="50">
                {{ director.address?.streetAddress }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div *ngIf="director.isLegalPerson" fxFlex="50">
                Operating address 2nd line
              </div>
              <div *ngIf="!director.isLegalPerson" fxFlex="50">
                Residential address 2nd line
              </div>
              <div data-testid="director-address-2nd-input" fxFlex="50">
                {{ director.address?.additionalStreetAddress || '-' }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">Zip/Postal code</div>
              <div data-testid="director-postcode-input" fxFlex="50">
                {{ director.address?.postCode }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">City</div>
              <div data-testid="director-city-input" fxFlex="50">
                {{ director.address?.city }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">Country</div>
              <div data-testid="director-country-select" fxFlex="50">
                {{
                  getCountryById(countries, director.address?.countryId)
                    | titlecase
                }}
              </div>
            </div>
            <div
              *ngIf="!director.isLegalPerson"
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">
                The address above is my temporary residence in EEA
              </div>
              <div data-testid="director-is-temp-address-input" fxFlex="50">
                {{ getStringValue(director.isTempAddress) }}
              </div>
            </div>

            <div
              *ngIf="!director.isLegalPerson"
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">Phone number</div>
              <div data-testid="director-phone-number-input" fxFlex="50">
                {{ director.phoneNumber }}
              </div>
            </div>

            <div
              *ngIf="!director.isLegalPerson"
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">Authorized contact person</div>
              <div data-testid="director-authorized-person-input" fxFlex="50">
                {{ getStringValue(director.isAuthorizedPerson) }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">Director</div>
              <div data-testid="director-director-input" fxFlex="50">
                {{ getStringValue(director.isDirector) }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">Shareholder</div>
              <div data-testid="director-shareholder-input" fxFlex="50">
                {{ getStringValue(director.isShareholder) }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
              *ngIf="director.isShareholder"
            >
              <div fxFlex="50">Percentage of shares owned by shareholder</div>
              <div data-testid="director-shareholder-shares-input" fxFlex="50">
                {{ director.percentageOwnedByShareholder }}%
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
            >
              <div fxFlex="50">UBO</div>
              <div data-testid="director-ubo-input" fxFlex="50">
                {{ getStringValue(director.isUBO) }}
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="20px grid"
              fxLayout.lt-sm="column"
              fxLayoutGap.lt-sm="4px grid"
              *ngIf="director.isUBO"
            >
              <div fxFlex="50">Percentage of shares owned by UBO</div>
              <div data-testid="director-ubo-shares-input" fxFlex="50">
                {{ director.percentageOwnedByUBO }}%
              </div>
            </div>

            <ng-container *ngIf="!director.isLegalPerson">
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Tax Identification Number</div>
                <div data-testid="director-tax-number-input" fxFlex="50">
                  {{ director.taxNumber }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Country of Tax Residency/VAT Number</div>
                <div data-testid="director-tax-country-select" fxFlex="50">
                  {{ getCountryById(countries, director.taxResidencyCountry) }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">
                  Are you (or have you been in the 12 previous months) a PEP or
                  a close family member (spouse, children, parents) or a close
                  associate (any business relations) of a PEP?
                </div>
                <div data-testid="director-pep-input" fxFlex="50">
                  {{ getStringValue(director.isPEP) }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Verification complete</div>
                <div data-testid="director-verification-done" fxFlex="50">
                  {{ getStringValue(director.onboardingVerificationDone) }}
                </div>
              </div>
            </ng-container>

            <div
              fxLayout="row wrap"
              fxLayoutGap="25px grid"
              fxLayoutAlign="space-between"
            >
              <app-file-input
                *ngFor="
                  let control of getDirectorDocumentsControls(director.id)
                "
                [formControl]="control"
                [viewOnly]="true"
                fxFlex="50"
              ></app-file-input>
            </div>

            <ng-container
              *ngFor="let item of getDirectorCloseLinks(director.id)"
              ><span class="small-title">Close link</span><br />
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Legal entity name</div>
                <div data-testid="close-link-name-input" fxFlex="50">
                  {{ item?.name }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Registration number</div>
                <div
                  data-testid="close-link-registration-number-input"
                  fxFlex="50"
                >
                  {{ item?.registrationNumber }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Date of Incorporation</div>
                <div
                  data-testid="close-link-incorporation-date-input"
                  fxFlex="50"
                >
                  {{ item?.incorporationDate }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Registered address</div>
                <div data-testid="close-link-address-input" fxFlex="50">
                  {{ item?.streetAddress }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Registered address 2nd line</div>
                <div data-testid="close-link-address-2nd-input" fxFlex="50">
                  {{ item?.additionalStreetAddress || '-' }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Zip/Postal code</div>
                <div data-testid="close-link-postcode-input" fxFlex="50">
                  {{ item?.postcode }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">City</div>
                <div data-testid="close-link-city-input" fxFlex="50">
                  {{ item?.city }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Country</div>
                <div data-testid="close-link-country-select" fxFlex="50">
                  {{ item?.country }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Director</div>
                <div data-testid="close-link-director-input" fxFlex="50">
                  {{ getStringValue(item?.isDirector) }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Shareholder</div>
                <div data-testid="close-link-shareholder-input" fxFlex="50">
                  {{ getStringValue(item?.isShareholder) }}
                </div>
              </div>
              <div
                *ngIf="item.isShareholder"
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Percentage owned by shareholder</div>
                <div
                  data-testid="close-link-shareholder-shares-input"
                  fxFlex="50"
                >
                  {{ item?.percentageOwnedByShareholder }}%
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">UBO</div>
                <div data-testid="close-link-ubo-input" fxFlex="50">
                  {{ getStringValue(item?.isUBO) }}
                </div>
              </div>
              <div
                *ngIf="item.isUBO"
                fxLayout="row"
                fxLayoutGap="20px grid"
                fxLayout.lt-sm="column"
                fxLayoutGap.lt-sm="4px grid"
              >
                <div fxFlex="50">Percentage owned by UBO</div>
                <div data-testid="close-link-ubo-shares-input" fxFlex="50">
                  {{ item?.percentageOwnedByUBO }}%
                </div>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-divider></mat-divider>
    </div>
  </app-expansion-panel-new>

  <!--  STEP 6 - Company's Group Members -->
  <app-expansion-panel-new
    [expanded]="false"
    name="Company’s Group Members"
    class="step-5"
  >
    <div class="directors-number">
      {{ groupMembers.length }} Company’s
      <ng-container [ngSwitch]="groupMembers.length">
        <ng-container *ngSwitchCase="1">Group Member</ng-container>
        <ng-container *ngSwitchDefault>Group Members</ng-container>
      </ng-container>
    </div>
    <div *ngFor="let groupMember of groupMembers; let i = index">
      <mat-divider></mat-divider>
      <h2 data-testid="group-member-summary-text">
        {{ i + 1 | ordinalNumber }} Group Member
      </h2>
      <div fxLayout="column" fxLayoutGap="0px" class="submitted-list">
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Company name</div>
          <div data-testid="group-member-name-input" fxFlex="50">
            {{ groupMember.name }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Registration number</div>
          <div data-testid="group-member-registration-number-input" fxFlex="50">
            {{ groupMember?.registrationNumber }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Date of Incorporation</div>
          <div data-testid="group-member-incorporation-date-input" fxFlex="50">
            {{ convertDate(groupMember?.incorporationDate) }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Registered address</div>
          <div data-testid="group-member-address-input" fxFlex="50">
            {{ groupMember?.address?.streetAddress }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Registered address 2nd line</div>
          <div data-testid="group-member-address-2nd-input" fxFlex="50">
            {{ groupMember?.address?.additionalStreetAddress || '-' }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Zip/Postal code</div>
          <div data-testid="group-member-postcode-input" fxFlex="50">
            {{ groupMember?.address?.postCode }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">City</div>
          <div data-testid="group-member-city-input" fxFlex="50">
            {{ groupMember?.address?.city }}
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Country</div>
          <div data-testid="group-member-country-select" fxFlex="50">
            {{ getCountryById(countries, groupMember?.address?.countryId) }}
          </div>
        </div>
      </div>
    </div>
  </app-expansion-panel-new>
</app-onboarding-layout-new>
