<div [formGroup]="counterpartyForm" class="main">
  <ng-container>
    <div fxLayoutAlign="start center" class="header">
      <h2>{{ index + 1 | ordinalNumber }} Counterparty</h2>
      <ng-container
        *ngTemplateOutlet="removeButton; context: { $implicit: counterparty }"
      ></ng-container>
    </div>

    <!-- COUNTERPARTY NAME -->
    <app-onboarding-form-item-new
      label="Name"
      [isReviewed]="isReviewed"
      [value]="counterparty.name"
      [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_COUNTERPARTY_NAME)"
    >
      <div fxLayout="column" class="field">
        <label for="name">Name</label>
        <mat-form-field appearance="outline">
          <input matInput name="name" formControlName="name" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('pattern')"
            >Forbidden character used</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('maxlength')"
            >Name can’t be longer than 64 characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- COUNTERPARTY COUNTRY -->
    <app-onboarding-form-item-new
      label="Country"
      [isReviewed]="isReviewed"
      [value]="getCountryById(countries, counterparty.country)"
      [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_COUNTERPARTY_COUNTRY)"
    >
      <div fxLayout="column" class="field">
        <label for="country">Country</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            name="country"
            formControlName="country"
          >
            <mat-option
              *ngFor="let country of countries"
              [value]="country.id"
              >{{ country.name | titlecase }}</mat-option
            >
          </mat-select>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="countryControl?.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- COUNTERPARTY BUSINESS ACTIVITY -->
    <app-onboarding-form-item-new
      label="Business Activity"
      [isReviewed]="isReviewed"
      [value]="counterparty.businessActivity"
      [field]="
        fieldsMap.get(OnboardingFieldId.BUSINESS_COUNTERPARTY_BUSINESS_ACTIVITY)
      "
    >
      <div fxLayout="column" class="field">
        <label for="businessActivity">Business Activity</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            fxLayout="column"
            fxLayoutGap="14px"
            name="businessActivity"
            formControlName="businessActivity"
          >
            <mat-option
              style="
                height: auto;
                white-space: normal;
                line-height: initial;
                padding: 10px;
              "
              *ngFor="let occupation of occupations"
              [value]="occupation[1]"
              >{{ occupation[0] }}</mat-option
            >
          </mat-select>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="businessActivityControl?.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- INCOMING / OUTGOING TRANSACTION -->
    <!-- Show the following when isReviewed and comment does not exist -->
    <ng-container
      *ngIf="
        isReviewed &&
        !fieldsMap.get(
          OnboardingFieldId.BUSINESS_COUNTERPARTY_INCOMING_TRANSACTION
        )?.comment
      "
    >
      <div fxLayout="row" fxLayoutGap="20px grid" class="submitted">
        <div fxFlex="50">Incoming Transaction</div>
        <div fxFlex="50" class="value">
          {{ getStringValue(counterparty.isIncomingTransaction) }}
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="20px grid" class="submitted">
        <div fxFlex="50">Outgoing Transaction</div>
        <div fxFlex="50" class="value">
          {{ getStringValue(counterparty.isOutgoingTransaction) }}
        </div>
      </div>
    </ng-container>

    <!-- Show the following when isReviewed and comment exists -->
    <app-onboarding-form-item-new
      label=""
      [isReviewed]="isReviewed"
      [value]=""
      [field]="
        fieldsMap.get(
          OnboardingFieldId.BUSINESS_COUNTERPARTY_INCOMING_TRANSACTION
        )
      "
    >
      <div fxLayout="column" class="field">
        <mat-checkbox
          name="isIncomingTransaction"
          formControlName="isIncomingTransaction"
        >
          Incoming Transaction
        </mat-checkbox>
      </div>
      <div fxLayout="column" class="field">
        <mat-checkbox
          name="isOutgoingTransaction"
          formControlName="isOutgoingTransaction"
        >
          Outgoing Transaction
        </mat-checkbox>
      </div>
    </app-onboarding-form-item-new>
    <mat-error *ngIf="counterpartyForm?.hasError('atLeastOneCheckboxChecked')"
      >At least one option is required to be selected</mat-error
    >
  </ng-container>
</div>

<ng-template #removeButton>
  <button
    *ngIf="!isReviewed"
    mat-icon-button
    (click)="removeCounterparty(counterparty)"
    [disabled]="isRemoving"
    [appButtonLoader]="isRemoving"
  >
    <mat-icon svgIcon="cancel"></mat-icon>
  </button>
</ng-template>
