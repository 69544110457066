import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { first } from 'rxjs/operators';
import { OnboardingService } from 'src/app/onboarding-new/onboarding.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  VerificationDialogComponent,
  VerificationDialogData,
} from 'src/app/onboarding-new/components/verification-dialog/verification-dialog.component';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateTime } from 'luxon';
import { KeyPersonVerificationData } from './models/key-person-verification-data.model';
import { AppDocument } from 'src/app/onboarding-new/models/document.model';
import { EventType } from 'src/app/onboarding-new/models/event-type.enum';
import { AppDocumentType } from 'src/app/onboarding-new/models/document-type.enum';
import { VerificationResponse } from './models/verification-response.model';
import { environment } from 'src/environments/environment';
import { firstOrLastNameValidator } from 'src/app/shared/helpers/various-helpers.helper';

@Component({
  templateUrl: './key-person-verification.component.html',
  styleUrls: ['./key-person-verification.component.scss'],
})
export class KeyPersonVerificationComponent implements OnInit {
  isLoading: boolean = false;
  isSaving: boolean = false;
  isGettingVerificationUrl: boolean = false;
  dialogRef!: MatDialogRef<VerificationDialogComponent>;
  referenceId!: string;

  form!: FormGroup;
  verificationToken!: string;
  verificationAccepted: boolean = false;
  documents: AppDocument[] = [];

  today: Date = new Date();
  readonly minAge = 18;
  minDob: Date = new Date(
    this.today.getFullYear() - this.minAge,
    this.today.getMonth(),
    this.today.getDate()
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
    private onboardingService: OnboardingService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.queryParams
      .pipe(first())
      .subscribe(async ({ token: verificationToken }) => {
        this.verificationToken = verificationToken;
        this.onboardingService
          .getKeyPersonVerificationForm(verificationToken)
          .subscribe(
            (form) => {
              this.documents = form.documents;
              this.form = this.fb.group({
                firstName: [
                  form.firstName,
                  [Validators.required, ...firstOrLastNameValidator],
                ],
                lastName: [
                  form.lastName,
                  [Validators.required, ...firstOrLastNameValidator],
                ],
                dateOfBirth: [
                  form.dateOfBirth ? DateTime.fromISO(form.dateOfBirth) : null,
                  Validators.required,
                ],
              });
              // show that verification was done only if id docs exist (in case documents failed to be saved)
              if (
                this.identityDocsUploaded(form.documents) &&
                form.onboardingVerificationDone
              ) {
                this.verificationAccepted = true;
                this.disableFormFields();
              }
              this.isLoading = false;
            },
            (err) => {
              this.isLoading = false;
              if (err.status === 400) {
                this.router.navigate(['login'], {
                  state: { isVerificationProblem: true },
                });
              } else {
                this.errorService.showErrorDialog();
              }
            }
          );
      });
  }

  async updateKeyPersonVerificationForm(): Promise<void> {
    this.isSaving = true;
    const body: KeyPersonVerificationData = {
      verificationToken: this.verificationToken,
      firstName: this.firstNameControl?.value,
      lastName: this.lastNameControl?.value,
      dateOfBirth: (this.dateOfBirthControl?.value as DateTime).toISODate(),
    };
    this.onboardingService.updateKeyPersonVerificationForm(body).subscribe(
      () => {
        this.form.markAsPristine();
        this.isSaving = false;
      },
      (error) => {
        this.isSaving = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  disableFormFields() {
    // disable fields that were cross-verified with the documents
    this.firstNameControl?.disable();
    this.lastNameControl?.disable();
    this.dateOfBirthControl?.disable();
  }

  @HostListener('window:message', ['$event'])
  async onMessage(event: MessageEvent) {
    if (event.origin === environment.SP_URL) {
      const verificationStatus = event.data.verification_status;
      if (verificationStatus === EventType.VERIFICATION_ACCEPTED) {
        const body: VerificationResponse = {
          requestId: event.data.request_id,
          verificationStatus: event.data.verification_status,
          referenceId: this.referenceId,
          verificationToken: this.verificationToken,
        };
        (
          await this.onboardingService.getKeyPersonVerificationResponse(body)
        ).subscribe(
          (res: { documents: AppDocument[] }) => {
            if (this.dialogRef) {
              // get the documents from backend and show that they were uploaded (dont show extra info e.g. expiry date)
              this.documents = res.documents;
              // show that verification was done only if id docs exist (in case documents failed to be saved)
              if (this.identityDocsUploaded(res.documents)) {
                this.verificationAccepted = true;
                // disable the form because if verification is succesful user to not change data
                this.disableFormFields();
              }
              // show the close button to close the dialog
              this.dialogRef.componentInstance.updateCloseButtonVisibility(
                true
              );
            }
          },
          () => {
            this.errorService.showErrorDialog();
          }
        );
      } else if (
        verificationStatus === EventType.VERIFICATION_DECLINED ||
        verificationStatus === EventType.VERIFICATION_CANCELLED
      ) {
        // show the close button to close the dialog
        this.dialogRef.componentInstance.updateCloseButtonVisibility(true);
      }
    }
  }

  identityDocsUploaded(documents: AppDocument[]) {
    const hasDocumentTypes = (
      types: AppDocumentType[],
      documents: AppDocument[]
    ) =>
      types.every((type) =>
        documents.some((obj) => obj.type === type && obj.isAccepted)
      );
    const hasDocs1 = hasDocumentTypes(
      [
        AppDocumentType.IDENTITY_CARD,
        AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
      ],
      documents
    );
    const hasDocs2 = hasDocumentTypes([AppDocumentType.PASSPORT], documents);
    return hasDocs1 || hasDocs2;
  }

  async getVerificationUrl(): Promise<void> {
    this.isGettingVerificationUrl = true;
    this.onboardingService
      .getKeyPersonVerificationUrl(this.verificationToken)
      .subscribe(
        (res) => {
          this.isGettingVerificationUrl = false;
          // open dialog to not allow data changes
          this.referenceId = res.reference_id;
          this.dialogRef = this.dialog.open<
            VerificationDialogComponent,
            VerificationDialogData
          >(VerificationDialogComponent, {
            width: '600px',
            height: '700px',
            disableClose: true,
            data: {
              src: res.verification_url,
            },
          });
        },
        () => {
          this.isGettingVerificationUrl = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  get firstNameControl(): AbstractControl | null {
    return this.form.get('firstName');
  }

  get lastNameControl(): AbstractControl | null {
    return this.form.get('lastName');
  }

  get dateOfBirthControl(): AbstractControl | null {
    return this.form.get('dateOfBirth');
  }
}
