<app-base-login-page title="Mobile number">
  <app-big-loader *ngIf="isLoading"></app-big-loader>
  <app-login-card *ngIf="!isLoading" imgName="phone.svg">
    <h2>Email verified</h2>
    <div class="text">
      Now let’s connect your account with your mobile phone. <br />
      Select the country code and enter your phone number (without re-entering
      the country code).
    </div>
    <ng-container [formGroup]="phoneForm">
      <div class="mobile-number">
        <label for="phoneNumber">Mobile number</label>
        <ngx-intl-tel-input
          [enablePlaceholder]="false"
          [searchCountryFlag]="true"
          [separateDialCode]="true"
          [selectedCountryISO]="countryCode"
          data-testid="phone-number-container"
          name="phoneNumber"
          formControlName="phoneNumber"
        >
        </ngx-intl-tel-input>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="phoneNumberControl?.invalid && phoneNumberControl?.touched"
        >
          {{
            phoneNumberControl?.hasError('unacceptableData')
              ? 'Data is not accepted. Please contact
          support@ecredo.com'
              : 'Number is invalid'
          }}
        </mat-error>
      </div>
      <div class="text desc">
        The provided phone number will be used for receiving one-time passwords
        (OTPs) for login and transaction approvals
      </div>
      <button
        *ngIf="!smsSent"
        mat-flat-button
        color="accent"
        [disabled]="phoneForm.invalid || isSending"
        [appButtonLoader]="isSending"
        class="send-sms"
        data-testid="send-sms-button"
        fxFlexAlign="center"
        (click)="sendSms()"
      >
        Send SMS
      </button>
    </ng-container>
    <ng-container *ngIf="smsSent">
      <mat-divider class="with-margin"></mat-divider>
      <div class="text">
        We’ve just sent you a verification SMS. Please
        <strong>check your mobile phone now for new messages</strong> and insert
        the verification code here within the next five minutes.
      </div>
      <div
        [formGroup]="verificationForm"
        fxLayoutAlign="space-between start"
        class="verification"
      >
        <div fxLayout="column">
          <label for="otp">Verification code</label>
          <mat-form-field appearance="outline">
            <input
              data-testid="verification-code-input"
              matInput
              name="otp"
              formControlName="otp"
            />
            <mat-error
              *ngIf="otpControl?.hasError('wrongCode')"
              data-testid="validation-message-box"
              >Wrong code</mat-error
            >
          </mat-form-field>
        </div>
        <button
          mat-flat-button
          color="accent"
          class="verify"
          data-testid="verify-button"
          [disabled]="verificationForm.invalid || isVerifying"
          [appButtonLoader]="isVerifying"
          (click)="verify()"
        >
          Verify
        </button>
      </div>
      <mat-divider></mat-divider>
      <div class="send-sms">
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          data-testid="send-sms-again-button"
          [disabled]="
            phoneForm.invalid ||
            isSendingAgain ||
            isSendAgainDisabled ||
            noMoreResendsAllowed
          "
          [appButtonLoader]="isSendingAgain"
          (click)="sendSms(true)"
        >
          Send SMS again
          <span *ngIf="isSendAgainDisabled">({{ countdown }}s)</span>
        </button>
        <img
          *ngIf="smsSentAgain && !noMoreResendsAllowed"
          src="assets/img/accept.svg"
          alt="sent"
          class="sent-again"
        />
        <mat-icon
          *ngIf="noMoreResendsAllowed"
          svgIcon="cancel"
          class="sent-again cancel"
        ></mat-icon>

        <div *ngIf="noMoreResendsAllowed" class="error">
          You have reached the maximum number of allowed SMS resend attempts.
          Please refresh the page and try again.
        </div>
      </div>
    </ng-container>
  </app-login-card>
</app-base-login-page>
