import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { CompanyDirectorWithDocuments } from '../../models/company-director.model';
import { OnboardingService } from '../../onboarding.service';
import { DateTime } from 'luxon';
import { Country } from 'src/app/shared/models/country.model';
import { CompanyCloseLink } from '../../models/company-close-link.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  addressLineValidator,
  fullNameValidator,
  getCountryById,
  getNationalityById,
  getStringValue,
  firstOrLastNameValidator,
  postCodeCityValidator,
  percentageValidators,
} from 'src/app/shared/helpers/various-helpers.helper';
import { CompanyCloseLinkSelectDialogComponent } from '../company-close-link-select-dialog/company-close-link-select-dialog.component';
import { CompanyDirectorCloseLink } from '../../models/company-director-close-link.model';
import { CountryISO } from 'ngx-intl-tel-input';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { OnboardingField } from '../../models/onboarding-field.model';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import {
  VerificationDialogComponent,
  VerificationDialogData,
} from '../../components/verification-dialog/verification-dialog.component';
import { AppDocumentType } from '../../models/document-type.enum';

export const atLeastOneCheckboxIsChecked = () => {
  return (group: FormGroup) => {
    if (
      group.value?.isAuthorizedPerson === false &&
      group.value?.isDirector === false &&
      group.value?.isUBO === false &&
      group.value?.isShareholder === false &&
      group.touched
    ) {
      return { atLeastOneCheckboxChecked: true };
    } else return null;
  };
};

@Component({
  selector: 'app-director-shareholder-form-new',
  templateUrl: './director-shareholder-form.component.html',
  styleUrls: ['./director-shareholder-form.component.scss'],
})
export class DirectorShareholderFormNewComponent implements OnInit, OnDestroy {
  @Input() index: number = 0; // used for numbering the components
  @Input() countries: Country[] = [];
  @Input() fields: OnboardingField[] = [];
  @Input() isReviewed: boolean = false;
  @Input() isEDD!: boolean;
  @Input() isFirstTimeEDD!: boolean;
  @Input() directorIndex: number = 0; // used for case e.g. 2nd Shareholder is 1st Director
  @Input() set director(val: CompanyDirectorWithDocuments) {
    this._director = val;

    // mark required different fields for legal person
    if (this.director.isLegalPerson) {
      this.directorForm
        .get('isLegalPerson')
        ?.setValidators(Validators.requiredTrue);
      this.directorForm
        .get('legalPersonName')
        ?.setValidators([Validators.required, ...fullNameValidator]);
      this.directorForm
        .get('legalPersonRegistrationNumber')
        ?.setValidators([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
          Validators.maxLength(50),
        ]);
      this.directorForm
        .get('legalPersonIncorporationDate')
        ?.setValidators([Validators.required]);
    } else {
      this.directorForm
        .get('firstName')
        ?.setValidators([Validators.required, ...firstOrLastNameValidator]);
      this.directorForm
        .get('lastName')
        ?.setValidators([Validators.required, ...firstOrLastNameValidator]);
      this.directorForm.get('dateOfBirth')?.setValidators(Validators.required);
      this.directorForm.get('nationality')?.setValidators(Validators.required);
      this.directorForm.get('isPEP')?.setValidators(Validators.required);
      this.directorForm
        .get('phoneNumber')
        ?.setValidators([Validators.required, phoneValidator()]);
      this.directorForm
        .get('taxNumber')
        ?.setValidators([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
          Validators.maxLength(50),
        ]);
    }
    // don't ask close links from only authorized contact persons
    if (this.isFirstTimeEDD && !this.isOnlyAuthorizedPerson()) {
      this.directorForm
        .get('hasCloseLinks')
        ?.setValidators([Validators.required]);
    }

    let { documents, ...temp } = val;
    const formValue = {
      ...temp,
      dateOfBirth: val.dateOfBirth ? DateTime.fromISO(val.dateOfBirth) : null,
      legalPersonIncorporationDate: val?.legalPersonIncorporationDate
        ? DateTime.fromISO(val?.legalPersonIncorporationDate)
        : null,
    };

    if (JSON.stringify(formValue) !== JSON.stringify(this.directorForm.value)) {
      this.directorForm.patchValue(formValue, { emitEvent: false });
    }
    // ensure stakeholders (except only authorized contact person) have uploaded proof of address and were accepted at first stage of onboarding
    const doc = this.director.documents.find(
      (d) => d.type === AppDocumentType.PROOF_OF_ADDRESS
    );
    if (
      (((this.director.isLegalPerson &&
        this.director.onboardingVerificationDone) ||
        !this.director.isLegalPerson) &&
        doc !== undefined &&
        doc.isAccepted) ||
      this.isOnlyAuthorizedPerson()
    ) {
      this.verificationAccepted = true;
      // don't disable form fields for only authorized contact person with no uploaded documents
      if (
        !(this.isOnlyAuthorizedPerson() && this.director.documents.length === 0)
      ) {
        this.disableFormFields();
      }
    } else {
      // natural person might change position (e.g. director) so value needs to reset
      this.verificationAccepted = false;
    }
    if (this.directorForm.valid) {
      this.inputsComplete = true;
    }
    // fieldsMap
    this.fieldsMap.clear();
    this.fields
      ?.filter((field) => !!field.comment && field.directorId === val.id)
      .forEach((field) => {
        let commentedValue = field.commentedValue;
        if (
          field.id.endsWith('.countryId') ||
          field.id.endsWith('.taxResidencyCountry')
        ) {
          // converts countryId to country name
          commentedValue = this.getCountryById(
            this.countries,
            parseInt(commentedValue)
          );
        }
        if (field.id.endsWith('.nationality')) {
          // converts nationality to nationality name
          commentedValue = this.getNationalityById(
            this.countries,
            parseInt(commentedValue)
          );
        }
        if (field.id.includes('.percentage')) {
          // add % to percentages
          if (commentedValue) commentedValue += '%';
        }
        // convert boolean
        if (commentedValue === 'true') {
          commentedValue = 'Yes';
        } else if (commentedValue === 'false') {
          commentedValue = 'No';
        }
        // converts date
        if (field.id.endsWith('.legalPersonIncorporationDate')) {
          commentedValue = DateTime.fromISO(commentedValue).toLocaleString(
            DateTime.DATE_MED
          );
        }
        this.fieldsMap.set(field.id, {
          comment: field.comment,
          commentedValue,
        });
      });
  }
  get director(): CompanyDirectorWithDocuments {
    return this._director;
  }
  @Input() set closeLinks(val: CompanyCloseLink[]) {
    this._closeLinks = val;
  }
  get closeLinks(): CompanyCloseLink[] {
    return this._closeLinks;
  }
  @Input() set directorCloseLinks(val: CompanyDirectorCloseLink[]) {
    this._directorCloseLinks = val;
  }
  get directorCloseLinks(): CompanyDirectorCloseLink[] {
    return this._directorCloseLinks;
  }
  @Output() directorChange = new EventEmitter<CompanyDirectorWithDocuments>();
  @Output() directorRemove = new EventEmitter<number>(); // emits director id
  @Output() isComplete = new EventEmitter<boolean>();
  @Output() companyCloseLinksChange = new EventEmitter<CompanyCloseLink[]>();
  @Output() companyDirectorCloseLinksChange = new EventEmitter<
    CompanyDirectorCloseLink[]
  >();
  @Output() referenceId = new EventEmitter<string>();
  @Output() dialogRef = new EventEmitter<
    MatDialogRef<VerificationDialogComponent>
  >();

  dialogRefCloseLinks!: MatDialogRef<CompanyCloseLinkSelectDialogComponent>;

  fieldsMap: Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  > = new Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  >();

  isRemoving: boolean = false;
  today: Date = new Date();
  readonly minAge = 18;
  minDob: Date = new Date(
    this.today.getFullYear() - this.minAge,
    this.today.getMonth(),
    this.today.getDate()
  );
  percentageValidators = percentageValidators;

  private _inputsComplete: boolean | undefined = undefined;
  private _documentsComplete: boolean | undefined = undefined;
  private _director!: CompanyDirectorWithDocuments;
  private _closeLinks!: CompanyCloseLink[];
  private _directorCloseLinks!: CompanyDirectorCloseLink[];
  private directorFormSub: Subscription | undefined = undefined;

  countryCode?: CountryISO | undefined;
  countryCodeCY: CountryISO = CountryISO.Cyprus;
  closeLinksTooltip: string =
    'A situation in which two or more natural or legal persons are linked by: (a) participation in the form of ownership, direct or by way of control, of 20 % or more of the voting rights or capital of an undertaking; (b) ‘control’ which means the relationship between a parent undertaking and a subsidiary, in all the cases referred to in Article 22(1) and (2) of Directive 2013/34/EU, or a similar relationship between any natural or legal person and an undertaking, any subsidiary undertaking of a subsidiary undertaking also being considered to be a subsidiary of the parent undertaking which is at the head of those undertakings;  (c) a permanent link of both or all of them to the same person by a control relationship.';
  isGettingVerificationUrl: boolean = false;
  verificationAccepted: boolean = false;

  getCountryById = getCountryById;
  OnboardingFieldId = OnboardingFieldId;
  getStringValue = getStringValue;
  getNationalityById = getNationalityById;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService,
    private dialog: MatDialog
  ) {}

  directorForm = this.fb.group(
    {
      id: null,
      firstName: null,
      lastName: null,
      isDirector: ['', { updateOn: 'change' }],
      isShareholder: ['', { updateOn: 'change' }],
      isUBO: ['', { updateOn: 'change' }],
      isAuthorizedPerson: ['', { updateOn: 'change' }],
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9@\\.\\-_]{1,255}$'),
        ],
      ],
      dateOfBirth: null,
      nationality: null,
      address: this.fb.group({
        streetAddress: ['', [Validators.required, ...addressLineValidator]],
        additionalStreetAddress: ['', [...addressLineValidator]],
        postCode: ['', [Validators.required, ...postCodeCityValidator]],
        city: ['', [Validators.required, ...postCodeCityValidator]],
        countryId: ['', Validators.required],
      }),
      isTempAddress: null,
      isPEP: null,
      phoneNumber: null,
      taxNumber: [''],
      taxResidencyCountry: null,
      percentageOwnedByShareholder: ['', this.percentageValidators],
      percentageOwnedByUBO: ['', this.percentageValidators],
      isLegalPerson: null,
      legalPersonName: null,
      legalPersonRegistrationNumber: null,
      legalPersonIncorporationDate: null,
      hasCloseLinks: [null, { updateOn: 'change' }],
    },
    {
      updateOn: 'blur',
      validators: [
        atLeastOneCheckboxIsChecked(),
        this.closeLinksFilledInEDD.bind(this), // check if user says that they have close links and close links were entered
      ],
    }
  );

  ngOnInit(): void {
    let lastQueueValue: CompanyDirectorWithDocuments | null = null;
    let savedValue: CompanyDirectorWithDocuments | null = null;

    // conditional validators
    this.isShareholderControl?.valueChanges.subscribe((val) => {
      if (val) {
        this.directorForm
          .get('percentageOwnedByShareholder')
          ?.setValidators(
            this.percentageValidators.concat(Validators.required)
          );
      } else {
        this.directorForm
          .get('percentageOwnedByShareholder')
          ?.clearValidators();
      }
      this.directorForm
        .get('percentageOwnedByShareholder')
        ?.updateValueAndValidity();
    });

    this.isUBOControl?.valueChanges.subscribe((val) => {
      if (val) {
        this.directorForm
          .get('percentageOwnedByUBO')
          ?.setValidators(
            this.percentageValidators.concat(Validators.required)
          );
      } else {
        this.directorForm.get('percentageOwnedByUBO')?.clearValidators();
      }
      this.directorForm.get('percentageOwnedByUBO')?.updateValueAndValidity();
    });
    if (this.isReviewed) {
      this.directorForm.markAsTouched();
    }

    // when residential country changes, set the respective country code in phone number if not empty
    this.countryIdControl?.valueChanges.subscribe((val) => {
      if (
        !this.isLegalPersonControl?.value &&
        !this.phoneNumberControl?.value
      ) {
        var countryIso = this.countries
          .find((country) => country.id === val)
          ?.code.toLowerCase() as CountryISO;
        // set country code only if exists in ngx-intl-tel-input
        if (Object.values(CountryISO).includes(countryIso)) {
          this.countryCode = countryIso;
        }
      }
    });

    // saving Form when values changed (requests are sent one by one, intermediate values are omitted)
    this.directorFormSub = this.directorForm.valueChanges
      .pipe(
        map(() => this.directorForm.getRawValue()), // get disabled fields as well
        tap((val) => (lastQueueValue = val)),
        concatMap((val) => {
          if (val === lastQueueValue) {
            this.inputsComplete = false; // prevents submitting application when saving in progress or form is invalid
            if (this.directorForm.valid) {
              savedValue = val;
              const body = {
                ...val,
                dateOfBirth: this.dateOfBirthControl?.value
                  ? (this.dateOfBirthControl?.value as DateTime).toISODate()
                  : null,
                phoneNumber: this.phoneNumberControl?.value
                  ? this.phoneNumberControl?.value?.e164Number || ''
                  : null,
                legalPersonIncorporationDate: this
                  .legalPersonIncorporationDateControl?.value
                  ? (
                      this.legalPersonIncorporationDateControl
                        ?.value as DateTime
                    ).toISODate()
                  : null,
              };
              return this.onboardingService
                .updateCompanyDirectorOrShareholder(this.director.id, body)
                .pipe(
                  tap((val) => {
                    if (savedValue === lastQueueValue) {
                      this.inputsComplete = this.directorForm.valid;
                      this.directorChange.emit(val);

                      // if select that has no close links, remove all close links in frontend
                      if (this.isFirstTimeEDD && !val.hasCloseLinks) {
                        this.directorCloseLinks =
                          this.directorCloseLinks.filter(
                            (dcl) => dcl.directorId !== this.director.id
                          );
                        this.companyDirectorCloseLinksChange.emit(
                          this.directorCloseLinks
                        );
                      }
                    }
                  })
                );
            } else {
              return of(null);
            }
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        () => {},
        () => this.errorService.showErrorDialog()
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    // when updated close links are emitted, force all directors (children) to emit isComplete to update section status
    // (essential when directors share the same close link when isReviewed)
    if (changes.closeLinks && changes.closeLinks.currentValue) {
      if (this.directorForm.valid && this.closeLinksComplete()) {
        this.inputsComplete = true;
      } else {
        this.inputsComplete = false;
      }
    }
  }

  disableFormFields() {
    // when fields are disabled it triggers valueChanges, then the form is saved, emitted which triggers set director(), then disable fields and ends up in a loop
    if (this.director.isLegalPerson) {
      this.legalPersonNameControl?.disable({ emitEvent: false });
    } else {
      this.firstNameControl?.disable({ emitEvent: false });
      this.lastNameControl?.disable({ emitEvent: false });
    }
    this.addressGroup?.disable({ emitEvent: false });
  }

  async getVerificationUrl(): Promise<void> {
    this.isGettingVerificationUrl = true;
    this.onboardingService
      .getVerificationUrl(false, true, this.director.id)
      .subscribe(
        (res) => {
          this.isGettingVerificationUrl = false;
          // open dialog to not allow data changes
          this.referenceId.emit(res.reference_id);
          this.dialogRef.emit(
            this.dialog.open<
              VerificationDialogComponent,
              VerificationDialogData
            >(VerificationDialogComponent, {
              width: '600px',
              height: '700px',
              disableClose: true,
              data: {
                src: res.verification_url,
              },
            })
          );
        },
        () => {
          this.isGettingVerificationUrl = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  ngOnDestroy(): void {
    this.directorFormSub?.unsubscribe();
    if (this.dialogRefCloseLinks) {
      this.dialogRefCloseLinks.close();
    }
  }

  selectCompanyCloseLink(directorId: number) {
    this.isComplete.emit(false); // prevents saving application while adding company close link
    this.dialogRefCloseLinks =
      this.dialog.open<CompanyCloseLinkSelectDialogComponent>(
        CompanyCloseLinkSelectDialogComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '770px',
          disableClose: true,
          data: {
            countries: this.countries,
            closeLinks: this.closeLinks,
            directorCloseLinks: this.directorCloseLinks,
            directorId: directorId,
            isLegalPerson: this.isLegalPersonControl?.value,
            isReviewed: this.isReviewed,
            isFirstTimeEDD: this.isFirstTimeEDD,
            fields: this.fields,
            hasCloselinks: this.hasCloseLinksControl?.value,
            isNotOnboarding: false,
          },
        }
      );
    const closeLinkChangeSub =
      this.dialogRefCloseLinks.componentInstance.companyCloseLinksChange.subscribe(
        (updatedCloseLinks) => {
          this.companyCloseLinksChange.emit(updatedCloseLinks); // emit to update company close links of other key persons
        }
      );
    const directorCloseLinkChangeSub =
      this.dialogRefCloseLinks.componentInstance.companyDirectorCloseLinksChange.subscribe(
        (updatedDirectorCloseLinks) => {
          this.companyDirectorCloseLinksChange.emit(updatedDirectorCloseLinks); // emit to update company director close links of other key persons, and when dialog is closed and opened again
        }
      );
    this.dialogRefCloseLinks.afterClosed().subscribe(() => {
      closeLinkChangeSub.unsubscribe();
      directorCloseLinkChangeSub.unsubscribe();
      if (this.directorForm.valid && this.closeLinksComplete()) {
        this.inputsComplete = true;
      }
      this.directorForm.updateValueAndValidity(); // rerun validators to check closeLinksFilledInEDD
    });
  }

  // check is needed when admin has added comments for close links and/or connections with key persons
  closeLinksComplete() {
    var isValid = true;
    // check the validity of each close link and each connection with key persons
    this.closeLinks.forEach((cl) => {
      // get director-close link row - each combination is unique
      var dcl = this.directorCloseLinks.find(
        (directorCloseLink) =>
          directorCloseLink.closeLinkId === cl.id &&
          directorCloseLink.directorId === this.director.id
      );
      if (
        dcl &&
        (!cl.name ||
          !cl.incorporationDate ||
          !cl.registrationNumber ||
          !cl.address?.streetAddress ||
          !cl.address?.city ||
          !cl.address?.postCode ||
          !cl.address?.countryId ||
          (!dcl.isDirector && !dcl.isShareholder && !dcl.isUBO) ||
          (dcl.isUBO && !dcl.percentageOwnedByUBO) ||
          (dcl.isShareholder && !dcl.percentageOwnedByShareholder))
      ) {
        isValid = false;
      }
    });
    return isValid;
  }

  private closeLinksFilledInEDD() {
    if (
      this.directorForm &&
      this.directorCloseLinks &&
      this.isFirstTimeEDD &&
      this.directorForm.get('hasCloseLinks')?.value
    ) {
      var dcl = this.directorCloseLinks.find(
        (directorCloseLink) => directorCloseLink.directorId === this.director.id
      );
      // if said that has close links but director-close links don't exist, form is Incomplete
      if (!dcl) {
        return { missingCloseLinks: true };
      }
    }
    return null;
  }

  removeDirector(director: CompanyDirectorWithDocuments): void {
    this.inputsComplete = false; // prevents submitting application when removing in progress
    this.isRemoving = true;

    director.firstName = '';
    director.lastName = '';
    director.legalPersonName = '';

    this.onboardingService
      .updateCompanyDirectorOrShareholder(director.id, director)
      .subscribe(
        (modifiedDirector) => {
          if (modifiedDirector) {
            this.directorChange.emit(modifiedDirector);
          } else {
            this.directorRemove.emit(director.id);
          }
          this.isRemoving = false;
        },
        () => {
          this.isRemoving = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  // set to true when inputs are saved and valid
  set inputsComplete(val: boolean | undefined) {
    if (val !== undefined && this.documentsComplete !== undefined) {
      this.isComplete.emit(val && this.documentsComplete);
    }
    this._inputsComplete = val;
  }
  get inputsComplete(): boolean | undefined {
    return this._inputsComplete;
  }
  // set to true when required files are saved
  set documentsComplete(val: boolean | undefined) {
    if (val !== undefined && this.inputsComplete !== undefined) {
      this.isComplete.emit(val && this.inputsComplete);
    }
    this._documentsComplete = val;
  }
  get documentsComplete(): boolean | undefined {
    return this._documentsComplete;
  }

  // returns if key person is only authorized contact person
  isOnlyAuthorizedPerson() {
    return !(
      this.director.isLegalPerson ||
      (!this.director.isLegalPerson &&
        (!this.director.isAuthorizedPerson ||
          this.director.isDirector ||
          this.director.isShareholder ||
          this.director.isUBO))
    );
  }

  // returns if key person completed their status
  isStatusComplete() {
    return (
      (this.director.isLegalPerson &&
        (this.isDirectorControl?.value ||
          this.isShareholderControl?.value ||
          this.isUBOControl?.value)) ||
      (!this.director.isLegalPerson &&
        (this.isAuthorizedPersonControl?.value ||
          this.isDirectorControl?.value ||
          this.isShareholderControl?.value ||
          this.isUBOControl?.value))
    );
  }

  get firstNameControl(): AbstractControl | null {
    return this.directorForm.get('firstName');
  }
  get lastNameControl(): AbstractControl | null {
    return this.directorForm.get('lastName');
  }
  get isAuthorizedPersonControl(): AbstractControl | null {
    return this.directorForm.get('isAuthorizedPerson');
  }
  get isDirectorControl(): AbstractControl | null {
    return this.directorForm.get('isDirector');
  }
  get isShareholderControl(): AbstractControl | null {
    return this.directorForm.get('isShareholder');
  }
  get isUBOControl(): AbstractControl | null {
    return this.directorForm.get('isUBO');
  }
  public get emailControl(): AbstractControl | null {
    return this.directorForm.get('email');
  }
  get dateOfBirthControl(): AbstractControl | null {
    return this.directorForm.get('dateOfBirth');
  }
  get nationalityControl(): AbstractControl | null {
    return this.directorForm.get('nationality');
  }
  // address
  get addressGroup(): AbstractControl | null {
    return this.directorForm.get('address');
  }
  get streetAddressControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('streetAddress');
  }
  get additionalStreetAddressControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('additionalStreetAddress');
  }
  get postCodeControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('postCode');
  }
  get cityControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('city');
  }
  get countryIdControl(): AbstractControl | null | undefined {
    return this.addressGroup?.get('countryId');
  }
  // natural person
  get isTempAddressControl(): AbstractControl | null {
    return this.directorForm?.get('isTempAddress');
  }
  get isPEPControl(): AbstractControl | null {
    return this.directorForm.get('isPEP');
  }
  get phoneNumberControl(): AbstractControl | null {
    return this.directorForm.get('phoneNumber');
  }
  get taxNumberControl(): AbstractControl | null {
    return this.directorForm.get('taxNumber');
  }
  get percentageOwnedByShareholderControl(): AbstractControl | null {
    return this.directorForm.get('percentageOwnedByShareholder');
  }
  get percentageOwnedByUBOControl(): AbstractControl | null {
    return this.directorForm.get('percentageOwnedByUBO');
  }
  // legal person
  get isLegalPersonControl(): AbstractControl | null {
    return this.directorForm.get('isLegalPerson');
  }
  get legalPersonNameControl(): AbstractControl | null {
    return this.directorForm.get('legalPersonName');
  }
  get legalPersonRegistrationNumberControl(): AbstractControl | null {
    return this.directorForm.get('legalPersonRegistrationNumber');
  }
  get legalPersonIncorporationDateControl(): AbstractControl | null {
    return this.directorForm.get('legalPersonIncorporationDate');
  }
  // person has close links
  get hasCloseLinksControl(): AbstractControl | null {
    return this.directorForm.get('hasCloseLinks');
  }
}
