<app-big-loader *ngIf="isLoading"></app-big-loader>
<ng-container *ngIf="!isLoading" [ngSwitch]="isInitialView">
  <!-- INITIAL VIEW -->
  <ng-container *ngSwitchCase="true">
    <div class="top-container">
      <h1>Template</h1>
      <mat-card class="template-container">
        <div>
          Download and fill in this Excel Template for making mass payments;
          only SEPA payments are allowed.
        </div>
        <a href="https://dev.ecredo.com/assets/payroll_template_new_empty.xlsx">
          <button mat-flat-button color="accent" class="g-medium">
            Download template
          </button>
        </a>
      </mat-card>

      <div fxLayout="column" class="accounts-container">
        <div fxHide.lt-sm fxLayout="row" fxLayoutAlign="space-between center">
          <h1>From</h1>
          <div></div>
          <h1>To</h1>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutAlign="space-between center"
        class="accounts-container"
      >
        <!-- FROM -->
        <div class="from-to">
          <h1 class="small-screen-h1" fxHide.gt-xs>From</h1>
          <app-account-list-item
            [account]="fromAccount"
          ></app-account-list-item>
        </div>

        <!-- ARROW -->
        <button mat-icon-button color="accent" class="arrow">
          <mat-icon
            svgIcon="arrow"
            [class.switch-accounts-button]="$breakpointObserver | async"
          ></mat-icon>
        </button>

        <!-- TO -->
        <div
          fxLayoutAlign.lt-sm="start"
          fxLayout.lt-sm="column"
          class="from-to"
        >
          <div fxLayout="column">
            <h1 fxHide.gt-xs class="negative-margin small-screen-h1">To</h1>
            <app-template-upload
              fxFlexAlign="stretch"
              (clearFile)="state = MassPaymentState.initial"
              (upload)="onUpload($event)"
            ></app-template-upload>
          </div>
        </div>
      </div>

      <!-- FILE PROCESSING -->
      <div
        *ngIf="state === MassPaymentState.fileProcessing"
        class="upload-state-container"
      >
        <h1>Processing...</h1>
        <div>Please wait</div>
      </div>
      <!-- FILE ERRORING -->
      <div
        *ngIf="state === MassPaymentState.fileErroring"
        class="upload-state-container"
      >
        {{ uploadError }}
      </div>
    </div>

    <!-- FILE UPLOADED -->
    <div
      *ngIf="state === MassPaymentState.fileUploaded"
      class="transfers-container"
    >
      <h1>Transfers</h1>
      <mat-card fxLayout="column" fxLayoutGap="20px" class="container">
        <app-mass-payment-table
          [dataSource]="massPayment?.lines"
        ></app-mass-payment-table>
        <div fxLayout fxLayoutAlign="space-between center" class="summary-row">
          <div class="transfers-count">
            {{ massPayment?.lines?.length }}
            <ng-container [ngPlural]="massPayment?.lines?.length || 0">
              <ng-template ngPluralCase="=1">transfer</ng-template>
              <ng-template ngPluralCase="other">transfers</ng-template>
            </ng-container>
          </div>
          <div fxLayout="row">
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              class="total-amount"
            >
              <div>Total Amount:</div>
              <div>Total Indicative Transfer Fee (SHA):</div>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              class="total-amount-value"
            >
              <div>{{ massPayment!.totalAmount | amount }} EUR</div>
              <div>{{ massPayment!.totalFee | amount }} EUR</div>
            </div>
          </div>
        </div>
        <button
          mat-flat-button
          color="accent"
          fxFlexAlign="end"
          (click)="goToConfirmationStep()"
        >
          Make {{ massPayment?.lines?.length }}
          <ng-container [ngPlural]="massPayment?.lines?.length || 0">
            <ng-template ngPluralCase="=1">transfer</ng-template>
            <ng-template ngPluralCase="other">transfers</ng-template>
          </ng-container>
        </button>
      </mat-card>
    </div>
  </ng-container>

  <!-- SECOND VIEW -->
  <div *ngSwitchCase="false" class="second-view-container">
    <h1 [ngSwitch]="state">
      <ng-container *ngSwitchCase="MassPaymentState.confirmation"
        >Transfers confirmation</ng-container
      >
      <ng-container *ngSwitchDefault>Transfers progress</ng-container>
    </h1>
    <mat-card class="container">
      <app-account-list-item
        [account]="fromAccount"
        [isBalanceHidden]="state !== MassPaymentState.confirmation"
      ></app-account-list-item>
      <!-- TRANSFERS STATUS -->
      <div class="transfers-status-container" *ngIf="isShowingTransfersStatus">
        <div fxLayout fxLayoutAlign="space-between center" class="success">
          <div>
            {{ successCount }}
            <ng-container [ngPlural]="successCount">
              <ng-template ngPluralCase="=1">transfer</ng-template>
              <ng-template ngPluralCase="other">transfers</ng-template>
            </ng-container>
            done
          </div>
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            [disabled]="state === MassPaymentState.retryInProgress"
            (click)="downloadReport()"
          >
            Download report
          </button>
        </div>
        <div
          *ngIf="failureCount"
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          class="failure"
        >
          <div>
            {{ failureCount }}
            <ng-container [ngPlural]="successCount">
              <ng-template ngPluralCase="=1">transfer</ng-template>
              <ng-template ngPluralCase="other">transfers</ng-template>
            </ng-container>
            failed
          </div>
          <app-small-loader
            *ngIf="state === MassPaymentState.retryInProgress"
          ></app-small-loader>
          <button
            *ngIf="state !== MassPaymentState.retryFinished"
            mat-stroked-button
            color="accent"
            class="g-small"
            [disabled]="state === MassPaymentState.retryInProgress"
            (click)="retry()"
          >
            Retry the failed transfers
          </button>
        </div>
        <div
          *ngIf="state === MassPaymentState.retryFinished && retryFailureCount"
          class="retry-failure"
        >
          The transfer retry failed, please double-check the data you provided
          and try to make a single transfer.
        </div>
      </div>

      <div fxLayout="column" class="amount-container">
        <div>Total Amount</div>
        <div class="amount-value">
          {{ massPayment!.totalAmount | amount }} EUR
        </div>
        <br />
        <div>Total Indicative Transfer Fee (SHA)</div>
        <div class="amount-value">{{ massPayment!.totalFee | amount }} EUR</div>
      </div>
      <h1 class="accent">To</h1>
      <app-mass-payment-table
        [dataSource]="massPayment?.lines"
        [showStatus]="state !== MassPaymentState.confirmation"
        [statusMap]="statusMap"
      ></app-mass-payment-table>
      <!-- CONFIRMATION -->
      <div
        *ngIf="state === MassPaymentState.confirmation"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        class="otp-container"
      >
        <div class="sms-text">
          We’ve just sent you a confirmation SMS.<br />
          <strong>Check your mobile now</strong><br />
          and insert the confirmation code here.
        </div>
        <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="20px">
          <div fxLayout="column">
            <label>Confirmation code</label>
            <mat-form-field>
              <input
                matInput
                [formControl]="otpControl"
                data-testid="confirmation-code-input"
              />
              <mat-error data-testid="confirmation-code-error-wrong-code"
                >Wrong code</mat-error
              >
            </mat-form-field>
          </div>
          <button mat-flat-button color="accent" (click)="confirm()">
            Confirm
          </button>
        </div>
        <button mat-stroked-button color="accent" (click)="cancel()">
          Cancel
        </button>
      </div>
      <!-- TRANSFERS IN PROGRESS -->
      <div
        *ngIf="state === MassPaymentState.transfersInProgress"
        class="transfers-started"
      >
        <h3>Transfers started</h3>
        <div>Check the progress of your transfers here above.</div>
      </div>
    </mat-card>
  </div>
</ng-container>
