import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateTime } from 'luxon';
import { first } from 'rxjs/operators';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes-guard';
import { Country } from 'src/app/shared/models/country.model';
import { PersonalForm } from 'src/app/onboarding-new/models/personal-form.model';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  getCountryById,
  getMonthlyIncomeNew,
  getMonthlySpend,
  getSizeOfWealth,
  setExtraQuestionsToRequired,
  firstOrLastNameValidator,
  addressLineValidator,
  postCodeCityValidator,
  NA,
} from 'src/app/shared/helpers/various-helpers.helper';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { RegisteredUsersService } from '../../../registered-users.service';
import { MonthlySpend } from 'src/app/onboarding-new/models/monthly-spend.enum';
import { MonthlyIncomeNew } from 'src/app/onboarding-new/models/monthly-income.enum';
import { OccupationProfession } from 'src/app/onboarding-new/models/occupation-profession.enum';
import { Occupation } from 'src/app/onboarding-new/models/occupation.enum';
import { CardPurpose } from 'src/app/onboarding-new/models/card-purpose.enum';
import { SizeOfWealth } from 'src/app/onboarding-new/models/size-of-wealth.enum';
import { Program } from 'src/app/login/models/program.enum';
import { SourceOfIncome } from 'src/app/onboarding-new/models/source-of-income.enum';
import { SourceOfWealth } from 'src/app/onboarding-new/models/source-of-wealth.enum';
import { RiskScoreLevel } from 'src/app/onboarding-new/models/risk-score-level.enum';
import { SaveRegisteredUser } from '../../../models/save-registered-user.model';
import { taxOrVatExists } from 'src/app/onboarding-new/components/onboarding-individual-form/onboarding-individual-form.component';

@Component({
  selector: 'app-registered-user-profile-individual-new',
  templateUrl: './registered-user-profile-individual.component.html',
  styleUrls: ['./registered-user-profile-individual.component.scss'],
})
export class RegisteredUserProfileIndividualNewComponent
  implements OnInit, ComponentCanDeactivate
{
  @Input() userId!: number;
  @Input() personalData!: PersonalForm;
  @Input() countries: Country[] = [];
  @Input() acceptedCountries: Country[] = [];
  @Input() email!: string;
  @Input() cellPhoneNumber!: string;
  @Input() program!: Program;
  @Output() reloadUser = new EventEmitter<void>();

  identityForm!: FormGroup;

  occupationProfessions = Object.entries(OccupationProfession);
  occupations = Object.entries(Occupation);
  cardPurposes = Object.entries(CardPurpose);
  monthlyIncomes = Object.entries(MonthlyIncomeNew);
  monthlySpends = Object.entries(MonthlySpend);
  sizesOfWealth = Object.entries(SizeOfWealth);
  sourcesOfWealth = Object.entries(SourceOfWealth);
  sourcesOfIncome = Object.entries(SourceOfIncome);
  getMonthlyIncome = getMonthlyIncomeNew;
  getMonthlySpend = getMonthlySpend;
  getSizeOfWealth = getSizeOfWealth;
  getCountryById = getCountryById;
  NA = NA;

  today: Date = new Date();
  readonly minAge = 18;
  minDob: Date = new Date(
    this.today.getFullYear() - this.minAge,
    this.today.getMonth(),
    this.today.getDate()
  );

  isSavingIdentityForm: boolean = false;
  showExtraQuestions: boolean = false;
  isHighRisk: boolean | undefined;

  constructor(
    private registeredUsersService: RegisteredUsersService,
    private errorService: ErrorService,
    private fb: FormBuilder
  ) {}

  canDeactivate(): boolean {
    return this.identityForm.pristine;
  }

  ngOnInit(): void {
    this.identityForm = this.fb.group({
      firstName: [
        this.personalData?.firstName,
        [Validators.required, ...firstOrLastNameValidator],
      ],
      lastName: [
        this.personalData?.lastName,
        [Validators.required, ...firstOrLastNameValidator],
      ],
      email: [{ value: this.email, disabled: true }],
      cellPhoneNumber: [this.cellPhoneNumber],
      dateOfBirth: [
        this.personalData?.dateOfBirth
          ? DateTime.fromISO(this.personalData?.dateOfBirth)
          : null,
        Validators.required,
      ],
      countryOfBirth: [this.personalData?.countryOfBirth, Validators.required],
      nationality: [this.personalData?.nationality, Validators.required],
      streetAddress: [
        this.personalData?.address?.streetAddress,
        [Validators.required, ...addressLineValidator],
      ],
      additionalStreetAddress: [
        this.personalData?.address?.additionalStreetAddress,
        [...addressLineValidator],
      ],
      postCode: [
        this.personalData?.address?.postCode,
        [Validators.required, ...postCodeCityValidator],
      ],
      city: [
        this.personalData?.address?.city,
        [Validators.required, ...postCodeCityValidator],
      ],
      countryId: [this.personalData?.address?.countryId, Validators.required],
      isTempAddress: this.personalData?.isTempAddress,
      additionalPhoneNumber: [
        this.personalData?.additionalPhoneNumber,
        phoneValidator(),
      ],
      additionalEmail: [
        this.personalData?.additionalEmail,
        [Validators.email, Validators.pattern('^[a-zA-Z0-9@._-]{1,255}$')],
      ],
      dataOccupationProfession: [
        this.personalData?.dataOccupationProfession,
        Validators.required,
      ],
      dataOccupation: [this.personalData?.dataOccupation, Validators.required],
      dataCardPurpose: [
        this.personalData?.dataCardPurpose
          ? this.personalData?.dataCardPurpose.split(', ')
          : '',
        Validators.required,
      ],
      dataMonthlyIncome: [
        this.personalData?.dataMonthlyIncome,
        Validators.required,
      ],
      dataMonthlySpend: [
        this.personalData?.dataMonthlySpend,
        Validators.required,
      ],
      isPEP: [this.personalData?.isPEP, Validators.required],
      dataSourceOfWealth: [this.personalData?.dataSourceOfWealth?.split(', ')],
      dataSourceOfIncome: [this.personalData?.dataSourceOfIncome?.split(', ')],
      dataSizeOfWealth: [this.personalData?.dataSizeOfWealth],
      countryOfSourceOfWealth: [this.personalData?.countryOfSourceOfWealth],
      countryOfSourceOfIncome: [this.personalData?.countryOfSourceOfIncome],
      dataTaxNumber: [
        this.personalData?.dataTaxNumber,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
          Validators.maxLength(50),
        ],
      ],
      dataTaxNumberNotAvailable: [''],
      dataVatNumber: [
        this.personalData?.dataVatNumber,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
          Validators.maxLength(50),
        ],
      ],
      dataVatNumberNotAvailable: [''],
      dataTaxAndVatCountry: [
        this.personalData?.dataTaxAndVatCountry,
        taxOrVatExists(),
      ],
      referee: [this.personalData?.referee, Validators.email],
      heardFrom: [
        this.personalData?.heardFrom,
        [
          Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
    });

    // show extra questions (source of wealth/income etc) if: (Jade + submitted edd) or (Emerald) or (high risk)
    this.isHighRisk =
      this.personalData.riskReports?.find(
        (r) => r.id === this.personalData.currentRiskReportId
      )?.adjustedRiskLevel === RiskScoreLevel.HIGH;
    this.showExtraQuestions =
      (this.personalData?.dtEddFormSubmitted &&
        this.program === Program.JADE) ||
      this.program === Program.EMERALD ||
      this.isHighRisk;
    if (this.showExtraQuestions) {
      setExtraQuestionsToRequired(this.identityForm);
    }

    // if tax number is N/A set the checkbox to true
    if (this.personalData.dataTaxNumber === NA) {
      this.dataTaxNumberNotAvailableControl?.setValue(true);
      this.dataTaxNumberControl?.disable();
    } else {
      this.dataTaxNumberNotAvailableControl?.setValue(false);
      this.dataTaxNumberControl?.enable();
    }

    // if vat number is N/A set the checkbox to true
    if (this.personalData.dataVatNumber === NA) {
      this.dataVatNumberNotAvailableControl?.setValue(true);
      this.dataVatNumberControl?.disable();
    } else {
      this.dataVatNumberNotAvailableControl?.setValue(false);
      this.dataVatNumberControl?.enable();
    }

    // if tax number is NA, disable the tax field and set the tax number to NA
    this.dataTaxNumberNotAvailableControl?.valueChanges.subscribe(
      (notAvailable) => {
        if (notAvailable) {
          this.dataTaxNumberControl?.disable();
          this.dataTaxNumberControl?.setValue(NA);
        } else {
          this.dataTaxNumberControl?.enable();
          this.dataTaxNumberControl?.setValue(null);
        }
        // run custom validator of country
        this.dataTaxAndVatCountryControl?.updateValueAndValidity();
      }
    );
    this.dataTaxNumberControl?.valueChanges.subscribe(() => {
      this.dataTaxAndVatCountryControl?.updateValueAndValidity();
    });

    // if vat number is NA, disable the vat field and set the vat number to NA
    this.dataVatNumberNotAvailableControl?.valueChanges.subscribe(
      (notAvailable) => {
        if (notAvailable) {
          this.dataVatNumberControl?.disable();
          this.dataVatNumberControl?.setValue(NA);
        } else {
          this.dataVatNumberControl?.enable();
          this.dataVatNumberControl?.setValue(null);
        }
        // run custom validator of country
        this.dataTaxAndVatCountryControl?.updateValueAndValidity();
      }
    );
    this.dataVatNumberControl?.valueChanges.subscribe(() => {
      this.dataTaxAndVatCountryControl?.updateValueAndValidity();
    });

    this.emailControl?.disable();
    this.cellPhoneNumberControl?.disable();
    this.firstNameControl?.disable();
    this.lastNameControl?.disable();
    this.dateOfBirthControl?.disable();
    this.countryOfBirthControl?.disable();

    this.additionalPhoneNumberControl?.valueChanges
      .pipe(first())
      .subscribe(() => {
        this.additionalPhoneNumberControl?.markAsPristine();
      });
    this.cellPhoneNumberControl?.valueChanges.pipe(first()).subscribe(() => {
      this.cellPhoneNumberControl?.markAsPristine();
    });

    this.identityForm.markAllAsTouched(); // show user extra incomplete fields if they are high risk
  }

  async saveIdentityUserForm(): Promise<void> {
    this.isSavingIdentityForm = true;
    const personalForm: PersonalForm = {
      nationality: this.nationalityControl?.value,
      address: {
        countryId: this.countryIdControl?.value,
        city: this.cityControl?.value,
        postCode: this.postCodeControl?.value,
        streetAddress: this.streetAddressControl?.value,
        additionalStreetAddress:
          this.additionalStreetAddressControl?.value || null,
      },
      isTempAddress: this.isTempAddressControl?.value,
      additionalPhoneNumber:
        this.additionalPhoneNumberControl?.value?.e164Number || '',
      additionalEmail: this.additionalEmailControl?.value || null,
      dataOccupationProfession: this.dataOccupationProfessionControl?.value,
      dataOccupation: this.dataOccupationControl?.value,
      dataCardPurpose: this.dataCardPurposeControl?.value.join(', '),
      dataMonthlyIncome: this.dataMonthlyIncomeControl?.value,
      dataMonthlySpend: this.dataMonthlySpendControl?.value,
      isPEP: this.isPEPControl?.value,
      dataSourceOfWealth: this.dataSourceOfWealthControl?.value
        ? this.dataSourceOfWealthControl?.value.join(', ')
        : null,
      dataSourceOfIncome: this.dataSourceOfIncomeControl?.value
        ? this.dataSourceOfIncomeControl?.value.join(', ')
        : null,
      dataSizeOfWealth: this.dataSizeOfWealthControl?.value,
      countryOfSourceOfWealth: this.countryOfSourceOfWealthControl?.value,
      countryOfSourceOfIncome: this.countryOfSourceOfIncomeControl?.value,
      dataTaxNumber: this.dataTaxNumberControl?.value,
      dataVatNumber: this.dataVatNumberControl?.value,
      dataTaxAndVatCountry: this.dataTaxAndVatCountryControl?.value,
      referee: this.refereeControl?.value || null,
      heardFrom: this.heardFromControl?.value || null,
    };
    const body: SaveRegisteredUser = {
      userId: this.userId,
      personalData: personalForm,
    };
    (
      await this.registeredUsersService.saveRegisteredUserPersonalData(body)
    ).subscribe(
      () => {
        this.isSavingIdentityForm = false;
        this.identityForm.markAsPristine();
        this.reloadUser.emit();
      },
      (error) => {
        this.isSavingIdentityForm = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  get firstNameControl(): AbstractControl | null {
    return this.identityForm.get('firstName');
  }
  get lastNameControl(): AbstractControl | null {
    return this.identityForm.get('lastName');
  }
  get dateOfBirthControl(): AbstractControl | null {
    return this.identityForm.get('dateOfBirth');
  }
  get countryOfBirthControl(): AbstractControl | null {
    return this.identityForm.get('countryOfBirth');
  }
  get nationalityControl(): AbstractControl | null {
    return this.identityForm.get('nationality');
  }
  get streetAddressControl(): AbstractControl | null | undefined {
    return this.identityForm.get('streetAddress');
  }
  get additionalStreetAddressControl(): AbstractControl | null | undefined {
    return this.identityForm.get('additionalStreetAddress');
  }
  get postCodeControl(): AbstractControl | null | undefined {
    return this.identityForm.get('postCode');
  }
  get cityControl(): AbstractControl | null | undefined {
    return this.identityForm.get('city');
  }
  get countryIdControl(): AbstractControl | null | undefined {
    return this.identityForm.get('countryId');
  }
  get isTempAddressControl(): AbstractControl | null | undefined {
    return this.identityForm.get('isTempAddress');
  }
  get additionalPhoneNumberControl(): AbstractControl | null {
    return this.identityForm.get('additionalPhoneNumber');
  }
  get additionalEmailControl(): AbstractControl | null {
    return this.identityForm.get('additionalEmail');
  }

  get refereeControl(): AbstractControl | null {
    return this.identityForm.get('referee');
  }
  get heardFromControl(): AbstractControl | null {
    return this.identityForm.get('heardFrom');
  }
  get emailControl(): AbstractControl | null {
    return this.identityForm.get('email');
  }
  get cellPhoneNumberControl(): AbstractControl | null {
    return this.identityForm.get('cellPhoneNumber');
  }
  get dataOccupationProfessionControl(): AbstractControl | null {
    return this.identityForm.get('dataOccupationProfession');
  }
  get dataOccupationControl(): AbstractControl | null {
    return this.identityForm.get('dataOccupation');
  }
  get dataCardPurposeControl(): AbstractControl | null {
    return this.identityForm.get('dataCardPurpose');
  }
  get dataMonthlyIncomeControl(): AbstractControl | null {
    return this.identityForm.get('dataMonthlyIncome');
  }
  get dataMonthlySpendControl(): AbstractControl | null {
    return this.identityForm.get('dataMonthlySpend');
  }
  get isPEPControl(): AbstractControl | null {
    return this.identityForm.get('isPEP');
  }
  get dataSourceOfWealthControl(): AbstractControl | null {
    return this.identityForm.get('dataSourceOfWealth');
  }
  get dataSourceOfIncomeControl(): AbstractControl | null {
    return this.identityForm.get('dataSourceOfIncome');
  }
  get countryOfSourceOfWealthControl(): AbstractControl | null {
    return this.identityForm.get('countryOfSourceOfWealth');
  }
  get countryOfSourceOfIncomeControl(): AbstractControl | null {
    return this.identityForm.get('countryOfSourceOfIncome');
  }
  get dataSizeOfWealthControl(): AbstractControl | null {
    return this.identityForm.get('dataSizeOfWealth');
  }
  get dataTaxNumberControl(): AbstractControl | null {
    return this.identityForm.get('dataTaxNumber');
  }
  get dataTaxNumberNotAvailableControl(): AbstractControl | null {
    return this.identityForm.get('dataTaxNumberNotAvailable');
  }
  get dataVatNumberControl(): AbstractControl | null {
    return this.identityForm.get('dataVatNumber');
  }
  get dataVatNumberNotAvailableControl(): AbstractControl | null {
    return this.identityForm.get('dataVatNumberNotAvailable');
  }
  get dataTaxAndVatCountryControl(): AbstractControl | null {
    return this.identityForm.get('dataTaxAndVatCountry');
  }
}
